import React, { useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import Ink from 'react-ink'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { createCorsoFormazione, saveCorsoFormazione, fetchCorsoAttivo, resetCorsoAttivo } from '../../actions'

const EditCorsiFormazione = ({
  initialValues,
  permessoGestioneCorsiFormazioneFlag,
  history,
  match,
  saveCorsoFormazione,
  createCorsoFormazione,
  fetchCorsoAttivo,
  resetCorsoAttivo,
}) => {
  useEffect(() => {
    if (match.params.corsoId) {
      fetchCorsoAttivo(match.params.corsoId)
    }
    if (!match.params.corsoId) {
      resetCorsoAttivo()
      //this.props.resetForm();
    }
  }, [fetchCorsoAttivo, match.params.corsoId, resetCorsoAttivo])

  const onSubmit = (values) => {
    if (match.params.corsoId) {
      // EDIT
      saveCorsoFormazione(values).then((action) => {
        history.push('/app/corsi-di-formazione')
      })
    } else {
      // NEW
      createCorsoFormazione(values).then((action) => {
        history.push('/app/corsi-di-formazione')
      })
    }
  }
  return (
    <div className="edit-corsi">
      <h1 className="page-title">Dettaglio corso formazione </h1>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-xs-6">
                <div className="panel panel-border panel-primary">
                  <div className="panel-heading">
                    <h3 className="panel-title">Modifica corso</h3>
                  </div>
                  <div className="panel-body">
                    <Field
                      name="titolo"
                      id="titolo"
                      render={({ input, meta }) => (
                        <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                          <label htmlFor="titolo">
                            <span>Titolo</span>
                          </label>
                          <input type="text" rows="3" id="titolo" className="form-control" {...input} />
                          <div className="help-block">{meta.touched && meta.error}</div>
                        </div>
                      )}
                    />
                    <Field
                      name="descrizione"
                      id="descrizione"
                      render={({ input, meta }) => (
                        <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                          <label htmlFor="descrizione">
                            <span>Descrizione</span>
                          </label>
                          <textarea id="descrizione" className="form-control" {...input} />
                          <div className="help-block">{meta.touched && meta.error}</div>
                        </div>
                      )}
                    />
                    <Field
                      name="requisiti"
                      id="requisiti"
                      render={({ input, meta }) => (
                        <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                          <label htmlFor="requisiti">
                            <span>Requisiti</span>
                          </label>
                          <input type="text" rows="3" id="requisiti" className="form-control" {...input} />
                          <div className="help-block">{meta.touched && meta.error}</div>
                        </div>
                      )}
                    />
                    <Field
                      name="durataOre"
                      id="durataOre"
                      render={({ input, meta }) => (
                        <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                          <label htmlFor="durataOre">
                            <span>Durata ore</span>
                          </label>
                          <input type="number" rows="3" id="durataOre" className="form-control" {...input} />
                          <div className="help-block">{meta.touched && meta.error}</div>
                        </div>
                      )}
                    />
                    <Field
                      name="dataInizio"
                      id="dataInizio"
                      render={({ input, meta }) => (
                        <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                          <label htmlFor="dataInizio">
                            <span>Data inizio</span>
                          </label>
                          <input type="date" rows="3" id="dataInizio" className="form-control" {...input} />
                          <div className="help-block">{meta.touched && meta.error}</div>
                        </div>
                      )}
                    />
                    <Field
                      name="attestatoRilasciato"
                      id="attestatoRilasciato"
                      render={({ input, meta }) => (
                        <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                          <label htmlFor="attestatoRilasciato">
                            <span>Attestato Rilasciato</span>
                          </label>
                          <input type="text" rows="3" id="attestatoRilasciato" className="form-control" {...input} />
                          <div className="help-block">{meta.touched && meta.error}</div>
                        </div>
                      )}
                    />
                    <Field
                      id="ente"
                      name="ente"
                      render={({ input, meta }) => (
                        <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                          <label htmlFor="ente">Luogo di svolgimento corso</label>
                          <textarea className="form-control" id="ente" {...input} />
                          <div className="help-block">{meta.touched && meta.error}</div>
                        </div>
                      )}
                    />
                    <Field
                      name="costo"
                      render={({ input, meta }) => (
                        <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                          <label htmlFor="costo">
                            <span>Costo corso</span>
                          </label>
                          <input type="number" rows="3" id="costo" className="form-control" {...input} />
                          <div className="help-block">{meta.touched && meta.error}</div>
                        </div>
                      )}
                    />
                    <Field
                      name="modalitaErogazione"
                      render={({ input, meta }) => (
                        <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                          <label htmlFor="modalitaErogazione">
                            <span>Modalità erogazione corso</span>
                          </label>
                          <input type="text" rows="3" id="modalitaErogazione" className="form-control" {...input} />
                          <div className="help-block">{meta.touched && meta.error}</div>
                        </div>
                      )}
                    />
                    <div className="checkbox checkbox-primary">
                      <Field name="obbligoFrequenzaFlag" id="obbligoFrequenzaFlag" type="checkbox" component="input" />
                      <label htmlFor="obbligoFrequenzaFlag">Obbligo di frequenza?</label>
                    </div>
                    <Field
                      name="orario"
                      render={({ input, meta }) => (
                        <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                          <label htmlFor="orario">
                            <span>Orario</span>
                          </label>
                          <input type="text" rows="3" id="orario" className="form-control" {...input} />
                          <div className="help-block">{meta.touched && meta.error}</div>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Link to="/app/corsi-di-formazione" className="btn btn-default btn-lg">
              <Ink />
              &laquo; Torna ai corsi
            </Link>
            &nbsp;&nbsp;
            <button type="submit" className="btn btn-primary btn-lg">
              <Ink />
              Salva corso &raquo;
            </button>
          </form>
        )}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    permessoGestioneCorsi: state.auth.user.permessi.permessoGestioneCorsiFormazioneFlag,
    initialValues: state.corsoFormazioneAttivo.data,
  }
}
export default connect(mapStateToProps, {
  saveCorsoFormazione,
  createCorsoFormazione,
  fetchCorsoAttivo,
  resetCorsoAttivo,
})(EditCorsiFormazione)
