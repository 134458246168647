import React, { Component } from 'react'
import BottoneAnnulla from './bottone_annulla'
import { connect } from 'react-redux'
import moment from 'moment'
import DatePicker from 'react-datepicker'

import { fetchCandidato, saveStatoOccupazionale } from '../../actions'
import Lookups from '../lookups/lookups'
import './edit_stato_occupazionale.less'
import { Field, Form } from 'react-final-form'

class EditStatoOccupazionale extends Component {
  componentDidMount() {
    // Ho già il candidato attivo se navigo normalmente
    // dal dettaglio candidato: questo controllo serve
    // nel caso in cui ricarico la pagina da qui (F5)
    if (!this.props.candidatoId) {
      this.props.fetchCandidato(this.props.match.params.id)
    }
  }

  onSubmit(props) {
    const {
      occupatoCassaIntegrazioneInizioF,
      occupatoCassaIntegrazioneFineF,
      occupatoContrattoSolidarietaInizioF,
      occupatoContrattoSolidarietaFineF,
      disoccupatoStrumentiSostegnoInizioF,
      disoccupatoUltimoLavoroDataFineF,
      didDataPresentazioneF,
      centroImpiegoDataIscrizioneF,
      ...rest
    } = props
    this.props.saveStatoOccupazionale(this.props.match.params.id, rest).then(action => {
      this.props.history.goBack()
    })
  }

  render() {
    const initialValues = this.props.initialValues ? this.props.initialValues : {}
    return (
      <Form
        onSubmit={this.onSubmit.bind(this)}
        validate={validate}
        initialValues={initialValues}
        render={({ handleSubmit, form }) => (
          <form className="edit-stato-occupazionale" onSubmit={handleSubmit}>
            <div className="heading">
              <h1 className="page-title pull-left">Stato occupazionale</h1>
              <BottoneAnnulla />
            </div>

            <div className="row">
              <div className="col-md-8">
                {/* STATO OCCUPAZIONALE*/}
                <div className="panel panel-border panel-blue">
                  <div className="panel-heading">
                    <h3 className="panel-title">Stato occupazionale</h3>
                  </div>
                  <div className="panel-body">
                    <div>
                      <div className="radio radio-success radio-inline">
                        <Field
                          name="stato"
                          id="stato_occupato"
                          component="input"
                          type="radio"
                          value="Occupato"
                        />
                        <label htmlFor="stato_occupato">Occupato</label>
                      </div>
                      <div className="radio radio-warning radio-inline">
                        <Field
                          name="stato"
                          id="stato_disoccupato"
                          component="input"
                          type="radio"
                          value="Disoccupato"
                        />
                        <label htmlFor="stato_disoccupato">Disoccupato</label>
                      </div>
                      <div className="radio radio-danger radio-inline">
                        <Field
                          name="stato"
                          id="stato_inoccupato"
                          component="input"
                          type="radio"
                          value="Inoccupato"
                        />
                        <label htmlFor="stato_inoccupato">Inoccupato</label>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Field
                name="x"
                id="x"
                render={({input, meta})=>}
                /> */}

                <div
                  className={`panel panel-border panel-success ${
                    form.getFieldState('stato')?.value === 'Occupato' ? '' : 'hidden'
                  }`}
                >
                  <div className="panel-heading">
                    <h3 className="panel-title">Occupato</h3>
                  </div>
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="occupatoTipoContratto">Tipo contratto</label>
                          <Field
                            name="occupatoTipoContratto"
                            id="occupatoTipoContratto"
                            render={({ input }) => (
                              <Lookups tipo="tipoContratto" id="occupatoTipoContratto" {...input} />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <Field
                          name="occupatoAltroTipo"
                          id="occupatoAltroTipo"
                          render={({ input, meta }) => (
                            <div
                              className={`form-group ${input.value === 'Altro' ? '' : 'hidden'}`}
                            >
                              <label htmlFor="occupatoAltroTipo">Altro</label>
                              <input type="text" className="form-control" {...input} />
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="occupatoTipoQualificaLegale">Qualifica legale</label>
                          <Field
                            name="occupatoTipoQualificaLegale"
                            id="occupatoTipoQualificaLegale"
                            render={({ input }) => (
                              <Lookups
                                tipo="tipoQualificaLegale"
                                id="occupatoTipoQualificaLegale"
                                {...input}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="occupatoAzienda">Azienda</label>
                          <Field
                            name="occupatoAzienda"
                            id="occupatoAzienda"
                            className="form-control"
                            component="input"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="occupatoSettore">Settore</label>
                          <Field
                            name="occupatoSettore"
                            id="occupatoSettore"
                            className="form-control"
                            component="input"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="occupatoIndirizzo">Località</label>
                          <Field
                            name="occupatoIndirizzo"
                            id="occupatoIndirizzo"
                            className="form-control"
                            component="input"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Tempo</label>
                          <div>
                            <div className="radio radio-primary radio-inline">
                              <Field
                                name="occupatoTempo"
                                id="occupatoTempo_Pieno"
                                component="input"
                                type="radio"
                                value="Pieno"
                              />

                              <label htmlFor="occupatoTempo_Pieno">Pieno</label>
                            </div>
                            <div className="radio radio-primary radio-inline">
                              <Field
                                name="occupatoTempo"
                                id="occupatoTempo_PartTime"
                                type="radio"
                                component="input"
                                value="Part-time"
                              />

                              <label htmlFor="occupatoTempo_PartTime">Part-time</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="occupatoOreSettimanali">Ore settimanali</label>
                          <Field
                            name="occupatoOreSettimanali"
                            id="occupatoOreSettimanali"
                            component="input"
                            type="number"
                            min="0"
                            max="99"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <div className="checkbox checkbox-primary">
                            <Field
                              name="occupatoCassaIntegrazioneFlag"
                              id="occupatoCassaIntegrazioneFlag"
                              component="input"
                              type="checkbox"
                            />

                            <label htmlFor="occupatoCassaIntegrazioneFlag">
                              Cassa integrazione
                            </label>
                          </div>
                        </div>
                        <div
                          className={`cid-collapse ${
                            initialValues.occupatoCassaIntegrazioneFlag ? '' : 'hidden'
                          }`}
                        >
                          <div className="form-group">
                            <label htmlFor="occupatoCassaIntegrazioneTipo">
                              Tipo cassa integrazione
                            </label>
                            <Field
                              name="occupatoCassaIntegrazioneTipo"
                              id="occupatoCassaIntegrazioneTipo"
                              render={({ input }) => (
                                <Lookups
                                  tipo="tipoCassaIntegrazione"
                                  id="occupatoCassaIntegrazioneTipo"
                                  {...input}
                                />
                              )}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="occupatoCassaIntegrazioneInizio">Inizio</label>
                            <Field
                              name="occupatoCassaIntegrazioneInizio"
                              id="occupatioCassaIntegrazioneInizio"
                              render={({ input }) => {
                                const { value, ...rest } = input
                                return (
                                  <DatePicker
                                    selected={
                                      value ? new Date(moment(value).format('MM-DD-YYYY')) : null
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    locale="it"
                                    className="form-control"
                                    value={new Date(value)}
                                    {...rest}
                                  />
                                )
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="occupatoCassaIntegrazioneFine">Fine</label>
                            <Field
                              name="occupatoCassaIntegrazioneFine"
                              id="occupatoCassaIntegrazioneFine"
                              render={({ input }) => {
                                const { value, ...rest } = input
                                return (
                                  <DatePicker
                                    selected={
                                      value ? new Date(moment(value).format('MM-DD-YYYY')) : null
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    locale="it"
                                    className="form-control"
                                    value={new Date(value)}
                                    {...rest}
                                  />
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <div className="checkbox checkbox-primary">
                            <Field
                              name="occupatoContrattoSolidarietaFlag"
                              id="occupatoContrattoSolidarietaFlag"
                              component="input"
                              type="checkbox"
                            />
                            <label htmlFor="occupatoContrattoSolidarietaFlag">
                              Contratto solidarietà
                            </label>
                          </div>
                        </div>
                        {/* <div
                          className={`cid-collapse ${
                            occupatoContrattoSolidarietaFlag.checked ? '' : 'hidden'
                          }`}
                        >
                          <div className="form-group">
                            <label htmlFor="occupatoContrattoSolidarietaInizio">Inizio</label>
                            <DatePicker
                              id="occupatoContrattoSolidarietaInizio"
                              selected={
                                occupatoContrattoSolidarietaInizio.value
                                  ? moment(occupatoContrattoSolidarietaInizio.value).date()
                                  : null
                              }
                              onChange={(momentDate) =>
                                occupatoContrattoSolidarietaInizio.onChange(
                                  momentDate.format('YYYY-MM-DD')
                                )
                              }
                              locale="it-IT"
                              className="form-control"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="occupatoContrattoSolidarietaFine">Fine</label>
                            <DatePicker
                              id="occupatoContrattoSolidarietaFine"
                              selected={
                                occupatoContrattoSolidarietaFine.value
                                  ? moment(occupatoContrattoSolidarietaFine.value).date()
                                  : null
                              }
                              onChange={(momentDate) =>
                                occupatoContrattoSolidarietaFine.onChange(
                                  momentDate.format('YYYY-MM-DD')
                                )
                              }
                              locale="it-IT"
                              className="form-control"
                            />
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`panel panel-border panel-warning ${
                    form.getFieldState('stato')?.value === 'Disoccupato' ? '' : 'hidden'
                  }`}
                >
                  <div className="panel-heading">
                    <h3 className="panel-title">Disoccupato</h3>
                  </div>
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="disoccupatoStrumentiSostegno">
                            Strumenti di sostegno al reddito
                          </label>
                          <Field
                            name="disoccupatoStrumentiSostegno"
                            id="disoccupatoStrumentiSostegno"
                            render={({ input }) => (
                              <Lookups
                                tipo="tipoStrumentoSostegno"
                                id="disoccupatoStrumentiSostegno"
                                {...input}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="disoccupatoStrumentiSostegnoInizio">Dalla data</label>
                          <Field
                            name="disoccupatoStrumentiSostegnoInizio"
                            id="disoccupatoStrumentiSostegnoInizio"
                            render={({ input }) => {
                              const { value, ...rest } = input
                              return (
                                <DatePicker
                                  selected={
                                    value ? new Date(moment(value).format('MM-DD-YYYY')) : null
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  locale="it"
                                  className="form-control"
                                  value={new Date(value)}
                                  {...rest}
                                />
                              )
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="disoccupatoUltimoLavoroDataFine">
                            Data fine ultimo lavoro
                          </label>
                          <Field
                            name="disoccupatoUltimoLavoroDataFine"
                            id="disoccupatoUltimoLavoroDataFine"
                            render={({ input }) => {
                              const { value, ...rest } = input
                              return (
                                <DatePicker
                                  selected={
                                    value ? new Date(moment(value).format('MM-DD-YYYY')) : null
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  locale="it"
                                  className="form-control"
                                  value={new Date(value)}
                                  {...rest}
                                />
                              )
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="disoccupatoUltimoLavoroTipoQualificaLegale">
                            Qualifica legale
                          </label>
                          <Field
                            name="disoccupatoUltimoLavoroTipoQualificaLegale"
                            id="disoccupatoUltimoLavoroTipoQualificaLegale"
                            render={({ input }) => (
                              <Lookups
                                tipo="tipoQualificaLegale"
                                id="disoccupatoUltimoLavoroTipoQualificaLegale"
                                {...input}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="disoccupatoUltimoLavoroAzienda">Azienda</label>
                          <Field
                            name="disoccupatoUltimoLavoroAzienda"
                            type="text"
                            className="form-control"
                            id="disoccupatoUltimoLavoroAzienda"
                            component="input"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="disoccupatoUltimoLavoroSettore">Settore</label>
                          <Field
                            name="disoccupatoUltimoLavoroSettore"
                            type="text"
                            className="form-control"
                            id="disoccupatoUltimoLavoroSettore"
                            component="input"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="disoccupatoUltimoLavoroIndirizzo">Località</label>
                          <Field
                            name="disoccupatoUltimoLavoroIndirizzo"
                            type="text"
                            className="form-control"
                            id="disoccupatoUltimoLavoroIndirizzo"
                            component="input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                {/* DID */}
                <div className="panel panel-border panel-blue">
                  <div className="panel-heading">
                    <div className="checkbox checkbox-blue">
                      <Field
                        name="didFlag"
                        type="checkbox"
                        className="form-control"
                        id="didFlag"
                        component="input"
                      />
                      <label htmlFor="didFlag">
                        <h3 className="panel-title">DID presentata</h3>
                      </label>
                    </div>
                  </div>
                  <div className="panel-body">
                    <div className={form.getFieldState('didFlag')?.value ? '' : 'hidden'}>
                      <div className="form-group">
                        <label htmlFor="didDataPresentazione">Data presentazione</label>
                        <Field
                          name="didDataPresentazione"
                          id="didDataPresentazione"
                          render={({ input }) => {
                            const { value, ...rest } = input
                            return (
                              <DatePicker
                                selected={
                                  value ? new Date(moment(value).format('MM-DD-YYYY')) : null
                                }
                                dateFormat="dd/MM/yyyy"
                                locale="it"
                                className="form-control"
                                value={new Date(value)}
                                {...rest}
                              />
                            )
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="didProtocollo">Protocollo (ID)</label>
                        <Field
                          name="didProtocollo"
                          type="text"
                          className="form-control"
                          id="didProtocollo"
                          component="input"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="didOperatorePsp">Operatore PSP</label>
                        <Field
                          name="didOperatorePsp"
                          type="text"
                          className="form-control"
                          id="didOperatorePsp"
                          component="input"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* STUDENTE */}
                <div className="panel panel-border panel-blue">
                  <div className="panel-heading">
                    <div className="checkbox checkbox-blue">
                      <Field
                        name="studenteFlag"
                        type="checkbox"
                        className="form-control"
                        id="studenteFlag"
                        component="input"
                      />
                      <label htmlFor="studenteFlag">
                        <h3 className="panel-title">Studente</h3>
                      </label>
                    </div>
                  </div>

                  <div className="panel-body">
                    {form.getFieldState('studenteFlag')?.value && (
                      <div>
                        <div className="form-group">
                          <label htmlFor="studenteIstituto">Istituto</label>
                          <Field
                            name="studenteIstituto"
                            type="text"
                            className="form-control"
                            id="studenteIstituto"
                            component="input"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="studenteUltimoAnnoFrequentato">
                            Ultimo anno frequentato
                          </label>
                          <Field
                            min={1970}
                            max={2050}
                            name="studenteUltimoAnnoFrequentato"
                            type="number"
                            className="form-control"
                            id="studenteUltimoAnnoFrequentato"
                            component="input"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* CENTRO IMPIEGO */}
                {/*}
                <div className="panel panel-border panel-blue">
                <div className="panel-heading">
                <div className="checkbox checkbox-blue">
                    <input type="checkbox" id="centroImpiegoFlag" {...centroImpiegoFlag} />
                <label htmlFor="centroImpiegoFlag">
                        <h3 className="panel-title">Iscritto a centro impiego</h3>
                      </label>
                    </div>
                   </div>
                  <div className="panel-body">
                    <div className={this.props.fields.centroImpiegoFlag.checked ? '' : 'hidden'}>
                      <div className="form-group">
                        <label htmlFor="centroImpiegoNome">Centro impiego di</label>
                        <input type="text" className="form-control" id="centroImpiegoNome" placeholder="" {...centroImpiegoNome} />
                      </div>
                      <div className="form-group">
                  <label htmlFor="centroImpiegoDataIscrizione">Data iscrizione</label>
                  <DatePicker
                    id="centroImpiegoDataIscrizione"
                    selected={centroImpiegoDataIscrizione.value ? moment(centroImpiegoDataIscrizione.value).date() : null}
                                onChange={(momentDate) => centroImpiegoDataIscrizione.onChange(momentDate.format('YYYY-MM-DD'))}
                                locale="it-IT"
                                className="form-control"/>
                            </div>
                          </div>
                        </div>

                      </div>
                      */}
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-blue btn-lg">
                Salva stato occupazionale &raquo;
              </button>
            </div>
          </form>
        )}
      />
    )
  }
}

function validate(values) {
  const errors = {}

  if (!values.stato) {
    errors.stato = 'Seleziona uno stato occupazionale'
  }

  return errors
}

const mapStateToProps = state => {
  return {
    initialValues: state.candidatoAttivo.statoOccupazionale,
    candidatoId: state.candidatoAttivo.candidatoId, // per sapere se ho già il candidato attivo su F5
  }
}

// connect: 1st argument mapStateToProps, 2nd mapDispatchToProps
// reduxForm: 1st form config, 2nd mapStateToProps, 3rd mapDipatchToProps
export default connect(mapStateToProps, { fetchCandidato, saveStatoOccupazionale })(
  EditStatoOccupazionale
)
