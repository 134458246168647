import React, { Component } from 'react'
import { Field, Form } from 'react-final-form'
import Ink from 'react-ink'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import {
  createJobVacancy,
  fetchJobVacancy,
  fetchTypeaheadComuni,
  saveJobVacancy,
} from '../../actions'
import { getJobVacancyData } from '../../reducers/reducer_job_vacancy_attiva'

class EditJobVacancy extends Component {
  componentDidMount() {
    if (this.props.match.params.jobVacancyId) {
      // Edit => Fetch Job Vacancy
      this.props.fetchJobVacancy(this.props.match.params.jobVacancyId)
    }
  }

  ///////////////////////////////////////////////////////////////
  // GESTIONE COMUNI AUTOCOMPLETE
  getComuniOptions = comuni => {
    return comuni.map(comune => {
      return {
        value: comune.comune,
        label: comune.comuneEsteso,
      }
    })
  }

  getComuni = input => {
    return this.props.fetchTypeaheadComuni(input).then(action => {
      return this.getComuniOptions(action.payload.data)
    })
  }

  onChangeLuogoLavoro(option) {
    this.props.fields.luogoLavoro.onChange(option ? option.value : null)
    this.props.fields.luogoLavoroComuneEsteso.onChange(option ? option.label : null)
  }

  onSubmit(values) {
    const submitValues = { ...values }

    const { ...rest } = submitValues
    if (this.props.match.params.jobVacancyId) {
      rest.luogoLavoro = values.luogoLavoro?.value ? values.luogoLavoro.value : values.luogoLavoro
      // EDIT
      this.props.saveJobVacancy(rest, this.props.permessoJvHome).then(action => {
        this.props.history.push('/app/job-vacancies')
      })
    } else {
      rest.luogoLavoro = values.luogoLavoro?.value
      // NEW
      this.props.createJobVacancy(rest, this.props.permessoJvHome).then(action => {
        this.props.history.push('/app/job-vacancies')
      })
    }
  }

  render() {
    const {
      aziendaRagioneSociale,
      agenziaRagioneSociale,
      sportelloNome,
      permessoJvSitoWeb,
      permessoJvHome,
    } = this.props

    const initialValues = this.props.initialValues ? this.props.initialValues : {}

    return (
      <div className="edit-job-vacancy">
        <h1 className="page-title">
          Dettaglio job vacancy{' '}
          <small>
            inserita da {agenziaRagioneSociale || aziendaRagioneSociale || sportelloNome}
          </small>
        </h1>

        <Form
          onSubmit={this.onSubmit.bind(this)}
          initialValues={initialValues}
          validate={validate}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="panel panel-border panel-primary">
                    <div className="panel-heading">
                      <h3 className="panel-title">Figura richiesta</h3>
                    </div>
                    <div className="panel-body">
                      <Field
                        name="descrizioneSintetica"
                        id="descrizioneSintetica"
                        render={({ input, meta }) => (
                          <div
                            className={`form-group ${
                              meta.touched && meta.invalid ? 'has-error' : ''
                            }`}
                          >
                            <label htmlFor="descrizioneSintetica">
                              <span style={{ color: '#c00' }}>Descrizione sintetica</span>{' '}
                              <small>(no numeri telefono o e-mail)</small>
                            </label>
                            <textarea rows="3" className="form-control" {...input} />
                            <div className="help-block">{meta.touched && meta.error}</div>
                          </div>
                        )}
                      />

                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="figuraProfessionale"
                            id="figuraProfessionale"
                            render={({ input, meta }) => (
                              <div
                                className={`form-group ${
                                  meta.touched && meta.invalid ? 'has-error' : ''
                                }`}
                              >
                                <label htmlFor="figuraProfessionale">Figura professionale</label>
                                <input type="text" className="form-control" {...input} />
                                <div className="help-block">{meta.touched && meta.error}</div>
                              </div>
                            )}
                          />
                        </div>
                        <div className="col-md-6">
                          <Field
                            name="mansione"
                            id="mansione"
                            render={({ input, meta }) => (
                              <div
                                className={`form-group ${
                                  meta.touched && meta.invalid ? 'has-error' : ''
                                }`}
                              >
                                <label htmlFor="mansione">Mansione</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="mansione"
                                  {...input}
                                />
                                <div className="help-block">{meta.touched && meta.error}</div>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <Field
                        name="descrizioneCompleta"
                        id="descrizioneCompleta"
                        render={({ input, meta }) => (
                          <div
                            className={`form-group ${
                              meta.touched && meta.invalid ? 'has-error' : ''
                            }`}
                          >
                            <label htmlFor="descrizioneCompleta">Descrizione completa</label>
                            <textarea rows="7" className="form-control" {...input} />
                            <div className="help-block">{meta.touched && meta.error}</div>
                          </div>
                        )}
                      />

                      <div className="row">
                        <div className="col-md-6">
                          {!!permessoJvSitoWeb && (
                            <div className="form-group">
                              <div className="checkbox checkbox-primary">
                                <Field
                                  name="sitoWebFlag"
                                  id="sitoWebFlag"
                                  type="checkbox"
                                  component="input"
                                />
                                <label htmlFor="sitoWebFlag">Mostra su sito web</label>
                              </div>
                            </div>
                          )}

                          {!!permessoJvHome && (
                            <div className="form-group">
                              <div className="checkbox checkbox-danger">
                                <Field
                                  name="homeFlag"
                                  id="homeFlag"
                                  type="checkbox"
                                  component="input"
                                />
                                <label htmlFor="homeFlag" style={{ color: '#c00' }}>
                                  Mostra in Home
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <Field
                              name="statoVacancy"
                              id="statoVacancy"
                              render={({ input }) => (
                                <select className="form-control" {...input}>
                                  <option value="APERTA">Aperta</option>
                                  <option value="CHIUSA">Chiusa</option>
                                </select>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="panel panel-border panel-warning">
                    <div className="panel-heading">
                      <h3 className="panel-title">Lavoro offerto</h3>
                    </div>
                    <div className="panel-body">
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="luogoLavoro"
                            id="luogoLavoro"
                            render={({ input, meta }) => (
                              <div
                                className={`form-group ${
                                  meta.touched && meta.invalid ? 'has-error' : ''
                                }`}
                              >
                                <label htmlFor="luogoLavoro">Luogo di lavoro</label>
                                <AsyncSelect
                                  name="luogoLavoro"
                                  loadOptions={this.getComuni}
                                  placeholder={
                                    initialValues.luogoLavoroComuneEsteso || 'Seleziona...'
                                  }
                                  loadingPlaceholder="Caricamento..."
                                  searchingText="Caricamento..."
                                  noResultsText="Nessun risultato"
                                  defaultOptions={[
                                    {
                                      value: initialValues.luogoLavoro,
                                      label: initialValues.luogoLavoroComuneEsteso,
                                    },
                                  ]}
                                  {...input}
                                />
                                <div className="help-block">{meta.touched && meta.error}</div>
                              </div>
                            )}
                          />
                        </div>
                        <div className="col-md-6">
                          <Field
                            name="dataInizio"
                            id="dataInizio"
                            render={({ input, meta }) => (
                              <div
                                className={`form-group ${
                                  meta.touched && meta.invalid ? 'has-error' : ''
                                }`}
                              >
                                <label htmlFor="dataInizio">Data inizio</label>
                                <input type="date" className="form-control" {...input} />
                                <div className="help-block">{meta.touched && meta.error}</div>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <Field
                        name="datoreLavoro"
                        id="datoreLavoro"
                        render={({ input, meta }) => (
                          <div
                            className={`form-group ${
                              meta.touched && meta.invalid ? 'has-error' : ''
                            }`}
                          >
                            <label htmlFor="datoreLavoro">Datore di lavoro</label>
                            <textarea rows="3" type="text" className="form-control" {...input} />
                            <div className="help-block">{meta.touched && meta.error}</div>
                          </div>
                        )}
                      />
                      <Field
                        name="orarioLavoro"
                        id="orarioLavoro"
                        render={({ input, meta }) => (
                          <div
                            className={`form-group ${
                              meta.touched && meta.invalid ? 'has-error' : ''
                            }`}
                          >
                            <label htmlFor="orarioLavoro">Orario lavoro</label>
                            <textarea rows="3" type="text" className="form-control" {...input} />
                            <div className="help-block">{meta.touched && meta.error}</div>
                          </div>
                        )}
                      />
                      <Field
                        name="tipologiaContratto"
                        id="tipologiaContratto"
                        render={({ input, meta }) => (
                          <div
                            className={`form-group ${
                              meta.touched && meta.invalid ? 'has-error' : ''
                            }`}
                          >
                            <label htmlFor="tipologiaContratto">Tipologia di contratto</label>
                            <textarea rows="3" type="text" className="form-control" {...input} />
                            <div className="help-block">{meta.touched && meta.error}</div>
                          </div>
                        )}
                      />
                      <Field
                        name="rangeRetributivo"
                        id="rangeRetributivo"
                        render={({ input, meta }) => (
                          <div
                            className={`form-group ${
                              meta.touched && meta.invalid ? 'has-error' : ''
                            }`}
                          >
                            <label htmlFor="rangeRetributivo">Range retributivo</label>
                            <textarea rows="3" type="text" className="form-control" {...input} />
                            <div className="help-block">{meta.touched && meta.error}</div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="panel panel-border panel-success">
                    <div className="panel-heading">
                      <h3 className="panel-title">Esperienza richiesta</h3>
                    </div>
                    <div className="panel-body">
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="livelloStudi"
                            id="livelloStudi"
                            render={({ input, meta }) => (
                              <div
                                className={`form-group ${
                                  meta.touched && meta.invalid ? 'has-error' : ''
                                }`}
                              >
                                <label htmlFor="livelloStudi">Livello di studi</label>
                                <input type="text" className="form-control" {...input} />
                                <div className="help-block">{meta.touched && meta.error}</div>
                              </div>
                            )}
                          />
                        </div>
                        <div className="col-md-6">
                          <Field
                            name="titoloStudio"
                            id="titoloStudio"
                            render={({ input, meta }) => (
                              <div
                                className={`form-group ${
                                  meta.touched && meta.invalid ? 'has-error' : ''
                                }`}
                              >
                                <label htmlFor="titoloStudio">Titolo di studio</label>
                                <input type="text" className="form-control" {...input} />
                                <div className="help-block">{meta.touched && meta.error}</div>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <Field
                        name="esperienzaRichiesta"
                        id="esperienzaRichiesta"
                        render={({ input, meta }) => (
                          <div
                            className={`form-group ${
                              meta.touched && meta.invalid ? 'has-error' : ''
                            }`}
                          >
                            <label htmlFor="esperienzaRichiesta">Esperienza richiesta</label>
                            <textarea rows="4" className="form-control" {...input} />
                            <div className="help-block">{meta.touched && meta.error}</div>
                          </div>
                        )}
                      />
                      <Field
                        name="skill"
                        id="skill"
                        render={({ input, meta }) => (
                          <div
                            className={`form-group ${
                              meta.touched && meta.invalid ? 'has-error' : ''
                            }`}
                          >
                            <label htmlFor="skill">
                              Skill professionali richieste
                              <br />
                              <small>
                                (conoscenze professionali, informatiche, linguistiche, patente)
                              </small>
                            </label>
                            <textarea rows="4" className="form-control" {...input} />
                            <div className="help-block">{meta.touched && meta.error}</div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="panel panel-border panel-pink">
                    <div className="panel-heading">
                      <h3 className="panel-title">Altre richieste</h3>
                    </div>
                    <div className="panel-body">
                      <Field
                        name="altriRequisiti"
                        id="altriRequisiti"
                        render={({ input, meta }) => (
                          <div
                            className={`form-group ${
                              meta.touched && meta.invalid ? 'has-error' : ''
                            }`}
                          >
                            <label htmlFor="altriRequisiti">Altri requisiti</label>
                            <textarea rows="4" className="form-control" {...input} />
                            <div className="help-block">{meta.touched && meta.error}</div>
                          </div>
                        )}
                      />
                      <Field
                        name="particolarita"
                        id="particolarita"
                        render={({ input, meta }) => (
                          <div
                            className={`form-group ${
                              meta.touched && meta.invalid ? 'has-error' : ''
                            }`}
                          >
                            <label htmlFor="particolarita">
                              Particolarità
                              <br />
                              <small>
                                (appartenente a cat. protette, Naspi, età in apprendistato)
                              </small>
                            </label>
                            <textarea rows="4" className="form-control" {...input} />
                            <div className="help-block">{meta.touched && meta.error}</div>
                          </div>
                        )}
                      />

                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="dataScadenza"
                            id="dataScadenza"
                            render={({ input, meta }) => (
                              <div
                                className={`form-group ${
                                  meta.touched && meta.invalid ? 'has-error' : ''
                                }`}
                              >
                                <label htmlFor="dataScadenza">Data scadenza richiesta</label>
                                <input type="date" className="form-control" {...input} />
                                <div className="help-block">{meta.touched && meta.error}</div>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Link to="/app/job-vacancies" className="btn btn-default btn-lg">
                <Ink />
                &laquo; Torna alle job vacancies
              </Link>
              &nbsp;&nbsp;
              <button type="submit" className="btn btn-primary btn-lg">
                <Ink />
                Salva job vacancy &raquo;
              </button>
            </form>
          )}
        />
      </div>
    )
  }
}

function validate(values) {
  const errors = {}

  if (!values.descrizioneSintetica) {
    errors.descrizioneSintetica = 'Inserisci la descrizione sintetica'
  }

  if (/\d/.test(values.descrizioneSintetica) || /@/.test(values.descrizioneSintetica)) {
    errors.descrizioneSintetica = 'Attenzione: non è possibile inserire numeri o e-mail'
  }

  if (!values.figuraProfessionale) {
    errors.figuraProfessionale = 'Inserisci la figura professionale'
  }

  if (!values.mansione) {
    errors.mansione = 'Inserisci la mansione'
  }

  if (!values.descrizioneCompleta) {
    errors.descrizioneCompleta = 'Inserisci la descrizione completa'
  }

  if (!values.dataInizio) {
    errors.dataInizio = 'Inserisci la data di inizio'
  }

  if (!values.orarioLavoro) {
    errors.orarioLavoro = "Inserisci l'orario di lavoro"
  }

  if (!values.luogoLavoro) {
    errors.luogoLavoro = 'Inserisci il luogo di lavoro'
  }

  if (!values.tipologiaContratto) {
    errors.tipologiaContratto = 'Inserisci la tipologia di contratto'
  }

  // if (!values.rangeRetributivo) {
  // 	errors.rangeRetributivo = 'Inserisci il range retributivo';
  // }

  // if (!values.livelloStudi) {
  // 	errors.livelloStudi = 'Inserisci il livello di studi';
  // }

  // if (!values.titoloStudio) {
  // 	errors.titoloStudio = 'Inserisci il titolo di studio';
  // }

  // if (!values.esperienzaRichiesta) {
  // 	errors.esperienzaRichiesta = 'Inserisci l\'esperienza richiesta';
  // }

  // if (!values.skill) {
  // 	errors.skill = 'Inserisci le skill richieste';
  // }

  // if (!values.dataScadenza) {
  // 	errors.dataScadenza = 'Inserisci la data di scadenza';
  // }

  return errors
}

const mapStateToProps = (state, ownProps) => {
  let editObject = {}
  if (ownProps.match.params.jobVacancyId) {
    // EDIT
    editObject = {
      initialValues: getJobVacancyData(state),
      aziendaRagioneSociale: state.jobVacancyAttiva.data.aziendaRagioneSociale,
      agenziaRagioneSociale: state.jobVacancyAttiva.data.agenziaRagioneSociale,
      sportelloNome: state.jobVacancyAttiva.data.sportelloNome,
    }
  }
  return {
    ...editObject,
    permessoJvHome: state.auth.user.permessi.gestioneJvHome,
    permessoJvSitoWeb: state.auth.user.ruolo === 'operatore',
  }
}

export default connect(mapStateToProps, {
  fetchJobVacancy,
  fetchTypeaheadComuni,
  saveJobVacancy,
  createJobVacancy,
})(EditJobVacancy)
