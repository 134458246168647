import axios from 'axios'
import cogoToast from 'cogo-toast'
import _debounce from 'lodash/debounce'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router'
import {
  // notifyError,
  // notifyWarning,
  fetchUser,
  logoutUser,
} from '../../actions'
import AppLogged from '../app/app_logged'
import ForgotPassword from '../auth/forgot_password'
import RequireAuth from '../auth/require_auth'
import ResetPassword from '../auth/reset_password'
import RevocaConsenso from '../auth/revoca_consenso'
import Footer from '../footer/footer'
import Header from '../header/header'
import Public from '../public/public'
import './app.css'
import Notification from './notification'

class App extends Component {
  constructor(props) {
    super(props)

    // Set Authorization Token on every request to server
    axios.interceptors.request.use(function (config) {
      config.headers.Authorization = localStorage.getItem('token')
      return config
    })

    let self = this

    // const notifyErrorDebounced = _debounce(self.props.notifyError, 1000)

    // Intercept 401 errors
    axios.interceptors.response.use(
      function (response) {
        // Response OK
        return response
      },
      function (error) {
        // Error
        if (error.response && error.response.status && error.response.status === 401) {
          // self.props.logoutUser(this.props.history)
          // if (error.data.message !== 'Username o password non validi.') {
          //   self.props.notifyWarning('Sessione scaduta', 'La sessione è scaduta, dovrai inserire nuovamente le credenziali.');
          // }

          self.props.history.push('/')
        } else if (error.response && error.response.status && error.response.status === 409) {
          cogoToast.error('Errore', {
            position: 'top-right',
            hideAfter: 10,
          })
          // self.props.notifyError('Errore', error.response.data.message)
        } else if (error.response && error.response.status && error.response.status === 400) {
          cogoToast.error(
            error.response.data.message === 'Password non valida'
              ? 'Password non valida: lunghezza minima di 8 caratteri, almeno un numero e almeno un carattere non numerico'
              : 'Qualcosa è andato storto',
            {
              heading: 'Errore',
              position: 'top-right',
              hideAfter: 10,
            }
          )
          // self.props.notifyError('Errore', 'Errore di validazione dei dati inseriti')
        } else {
          _debounce(
            () =>
              cogoToast.error("E' avvenuto un errore durante la comunicazione con il server", {
                heading: 'Errore',
                position: 'top-right',
                hideAfter: 10,
              }),
            10000
          )
          // notifyErrorDebounced(
          //   'Errore',
          //   "E' avvenuto un errore durante la comunicazione con il server."
          // )
        }

        return Promise.reject(error)
      }
    )
  }

  render() {
    // return (
    //   <div style={{ height: '1000px', position: 'relative' }}>
    //     <nav style={{ position: 'sticky', top: 0, left: 0, right: 0, bottom: 0 }}>ciao</nav>
    //   </div>
    // )
    return (
      <div>
        <Notification />
        <Header />
        <div className="wrapper">
          <Switch>
            <Route exact path="/" component={Public} />
            <Route path="/password-dimenticata" component={ForgotPassword} />
            <Route path="/revoca-consenso" component={RevocaConsenso} />
            <Route path="/app" component={RequireAuth()(AppLogged)} />
            <Route path="/reset-password/:token" component={ResetPassword} />
          </Switch>
        </div>
        <Footer />
      </div>
    )
  }
}

App = withRouter(App)

export default connect(null, {
  // notifyError,
  // notifyWarning,
  fetchUser,
  logoutUser,
})(App)
