import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Ink from 'react-ink'
import swal from 'sweetalert'

import { removeTitoloStudio } from '../../actions'
import { MdModeEdit, MdRemoveCircle } from 'react-icons/md'

class TitoloStudio extends Component {
  onRemove() {
    this.props.removeTitoloStudio(this.props.titoloStudio.titoloStudioId, this.props.candidatoId)
  }

  handleDeleteTitoloDiStudio = () => {
    swal({
      icon: 'warning',
      title: 'Eliminazione titolo di studio',
      text: 'Sei sicuro di vole eliminare questo titolo di studio? Questa azione è irreversibile.',
      buttons: ['Annulla', 'Si, elimina titolo di studio'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        this.onRemove()
      }
    })
  }

  render() {
    const { titoloStudio, candidatoId, canEdit } = this.props

    return (
      <div className={'panel panel-color panel-blue panel-show-section'}>
        <div className="panel-heading">
          <div className="row">
            <div className="col-xs-6">
              <h3 className="panel-title">
                {titoloStudio.tipo} - <br className="hidden-md hidden-lg" />
                {titoloStudio.titolo}
              </h3>
            </div>
            <div className="col-xs-6 actions">
              {canEdit ? (
                <h3 className="panel-title">
                  <Link
                    to={`/app/candidati/${candidatoId}/titoli-studio/${titoloStudio.titoloStudioId}`}
                    className="btn btn-sm btn-blue"
                  >
                    <MdModeEdit />
                    <span>Modifica</span>
                  </Link>
                  <button
                    className="btn btn-sm btn-blue btn-remove"
                    onClick={() => this.handleDeleteTitoloDiStudio()}
                  >
                    <MdRemoveCircle />
                    <span>Elimina</span>
                  </button>
                </h3>
              ) : null}
            </div>
          </div>
        </div>
        <div className="panel-body">
          <div className="row">
            <div className="col-sm-6">
              <p>
                <b>Presso {titoloStudio.istituto}</b>
              </p>
              <p>
                Dal {titoloStudio.dataInizioMese}/{titoloStudio.dataInizioAnno} al{' '}
                <b>
                  {titoloStudio.dataFineMese}/{titoloStudio.dataFineAnno}
                </b>
                , voto <b>{titoloStudio.voto}</b>
              </p>
            </div>
            <div className="col-sm-6">
              <div className="dettagli">
                <p>{titoloStudio.descrizioneCv || 'Nessuna descrizione CV'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, { removeTitoloStudio })(TitoloStudio)
