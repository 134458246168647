import React, { Component } from 'react'
import AsyncSelect from 'react-select/async'
import { connect } from 'react-redux'
import { Form, Field } from 'react-final-form'
import Ink from 'react-ink'
import { MdAdd } from 'react-icons/md'

import {
  fetchGruppi,
  fetchTypeaheadCandidati,
  sendEmail,
  sendSms,
  resetStatoInvioMessaggio,
} from '../../actions'

class InvioMessaggio extends Component {
  constructor(props) {
    super(props)

    this.state = {
      candidato: {
        nomeCognome: null,
      },
    }
  }

  componentDidMount() {
    this.props.fetchGruppi()
    this.props.resetStatoInvioMessaggio()
    // this.props.resetForm()
    this.setState({
      candidato: {
        nomeCognome: null,
      },
    })
  }

  onSubmit(values) {
    if (values.tipoMessaggio === 'email') {
      if (values.destinatario === 'candidato' && values.candidatoId.value) {
        values.candidatoId = values.candidatoId.value
      }

      this.props.sendEmail(values).then(
        // this.props.resetForm,
        this.setState({
          candidato: {
            nomeCognome: null,
          },
        })
      )
    } else {
      this.props.sendSms(values).then(
        // this.props.resetForm,
        this.setState({
          candidato: {
            nomeCognome: null,
          },
        })
      )
    }
  }

  ///////////////////////////////////////////////
  // GESTIONE AUTOCOMPLETE CANDIDATI
  getCandidatiOptions = (candidati) => {
    return candidati.map((candidato) => {
      return {
        value: candidato.candidatoId,
        label: candidato.nomeCognome,
        fotografia: candidato.fotografia,
      }
    })
  }

  getCandidati = (input) => {
    return this.props.fetchTypeaheadCandidati(input).then((action) => {
      return this.getCandidatiOptions(action.payload.data)
    })
  }

  onChangeCandidato(option) {
    this.props.fields.candidatoId.onChange(option ? option.value : null)
    this.setState({
      candidato: {
        nomeCognome: option ? option.label : null,
      },
    })
  }

  renderOption(option) {
    return (
      <span>
        <img className="avatar" src={option.fotografia} alt="avatar" />
        {option.label}
      </span>
    )
  }

  ///////////////////////////////////////////////
  // GESTIONE SELECT GRUPPI
  renderGruppiOptions() {
    if (this.props.gruppi && this.props.gruppi.length > 0) {
      return this.props.gruppi.map((gruppo) => (
        <option key={gruppo.gruppoCvSinteticoId} value={gruppo.gruppoCvSinteticoId}>
          {gruppo.nome}
        </option>
      ))
    }
  }

  ///////////////////////////////////////////////
  // RENDER
  render() {
    const lunghezzaMaxSms = 160
    // const lunghezzaSms = messaggio.value ? messaggio.value.length : 0
    const initialValues = this.props.initialValues ? this.props.initialValues : {}

    return (
      <div className="invio-messaggio">
        <h1 className="page-title">Invio Messaggio</h1>

        {!this.props.messaggioInviato ? (
          <Form
            onSubmit={this.onSubmit.bind(this)}
            initialValues={initialValues}
            validate={validate}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit} className="invio-messaggio-form">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="panel panel-border panel-success">
                      <div className="panel-heading">
                        <h3 className="panel-title">Destinatario</h3>
                      </div>
                      <div className="panel-body">
                        <div
                          className={`form-group ${
                            form.getFieldState('destinatario')?.touched &&
                            form.getFieldState('destinatario')?.invalid
                              ? 'has-error'
                              : ''
                          }`}
                        >
                          <div>
                            <div className="radio radio-success radio-inline">
                              <Field
                                name="destinatario"
                                type="radio"
                                id="destinatario_candidato"
                                value="candidato"
                                component="input"
                              />
                              <label htmlFor="destinatario_candidato">Candidato</label>
                            </div>
                            <div className="radio radio-success radio-inline">
                              <Field
                                name="destinatario"
                                type="radio"
                                id="destinatario_gruppo"
                                value="gruppo"
                                component="input"
                              />
                              <label htmlFor="destinatario_gruppo">Gruppo</label>
                            </div>
                          </div>
                          <div className="help-block">
                            {form.getFieldState('destinatario')?.touched &&
                              form.getFieldState('destinatario')?.error}
                          </div>
                        </div>

                        {form.getFieldState('destinatario')?.value === 'candidato' && (
                          <div>
                            <Field
                              name="candidatoId"
                              id="candidatoId"
                              render={({ input, meta }) => (
                                <div
                                  className={`form-group ${
                                    meta.touched && meta.invalid ? 'has-error' : ''
                                  }`}
                                >
                                  <label htmlFor="candidatoId">Seleziona candidato</label>
                                  <AsyncSelect
                                    loadOptions={this.getCandidati}
                                    placeholder="Seleziona..."
                                    loadingPlaceholder="Caricamento..."
                                    searchingText="Caricamento..."
                                    noResultsText="Nessun risultato"
                                    optionRenderer={this.renderOption}
                                    {...input}
                                  />
                                  <div className="help-block">{meta.touched && meta.error}</div>
                                </div>
                              )}
                            />
                          </div>
                        )}
                        {form.getFieldState('destinatario')?.value === 'gruppo' && (
                          <Field
                            name="gruppoCvSinteticoId"
                            id="gruppoCvSinteticoId"
                            render={({ input, meta }) => (
                              <div
                                className={`form-group ${
                                  meta.touched && meta.invalid ? 'has-error' : ''
                                }`}
                              >
                                <label htmlFor="gruppoCvSinteticoId">Seleziona gruppo</label>
                                <select className="form-control" {...input}>
                                  <option value="">-- Seleziona gruppo --</option>
                                  {this.renderGruppiOptions()}
                                </select>
                                <div className="help-block">{meta.touched && meta.error}</div>
                              </div>
                            )}
                          />
                        )}
                      </div>
                    </div>

                    <div className="panel panel-border panel-primary">
                      <div className="panel-heading">
                        <h3 className="panel-title">Messaggio</h3>
                      </div>
                      <div className="panel-body">
                        <div
                          className={`form-group ${
                            form.getFieldState('tipoMessaggio')?.touched &&
                            form.getFieldState('tipoMessaggio')?.invalid
                              ? 'has-error'
                              : ''
                          }`}
                        >
                          <div>
                            <div className="radio radio-primary radio-inline">
                              <Field
                                type="radio"
                                name="tipoMessaggio"
                                id="tipoMessaggio_email"
                                value="email"
                                component="input"
                              />
                              <label htmlFor="tipoMessaggio_email">Email</label>
                            </div>
                            <div className="radio radio-primary radio-inline">
                              <Field
                                type="radio"
                                name="tipoMessaggio"
                                id="tipoMessaggio_sms"
                                value="sms"
                                component="input"
                              />
                              <label htmlFor="tipoMessaggio_sms">SMS</label>
                            </div>
                          </div>
                          <div className="help-block">
                            {form.getFieldState('tipoMessaggio')?.touched &&
                              form.getFieldState('tipoMessaggio')?.error}
                          </div>
                        </div>

                        {form.getFieldState('tipoMessaggio')?.value === 'email' && (
                          <div>
                            <Field
                              id="oggetto"
                              name="oggetto"
                              render={({ input, meta }) => (
                                <div
                                  className={`form-group ${
                                    meta.touched && meta.invalid ? 'has-error' : ''
                                  }`}
                                >
                                  <label htmlFor="oggetto">Oggetto</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="oggetto"
                                    {...input}
                                  />
                                  <div className="help-block">{meta.touched && meta.error}</div>
                                </div>
                              )}
                            />
                            <Field
                              id="messaggio"
                              name="messaggio"
                              render={({ input, meta }) => (
                                <div
                                  className={`form-group ${
                                    meta.touched && meta.invalid ? 'has-error' : ''
                                  }`}
                                >
                                  <label htmlFor="messaggio">Messaggio</label>
                                  <textarea
                                    className="form-control"
                                    id="messaggio"
                                    {...input}
                                    rows="6"
                                  />
                                  <div className="help-block">{meta.touched && meta.error}</div>
                                </div>
                              )}
                            />
                          </div>
                        )}

                        {form.getFieldState('tipoMessaggio')?.value === 'sms' && (
                          <div>
                            <Field
                              name="messaggio"
                              id="messaggio"
                              render={({ input, meta }) => (
                                <div
                                  className={`form-group ${
                                    meta.touched && meta.invalid ? 'has-error' : ''
                                  }`}
                                >
                                  <label htmlFor="messaggio">Messaggio</label>
                                  <textarea className="form-control" {...input} rows="3" />
                                  <div className="row">
                                    <div className="col-xs-6">
                                      <div className="help-block">{meta.touched && meta.error}</div>
                                    </div>
                                    <div className="col-xs-6">
                                      <div
                                        className={`help-block text-right ${
                                          form.getFieldState('messaggio')?.value
                                            ? (form.getFieldState('messaggio')?.value + '').length
                                            : 0 > lunghezzaMaxSms
                                            ? 'error'
                                            : ''
                                        }`}
                                      >
                                        {lunghezzaMaxSms -
                                          (form.getFieldState('messaggio')?.value
                                            ? form.getFieldState('messaggio')?.value + ''
                                            : ''
                                          ).length}{' '}
                                        caratteri rimasti
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <button
                      type="submit"
                      disabled={!form.getState()?.valid}
                      className="btn btn-primary btn-lg"
                    >
                      <Ink />
                      Invia messaggio &raquo;
                    </button>
                  </div>

                  <div className="col-sm-6">
                    <img src="https://steamuserimages-a.akamaihd.net/ugc/255969629398159937/DDA5094FBFD15E3B57317C20308BFEFC7163B889/" />
                  </div>
                </div>
              </form>
            )}
          />
        ) : (
          <div className="row">
            <div className="col-sm-6">
              <div className="alert alert-success">
                <h4>
                  <strong>Messaggio inviato!</strong>
                </h4>
                <button className="btn btn-success" onClick={this.props.resetStatoInvioMessaggio}>
                  <Ink />
                  Invia un altro messaggio &raquo;
                </button>
              </div>
            </div>
            <div className="col-sm-6">
              <img src="https://steamuserimages-a.akamaihd.net/ugc/255969629398159937/DDA5094FBFD15E3B57317C20308BFEFC7163B889/" />
            </div>
          </div>
        )}
      </div>
    )
  }
}

function validate(values) {
  const errors = {}

  if (values.tipoMessaggio === 'email' && !values.oggetto) {
    errors.oggetto = 'Inserisci un oggetto'
  }

  if (!values.messaggio) {
    errors.messaggio = 'Inserisci un messaggio'
  }

  if (values.tipoMessaggio === 'sms' && !/^[\s\S]{0,160}$/.test(values.messaggio)) {
    errors.messaggio = 'Inserisci al massimo 160 caratteri.'
  }

  if (values.destinatario === 'candidato' && !values.candidatoId) {
    errors.candidatoId = 'Seleziona un candidato'
  }

  if (values.destinatario === 'gruppo' && !values.gruppoCvSinteticoId) {
    errors.gruppoCvSinteticoId = 'Seleziona un gruppo'
  }

  return errors
}

const mapStateToProps = (state, props) => {
  return {
    gruppi: state.gruppi,
    messaggioInviato: state.messaggi.inviato,
    initialValues: {
      tipoMessaggio: 'email',
      destinatario: 'candidato',
    },
    invioMessaggioSms: state.auth.user.permessi.invioSms,
  }
}

export default connect(mapStateToProps, {
  fetchGruppi,
  fetchTypeaheadCandidati,
  sendEmail,
  sendSms,
  resetStatoInvioMessaggio,
})(InvioMessaggio)
