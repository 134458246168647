import React, { Component } from 'react'
import {
  MdContentCopy,
  MdModeEdit,
  MdRemoveCircle,
  MdVisibility,
  MdVisibilityOff,
} from 'react-icons/md'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'
import {
  duplicateEsperienzaLavorativa,
  removeEsperienzaLavorativa,
  updateEsperienzaLavorativa,
} from '../../actions'

class EsperienzaLavorativa extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visibile: !this.props.esperienzaLavorativa.nascondiDaCvFlag,
    }
  }

  remove() {
    this.props.removeEsperienzaLavorativa(
      this.props.esperienzaLavorativa.esperienzaLavorativaId,
      this.props.candidatoId
    )
  }

  switchAttivaDisattiva = value => event => {
    console.log('VISIBILE: ', value)
    event.preventDefault()
    this.setState({ visibile: value })
    const { esperienzaLavorativaId, ...rest } = this.props.esperienzaLavorativa
    this.props.updateEsperienzaLavorativa(
      this.props.esperienzaLavorativa.esperienzaLavorativaId,
      { ...rest, nascondiDaCvFlag: !value },
      this.props.candidatoId
    )
  }

  duplica = event => {
    event.preventDefault()
    this.props.duplicateEsperienzaLavorativa(
      this.props.esperienzaLavorativa.esperienzaLavorativaId,
      this.props.candidatoId
    )
  }

  renderMotivazioneFine() {
    const { esperienzaLavorativa } = this.props
    let motivazione = ''

    if (esperienzaLavorativa.dataFine && esperienzaLavorativa.motivazioneTermine) {
      motivazione = esperienzaLavorativa.motivazioneTermine

      if (
        esperienzaLavorativa.motivazioneTermine === 'Altro' &&
        esperienzaLavorativa.motivazioneAltro
      ) {
        motivazione += ' - ' + esperienzaLavorativa.motivazioneAltro
      }
    }

    return motivazione ? `(${motivazione})` : '' // (motivazione)
  }

  handleDeleteEsperienzaLavorativa = () => {
    swal({
      icons: 'warning',
      title: 'Eliminazione esperienza lavorativa',
      text: 'Sei sicuro di vole eliminare questa esperienza lavorativa? Questa azione è irreversibile.',
      buttons: ['Annulla', 'Si, elimina esperienza lavorativa'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        this.remove()
      }
    })
  }

  render() {
    const { esperienzaLavorativa, candidatoId, canEdit } = this.props

    return (
      <div
        className={
          this.state.visibile
            ? 'panel panel-color panel-primary panel-show-section'
            : 'panel panel-color panel-hidden panel-show-section'
        }
      >
        <div className="panel-heading">
          <div className="row">
            <div className="col-xs-5">
              <h3
                className="panel-title"
                style={{ color: this.state.visibile ? '#fff' : '#333' }}
              >
                {esperienzaLavorativa.azienda}
                <br className="hidden-md hidden-lg" />
                <small>
                  {esperienzaLavorativa.settoreAttivita} - {esperienzaLavorativa.indirizzo}
                </small>
              </h3>
            </div>
            <div className="col-xs-7 actions">
              {canEdit ? (
                <h3 className="panel-title flex">
                  <a
                    href="/#"
                    style={{ display: 'flex', alignItems: 'center' }}
                    className={
                      this.state.visibile ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-hidden'
                    }
                    onClick={this.switchAttivaDisattiva(!this.state.visibile)}
                  >
                    {this.state.visibile ? <MdVisibility /> : <MdVisibilityOff />}
                    Attiva/Disattiva CV
                  </a>
                  <a
                    href="/#"
                    style={{ display: 'flex', alignItems: 'center' }}
                    className={
                      this.state.visibile ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-hidden'
                    }
                    onClick={this.duplica}
                  >
                    <MdContentCopy />
                    Duplica
                  </a>
                  <Link
                    to={`/app/candidati/${candidatoId}/esperienze-lavorative/${esperienzaLavorativa.esperienzaLavorativaId}`}
                    style={{ display: 'flex', alignItems: 'center' }}
                    className={
                      this.state.visibile ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-hidden'
                    }
                  >
                    <MdModeEdit />
                    <span>Modifica</span>
                  </Link>
                  <a
                    href="/#"
                    style={{ display: 'flex', alignItems: 'center' }}
                    className={
                      this.state.visibile
                        ? 'btn btn-sm btn-primary btn-remove'
                        : 'btn btn-sm btn-hidden btn-remove'
                    }
                    onClick={event => {
                      event.preventDefault()
                      this.handleDeleteEsperienzaLavorativa()
                    }}
                  >
                    <MdRemoveCircle />
                    <span>Elimina</span>
                  </a>
                </h3>
              ) : null}
            </div>
          </div>
        </div>
        <div className="panel-body">
          <div className="row">
            <div className="col-sm-6">
              <p>
                <b>
                  {esperienzaLavorativa.posizioneProfessionale} -{' '}
                  {esperienzaLavorativa.tipoQualificaLegale}&nbsp;
                  {esperienzaLavorativa.tipoContratto !== 'Altro'
                    ? esperienzaLavorativa.tipoContratto
                    : esperienzaLavorativa.altroTipo}
                </b>
              </p>
              <p>
                <b>
                  {esperienzaLavorativa.dataInizioMese}/{esperienzaLavorativa.dataInizioAnno} -{' '}
                  {esperienzaLavorativa.dataFineMese && esperienzaLavorativa.dataFineAnno
                    ? esperienzaLavorativa.dataFineMese + '/' + esperienzaLavorativa.dataFineAnno
                    : 'Oggi'}
                </b>{' '}
                {this.renderMotivazioneFine()}
              </p>
            </div>
            <div className="col-sm-6">
              <div className="dettagli">{esperienzaLavorativa.mansioni}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, {
  removeEsperienzaLavorativa,
  duplicateEsperienzaLavorativa,
  updateEsperienzaLavorativa,
})(EsperienzaLavorativa)
