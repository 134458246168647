import {
  FETCH_CANDIDATO,
  RESET_CANDIDATO,
  SAVE_ANAGRAFICA,
  SAVE_SITO_WEB_FLAG,
  SAVE_STAMPE,
  SET_NOTE_AGENZIA,
  SAVE_NOTE_AGENZIA_DA_OPERATORE,
  SAVE_PRIVACY,
  SET_STATO_SERVIZIO,
  SET_NOTE_SERVIZIO,
  REMOVE_ESPERIENZA_LAVORATIVA,
  DUPLICATE_ESPERIENZA_LAVORATIVA,
  REMOVE_TITOLO_STUDIO,
  REMOVE_CORSO_FORMAZIONE,
  REMOVE_CONOSCENZA_LINGUISTICA,
  REMOVE_CONOSCENZA_INFORMATICA,
  UPLOAD_ALLEGATI,
  REMOVE_ALLEGATO,
  ADD_OBIETTIVO_PROFESSIONALE,
  SAVE_OBIETTIVO_PROFESSIONALE,
  REMOVE_OBIETTIVO_PROFESSIONALE,
  SET_CENTRO_DISPONIBILITA_GEOGRAFICA,
  SET_RAGGIO_DISPONIBILITA_GEOGRAFICA,
  SAVE_NUOVO_SPORTELLO,
  CONFERMA_SPOSTAMENTO,
  ANNULLA_SPOSTAMENTO,
  SET_VIDEOCV_URL,
  FETCH_GRUPPI_CANDIDATO,
  ADD_GRUPPO_CANDIDATO,
  CREATE_NOTA_DIARIO_CANDIDATO,
  DELETE_NOTA_DIARIO_CANDIDATO,
  SET_FILTER_NOTE_DIARIO_ESCLUDI_AUTOMATICHE,
} from '../actions/types'

import moment from 'moment'

const INITIAL_STATE = {
  anagrafica: {},
  gruppi: [],
  stampe: {},
  sportello: {},
  account: {},
  ultimaModifica: {},
  statoOccupazionale: {},
  servizio: { lavoro: {}, did: {}, dimissioni: {}, consulenza: {} },
  acquisizioniAgenzie: [],
  noteDiario: [],
  filterNoteDiarioEscludiAutomatiche: true,
}

const formatDateIso = (date) => {
  return date ? moment(date).format('YYYY-MM-DD') : null
}

const formatDateMonth = (date) => {
  return date ? moment(date).format('YYYY-MM') : null
}

const formatDateIta = (date) => {
  return date ? moment(date).format('DD/MM/YYYY') : null
}

const formatDateTimeIta = (date) => {
  return date ? moment(date).format('DD/MM/YYYY HH:mm') : null
}

const formatDateMeseAnno = (date) => {
  return date ? moment(date).format('MMMM YYYY') : null
}

const calcolaEta = (date) => {
  return moment().diff(moment(date), 'years')
}

const formatDates = (candidato) => {
  // ANAGRAFICA
  candidato.anagrafica.dataNascita = formatDateIso(candidato.anagrafica.dataNascita)
  candidato.anagrafica.dataNascitaF = formatDateIta(candidato.anagrafica.dataNascita)
  candidato.anagrafica.eta = calcolaEta(candidato.anagrafica.dataNascita)

  candidato.anagrafica.documentoScadenza = formatDateIso(candidato.anagrafica.documentoScadenza)
  candidato.anagrafica.documentoScadenzaF = formatDateIta(candidato.anagrafica.documentoScadenza)

  candidato.anagrafica.extracomunitarioPermessoSoggiornoScadenza = formatDateIso(
    candidato.anagrafica.extracomunitarioPermessoSoggiornoScadenza
  )
  candidato.anagrafica.extracomunitarioPermessoSoggiornoScadenzaF = formatDateIta(
    candidato.anagrafica.extracomunitarioPermessoSoggiornoScadenza
  )

  candidato.ultimaModifica.dataF = formatDateTimeIta(candidato.ultimaModifica.data)

  // STATO OCCUPAZIONALE
  candidato.statoOccupazionale.occupatoCassaIntegrazioneInizio = formatDateIso(
    candidato.statoOccupazionale.occupatoCassaIntegrazioneInizio
  )
  candidato.statoOccupazionale.occupatoCassaIntegrazioneInizioF = formatDateIta(
    candidato.statoOccupazionale.occupatoCassaIntegrazioneInizio
  )

  candidato.statoOccupazionale.occupatoCassaIntegrazioneFine = formatDateIso(
    candidato.statoOccupazionale.occupatoCassaIntegrazioneFine
  )
  candidato.statoOccupazionale.occupatoCassaIntegrazioneFineF = formatDateIta(
    candidato.statoOccupazionale.occupatoCassaIntegrazioneFine
  )

  candidato.statoOccupazionale.occupatoContrattoSolidarietaInizio = formatDateIso(
    candidato.statoOccupazionale.occupatoContrattoSolidarietaInizio
  )
  candidato.statoOccupazionale.occupatoContrattoSolidarietaInizioF = formatDateIta(
    candidato.statoOccupazionale.occupatoContrattoSolidarietaInizio
  )

  candidato.statoOccupazionale.occupatoContrattoSolidarietaFine = formatDateIso(
    candidato.statoOccupazionale.occupatoContrattoSolidarietaFine
  )
  candidato.statoOccupazionale.occupatoContrattoSolidarietaFineF = formatDateIta(
    candidato.statoOccupazionale.occupatoContrattoSolidarietaFine
  )

  candidato.statoOccupazionale.disoccupatoStrumentiSostegnoInizio = formatDateIso(
    candidato.statoOccupazionale.disoccupatoStrumentiSostegnoInizio
  )
  candidato.statoOccupazionale.disoccupatoStrumentiSostegnoInizioF = formatDateIta(
    candidato.statoOccupazionale.disoccupatoStrumentiSostegnoInizio
  )

  candidato.statoOccupazionale.disoccupatoUltimoLavoroDataFine = formatDateIso(
    candidato.statoOccupazionale.disoccupatoUltimoLavoroDataFine
  )
  candidato.statoOccupazionale.disoccupatoUltimoLavoroDataFineF = formatDateIta(
    candidato.statoOccupazionale.disoccupatoUltimoLavoroDataFine
  )

  candidato.statoOccupazionale.didDataPresentazione = formatDateIso(candidato.statoOccupazionale.didDataPresentazione)
  candidato.statoOccupazionale.didDataPresentazioneF = formatDateIta(candidato.statoOccupazionale.didDataPresentazione)

  candidato.statoOccupazionale.centroImpiegoDataIscrizione = formatDateIso(
    candidato.statoOccupazionale.centroImpiegoDataIscrizione
  )
  candidato.statoOccupazionale.centroImpiegoDataIscrizioneF = formatDateIta(
    candidato.statoOccupazionale.centroImpiegoDataIscrizione
  )
  return candidato
}

export default function reducerCandidato(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_CANDIDATO:
      const candidato = { ...action.payload.data, filterNoteDiarioEscludiAutomatiche: true }

      return {
        ...formatDates(candidato),
      }
    case RESET_CANDIDATO:
      return {}
    case SAVE_ANAGRAFICA:
      return {
        ...state,
        ...action.payload,
        gruppi: state.gruppi,
      }
    case SAVE_SITO_WEB_FLAG:
      return {
        ...state,
        sitoWebFlag: action.payload.data.sitoWebFlag,
      }
    case SAVE_STAMPE:
      return {
        ...state,
        stampe: {
          ...state.stampe,
          ...action.payload.data.stampe,
        },
      }
    case SET_NOTE_AGENZIA:
      return {
        ...state,
        noteAgenzia: action.payload,
      }
    case SAVE_NOTE_AGENZIA_DA_OPERATORE:
      return {
        ...state,
        acquisizioniAgenzie: state.acquisizioniAgenzie.map((m) => {
          if (m.movimentoCreditiAgenziaId === action.payload.data.movimentoCreditiAgenziaId) {
            return {
              ...m,
              note: action.payload.data.note,
            }
          }
          return m
        }),
      }
    case SET_STATO_SERVIZIO:
      return {
        ...state,
        servizio: {
          ...state.servizio,
          [action.payload.servizio]: {
            ...state.servizio[action.payload.servizio],
            stato: action.payload.stato,
          },
        },
      }
    case SET_NOTE_SERVIZIO:
      return {
        ...state,
        servizio: {
          ...state.servizio,
          [action.payload.servizio]: {
            ...state.servizio[action.payload.servizio],
            note: action.payload.note,
          },
        },
      }
    case DUPLICATE_ESPERIENZA_LAVORATIVA:
      return {
        ...state,
        esperienzeLavorative: [...state.esperienzeLavorative, action.payload.data],
      }
    case REMOVE_ESPERIENZA_LAVORATIVA:
      return {
        ...state,
        esperienzeLavorative: state.esperienzeLavorative.filter(
          (esperienzaLavorativa) => esperienzaLavorativa.esperienzaLavorativaId !== action.payload
        ),
      }
    case REMOVE_TITOLO_STUDIO:
      return {
        ...state,
        titoliStudio: state.titoliStudio.filter((titoloStudio) => titoloStudio.titoloStudioId !== action.payload),
      }
    case REMOVE_CORSO_FORMAZIONE:
      return {
        ...state,
        corsiFormazione: state.corsiFormazione.filter(
          (corsoFormazione) => corsoFormazione.corsoFormazioneId !== action.payload
        ),
      }
    case REMOVE_CONOSCENZA_LINGUISTICA:
      return {
        ...state,
        conoscenzeLinguistiche: state.conoscenzeLinguistiche.filter(
          (conoscenzaLinguistica) => conoscenzaLinguistica.conoscenzaLinguisticaId !== action.payload
        ),
      }
    case REMOVE_CONOSCENZA_INFORMATICA:
      return {
        ...state,
        conoscenzeInformatiche: {
          ...state.conoscenzeInformatiche,
          conoscenze: state.conoscenzeInformatiche.conoscenze.filter(
            (conoscenzaInformatica) => conoscenzaInformatica.conoscenzaInformaticaId !== action.payload
          ),
        },
      }
    case UPLOAD_ALLEGATI:
      return {
        ...state,
        allegati: [...state.allegati, ...action.payload.data],
      }
    case REMOVE_ALLEGATO:
      return {
        ...state,
        allegati: state.allegati.filter((allegato) => allegato.allegatoId !== action.payload),
      }
    case ADD_OBIETTIVO_PROFESSIONALE: {
      return {
        ...state,
        obiettiviProfessionali: {
          ...state.obiettiviProfessionali,
          posizioniProfessionali: [...state.obiettiviProfessionali.posizioniProfessionali, action.payload.data],
        },
      }
    }
    case SAVE_OBIETTIVO_PROFESSIONALE: {
      return {
        ...state,
        obiettiviProfessionali: {
          ...state.obiettiviProfessionali,
          posizioniProfessionali: state.obiettiviProfessionali.posizioniProfessionali.map((posizioneProfessionale) => {
            if (posizioneProfessionale.obiettivoProfessionaleId === action.payload.data.obiettivoProfessionaleId) {
              return {
                ...posizioneProfessionale,
                selezionatoFlag: action.payload.data.selezionatoFlag,
              }
            }
            return posizioneProfessionale
          }),
        },
      }
    }
    case REMOVE_OBIETTIVO_PROFESSIONALE: {
      return {
        ...state,
        obiettiviProfessionali: {
          ...state.obiettiviProfessionali,
          posizioniProfessionali: state.obiettiviProfessionali.posizioniProfessionali.filter(
            (posizioneProfessionale) => posizioneProfessionale.obiettivoProfessionaleId !== action.payload
          ),
        },
      }
    }
    case SET_CENTRO_DISPONIBILITA_GEOGRAFICA: {
      return {
        ...state,
        obiettiviProfessionali: {
          ...state.obiettiviProfessionali,
          disponibilitaGeografica: {
            ...state.obiettiviProfessionali?.disponibilitaGeografica,
            lat: action.payload.lat,
            lng: action.payload.lng,
          },
        },
      }
    }
    case SET_RAGGIO_DISPONIBILITA_GEOGRAFICA: {
      return {
        ...state,
        obiettiviProfessionali: {
          ...state.obiettiviProfessionali,
          disponibilitaGeografica: {
            ...state.obiettiviProfessionali?.disponibilitaGeografica,
            raggio: action.payload,
          },
        },
      }
    }
    case SAVE_NUOVO_SPORTELLO:
    case CONFERMA_SPOSTAMENTO:
    case ANNULLA_SPOSTAMENTO: {
      return {
        ...state,
        sportello: action.payload.data.sportello,
      }
    }

    case SAVE_PRIVACY: {
      return {
        ...state,
        consensoPrivacyFlag: action.payload.data.consensoPrivacyFlag,
        consensoPrivacyMarketingFlag: action.payload.data.consensoPrivacyMarketingFlag,
        consensoPrivacyData: action.payload.data.consensoPrivacyData,
      }
    }

    case SET_VIDEOCV_URL: {
      return {
        ...state,
        videoCvUrl: action.payload,
      }
    }
    case FETCH_GRUPPI_CANDIDATO: {
      return {
        ...state,
        gruppi: action.payload.data,
      }
    }
    case ADD_GRUPPO_CANDIDATO: {
      return {
        ...state,
        gruppi: [...state.gruppi, action.payload.data],
      }
    }
    case CREATE_NOTA_DIARIO_CANDIDATO:
      const noteDiario = [action.payload.data, ...state.noteDiario]
      noteDiario.sort((a, b) => a.data < b.data)
      console.log('Creazione nota', noteDiario)

      return {
        ...state,
        noteDiario: noteDiario,
      }
    case DELETE_NOTA_DIARIO_CANDIDATO:
      return {
        ...state,
        noteDiario: state.noteDiario.filter((notaDiario) => notaDiario.notaDiarioId !== action.payload),
      }
    case SET_FILTER_NOTE_DIARIO_ESCLUDI_AUTOMATICHE:
      return {
        ...state,
        filterNoteDiarioEscludiAutomatiche: action.payload,
      }
    default:
      return state
  }
}

export function isAnagraficaCompleta(globalState) {
  const isAnagraficaCompleta =
    globalState.candidatoAttivo.anagrafica &&
    globalState.candidatoAttivo.anagrafica.nome &&
    globalState.candidatoAttivo.anagrafica.cognome &&
    globalState.candidatoAttivo.anagrafica.codiceFiscale &&
    globalState.candidatoAttivo.anagrafica.dataNascita &&
    globalState.candidatoAttivo.anagrafica.luogoNascita &&
    globalState.candidatoAttivo.anagrafica.sesso &&
    globalState.candidatoAttivo.anagrafica.fotografia &&
    globalState.candidatoAttivo.anagrafica.cittadinanza &&
    globalState.candidatoAttivo.anagrafica.linguaMadre &&
    globalState.candidatoAttivo.anagrafica.residenzaIndirizzo &&
    globalState.candidatoAttivo.anagrafica.residenzaCap &&
    globalState.candidatoAttivo.anagrafica.residenzaComune &&
    globalState.candidatoAttivo.anagrafica.domicilioIndirizzo &&
    globalState.candidatoAttivo.anagrafica.domicilioCap &&
    globalState.candidatoAttivo.anagrafica.domicilioComune &&
    (globalState.candidatoAttivo.anagrafica.telefono ||
      globalState.candidatoAttivo.anagrafica.cellulare ||
      globalState.candidatoAttivo.anagrafica.email) &&
    globalState.candidatoAttivo.descrizioneCvSintetico &&
    globalState.candidatoAttivo.descrizioneCvSintetico.length >= 400

  return !!isAnagraficaCompleta
}

export function isStatoOccupazionaleCompleto(globalState) {
  const isStatoOccupazionaleCompleto = globalState.candidatoAttivo.statoOccupazionale.stato

  return !!isStatoOccupazionaleCompleto
}

export function isTitoloDiStudioCompleto(globalState) {
  const isTitoloDiStudioCompleto =
    globalState.candidatoAttivo.titoliStudio && globalState.candidatoAttivo.titoliStudio.length > 0

  return !!isTitoloDiStudioCompleto
}

export function isAltreCompetenzeCompleto(globalState) {
  const isAltreCompetenzeCompleto =
    globalState.candidatoAttivo.altreCompetenze &&
    (globalState.candidatoAttivo.altreCompetenze.altre ||
      globalState.candidatoAttivo.altreCompetenze.organizzative ||
      globalState.candidatoAttivo.altreCompetenze.comunicative)

  return !!isAltreCompetenzeCompleto
}

export function isObiettiviProfessionaliCompleto(globalState) {
  const isObiettiviProfessionaliCompleto =
    globalState.candidatoAttivo.obiettiviProfessionali &&
    globalState.candidatoAttivo.obiettiviProfessionali.posizioniProfessionali &&
    globalState.candidatoAttivo.obiettiviProfessionali.posizioniProfessionali.length > 0

  return !!isObiettiviProfessionaliCompleto
}

export function isDisponibilitaContrattoCompleto(globalState) {
  const op = globalState.candidatoAttivo.obiettiviProfessionali
  const isDisponibilitaContrattoCompleto =
    op &&
    (op.disponibilitaContrattoTempoIndeterminatoFlag ||
      op.disponibilitaContrattoTempoDeterminatoFlag ||
      op.disponibilitaContrattoApprendistatoFlag ||
      op.disponibilitaContrattoSomministrazioneFlag ||
      op.disponibilitaContrattoLavoroOccasionaleFlag ||
      op.disponibilitaContrattoStageTirocinioFlag ||
      op.disponibilitaContrattoVoucherFlag ||
      op.disponibilitaContrattoLavoroAutonomoFlag ||
      op.disponibilitaContrattoStagionaleFlag ||
      op.disponibilitaContrattoLavoroChiamataFlag ||
      op.disponibilitaContrattoCoCoCoFlag)

  return !!isDisponibilitaContrattoCompleto
}

export function isDisponibilitaGeograficaCompleto(globalState) {
  const op = globalState.candidatoAttivo.obiettiviProfessionali
  const isDisponibilitaGeograficaCompleto =
    op &&
    op.disponibilitaGeografica &&
    op.disponibilitaGeografica.lat &&
    op.disponibilitaGeografica.lng &&
    op.disponibilitaGeografica.raggio

  return !!isDisponibilitaGeograficaCompleto
}

export function isEsperienzeLavorativeCompleto(globalState) {
  const isEsperienzeLavorativeCompleto =
    globalState.candidatoAttivo.esperienzeLavorative && globalState.candidatoAttivo.esperienzeLavorative.length > 0

  return !!isEsperienzeLavorativeCompleto
}

export function isStatoCvCompleto(globalState) {
  const isStatoCvCompleto =
    globalState.candidatoAttivo.servizio && globalState.candidatoAttivo.servizio.lavoro.stato === 'cv_completo'

  return !!isStatoCvCompleto
}

export function isCvCompleto(globalState) {
  const isCvCompleto =
    isAnagraficaCompleta(globalState) &&
    isStatoOccupazionaleCompleto(globalState) &&
    isTitoloDiStudioCompleto(globalState) &&
    isAltreCompetenzeCompleto(globalState) &&
    isObiettiviProfessionaliCompleto(globalState) &&
    isDisponibilitaContrattoCompleto(globalState) &&
    isDisponibilitaGeograficaCompleto(globalState) &&
    isStatoCvCompleto(globalState)

  return isCvCompleto
}

export function getNoteDiarioFiltered(globalState) {
  const { noteDiario, filterNoteDiarioEscludiAutomatiche } = globalState.candidatoAttivo

  return noteDiario.filter((notaDiario) => !filterNoteDiarioEscludiAutomatiche || notaDiario.categoria !== 'Automatica')
}
