import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Ink from 'react-ink'
import cogoToast from 'cogo-toast'

import {
  fetchAgenzie,
  deleteAgenzia,
  setKeywordsAgenzie,
  setSportelloCorrenteFlagAgenzie,
  changePageAgenzie,
  addCreditiAgenzia,
  API_ROOT_URL,
} from '../../actions'

import Pager from '../pager/pager'

import { FaSearch } from 'react-icons/fa'
import { MdEdit, MdAdd } from 'react-icons/md'
import swal from 'sweetalert'

class AgenzieList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      agenziaId: 0,
      creditiAgenziaId: '',
      creditiNumero: '',
      creditiCausale: '',
    }
  }

  componentDidMount() {
    this.props.fetchAgenzie(this.props.keywords, this.props.sportelloCorrenteFlag, this.props.pageRequested)
  }

  componentDidUpdate(prevProps) {
    const filterChanged =
      prevProps.keywords !== this.props.keywords || prevProps.sportelloCorrenteFlag !== this.props.sportelloCorrenteFlag

    const pageChanged = prevProps.pageRequested !== this.props.pageRequested

    if (filterChanged) {
      this.props.changePageAgenzie(1)
    }
    if (filterChanged || pageChanged) {
      this.props.fetchAgenzie(
        this.props.keywords,
        this.props.sportelloCorrenteFlag,
        filterChanged ? 1 : this.props.pageRequested
      )
    }
  }

  handleDeleteAgenzia = () => {
    swal({
      icon: 'warning',
      title: 'Eliminazione agenzia',
      text: 'Sei sicuro di voler eliminare questa agenzia? Verranno eliminati anche tutti i relativi operatori e job vacancies. Questa azione è irreversibile.',
      buttons: ['Annulla', 'Si, elimina agenzia'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onRemove()
      }
    })
  }

  handleChangeValue = (field) => (event) => {
    let value = event.target.value
    if (field === 'creditiNumero') {
      if (value > 9999) {
        value = 9999
      }
    }
    this.setState({
      [field]: value,
    })
  }

  handleAddCrediti = (event) => {
    event.preventDefault()
    this.props.addCreditiAgenzia(
      parseInt(this.state.creditiAgenziaId),
      parseInt(this.state.creditiNumero),
      this.state.creditiCausale
    )
    this.setState({
      creditiAgenziaId: '',
      creditiNumero: '',
      creditiCausale: '',
    })

    cogoToast.success('I crediti sono stati aggiunti correttamente.', {
      heading: 'Crediti aggiunti',
      position: 'top-right',
      hideAfter: 5,
    })
  }

  onRemove() {
    this.props.deleteAgenzia(this.state.agenziaId)
  }

  changeKeywords = (event) => {
    this.props.setKeywordsAgenzie(event.target.value)
  }

  changeSportelloCorrenteFlag = (event) => {
    this.props.setSportelloCorrenteFlagAgenzie(event.target.checked)
  }

  renderAgenziePermessoJv(permessoJv, dichiarazioneJv, agenziaId) {
    const token = localStorage.getItem('token')

    if (dichiarazioneJv && permessoJv) {
      return (
        <a
          href={`${API_ROOT_URL}/agenzie/${agenziaId}/download_dichiarazione_jv?t=${token}`}
          target="_blank"
          className="label label-success"
          rel="noreferrer"
        >
          JV
        </a>
      )
    } else if (dichiarazioneJv && !permessoJv) {
      return (
        <a
          href={`${API_ROOT_URL}/agenzie/${agenziaId}/download_dichiarazione_jv?t=${token}`}
          target="_blank"
          className="label label-warning"
          rel="noreferrer"
        >
          JV
        </a>
      )
    } else if (!dichiarazioneJv && permessoJv) {
      return <div className="label label-success">JV</div>
    } else {
      // !dichiarazione && !permessoJv
      return <div className="label label-danger">JV</div>
    }
  }

  renderAgenzie(agenzie, permessoGestioneAgenzie, ruolo, sportelloId) {
    return agenzie.map((agenzia) => (
      <tr key={agenzia.agenziaId}>
        <td>
          {this.renderAgenziePermessoJv(agenzia.permessoJobVacancyFlag, agenzia.dichiarazioneJvFlag, agenzia.agenziaId)}
        </td>
        <td className="text-center">
          {agenzia.crediti ? (
            <span style={{ fontSize: 20, fontWeight: 'bold', color: '#61b815' }}>{agenzia.crediti}</span>
          ) : (
            0
          )}
        </td>
        <td>{agenzia.ragioneSociale}</td>
        <td>{agenzia.comune}</td>
        <td>{agenzia.telefono}</td>
        <td>
          <a href={`mailto:${agenzia.email}`}>{agenzia.email}</a>
        </td>
        <td>
          {agenzia.referenteNome} {agenzia.referenteCognome}
        </td>
        {ruolo === 'operatore' && (
          <td>
            {agenzia.numeroJv > 0 && (
              <Link
                className="btn btn-sm btn-default"
                style={{ marginRight: 10 }}
                to={'/agenzie/' + agenzia.agenziaId + '/job-vacancies'}
              >
                Vedi <b>{agenzia.numeroJv}</b> JV
              </Link>
            )}
          </td>
        )}
        <td className="text-right">
          {((!!permessoGestioneAgenzie && ruolo === 'amministratore') ||
            (!!permessoGestioneAgenzie && agenzia.sportelloId === sportelloId)) && (
            <div className="actions">
              <Link
                className="btn btn-sm btn-primary"
                style={{ marginRight: 10 }}
                to={'/app/agenzie/' + agenzia.agenziaId}
              >
                <MdEdit />
                Modifica
              </Link>
              <button
                className="btn btn-sm btn-danger"
                onClick={() => {
                  this.setState({ agenziaId: agenzia.agenziaId })
                  this.handleDeleteAgenzia()
                }}
              >
                &times; Elimina
              </button>
            </div>
          )}
        </td>
      </tr>
    ))
  }

  render() {
    const {
      keywords,
      permessoGestioneAgenzie,
      ruolo,
      sportelloCorrenteFlag,
      sportelloId,
      changePageAgenzie,
      agenzie,
      pagination,
    } = this.props

    return (
      <div className="agenzie-list">
        <h1 className="page-title">Agenzie</h1>

        {!!permessoGestioneAgenzie && (
          <Link to="/app/agenzie/new" className="btn btn-success pull-right">
            <Ink />
            <MdAdd style={{ verticalAlign: 'middle' }} />
            Nuova agenzia
          </Link>
        )}

        <div
          className="filtro-agenzie search-box form-group"
          style={{ width: 300, display: 'inline-block', marginBottom: 30 }}
        >
          <input
            type="text"
            className="form-control"
            value={keywords}
            onChange={this.changeKeywords}
            placeholder="Filtra per parole chiave..."
          />
          <FaSearch />
        </div>

        {ruolo !== 'amministratore' && (
          <div style={{ display: 'inline-block', marginLeft: 10 }}>
            <div className="checkbox checkbox-primary">
              <input
                type="checkbox"
                id="sportelloCorrente"
                checked={sportelloCorrenteFlag}
                onChange={this.changeSportelloCorrenteFlag}
              />
              <label htmlFor="sportelloCorrente">Solo sportello corrente</label>
            </div>
          </div>
        )}

        <Pager
          position={'top'}
          page={pagination.page}
          totalPages={pagination.totalPages}
          onChangePage={changePageAgenzie}
        />

        <div className="card-box" style={{ marginBottom: 10 }}>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>JV</th>
                  <th>Crediti</th>
                  <th>Ragione sociale</th>
                  <th>Comune</th>
                  <th>Telefono</th>
                  <th>Email</th>
                  <th>Referente</th>
                  {ruolo !== 'amministratore' && <th />}
                  <th />
                </tr>
              </thead>
              <tbody>{this.renderAgenzie(agenzie, permessoGestioneAgenzie, ruolo, sportelloId)}</tbody>
            </table>
          </div>
        </div>

        <Pager
          position={'bottom'}
          page={pagination.page}
          totalPages={pagination.totalPages}
          onChangePage={changePageAgenzie}
        />

        {ruolo === 'amministratore' && (
          <div className="card-box aggiungi-crediti" style={{ marginTop: 30 }}>
            <h1 className="page-title">Aggiungi crediti</h1>
            <form onSubmit={this.handleAddCrediti}>
              <table className="table">
                <thead>
                  <tr>
                    <th>Agenzia</th>
                    <th>Crediti</th>
                    <th>Causale</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <select
                        id="agenziaId"
                        className="form-control"
                        value={this.state.creditiAgenziaId}
                        onChange={this.handleChangeValue('creditiAgenziaId')}
                      >
                        <option value="">-- Seleziona l'agenzia --</option>
                        {this.props.agenzie.map((a) => (
                          <option key={a.agenziaId} value={a.agenziaId}>
                            {a.ragioneSociale}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <input
                        id="crediti"
                        type="number"
                        className="form-control"
                        value={parseInt(this.state.creditiNumero)}
                        max="9999"
                        onChange={this.handleChangeValue('creditiNumero')}
                      />
                    </td>
                    <td>
                      <input
                        id="note"
                        type="text"
                        className="form-control"
                        value={this.state.creditiCausale}
                        onChange={this.handleChangeValue('creditiCausale')}
                      />
                    </td>
                    <td>
                      <button type="sumbit" className="btn btn-primary">
                        Aggiungi crediti
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    agenzie: state.agenzie.data,
    pagination: state.agenzie.pagination,
    pageRequested: state.agenzie.pageRequested,
    keywords: state.agenzie.keywords,
    sportelloCorrenteFlag: state.agenzie.sportelloCorrenteFlag,
    permessoGestioneAgenzie: state.auth.user.permessi.gestioneAgenzie,
    ruolo: state.auth.user.ruolo,
    sportelloId: state.auth.user.sportelloCorrente.sportelloId,
  }
}

export default connect(mapStateToProps, {
  fetchAgenzie,
  deleteAgenzia,
  setKeywordsAgenzie,
  setSportelloCorrenteFlagAgenzie,
  changePageAgenzie,
  addCreditiAgenzia,
})(AgenzieList)
