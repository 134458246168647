import React, { Component } from 'react'
import { connect } from 'react-redux'
import Ink from 'react-ink'
import swal from 'sweetalert'
import { FaExclamationTriangle } from 'react-icons/fa'
import { MdArchive } from 'react-icons/md'

import {
  fetchCandidato,
  deleteCandidato,
  archiveCandidato,
  unarchiveCandidato,
  setVideoCvUrl,
  saveVideoCvUrl,
} from '../../actions'
import { isCvCompleto } from '../../reducers/reducer_candidato_attivo'
import Anagrafica from './anagrafica'
import CompletezzaCv from './completezza_cv'
import ServiziRichiesti from './servizi_richiesti'
import CvSintetico from './cv_sintetico'
import NoteInterne from './note_interne'
import VideoCv from './video_cv'
// import Credenziali from './credenziali';
import StatoOccupazionale from './stato_occupazionale'
import EsperienzeLavorative from './esperienze_lavorative'
import TitoliStudio from './titoli_studio'
import CorsiFormazione from './corsi_formazione'
import ConoscenzeLinguistiche from './conoscenze_linguistiche'
import ConoscenzeInformatiche from './conoscenze_informatiche'
import InteressiCorsi from './interessi_corsi'
import AltreCompetenze from './altre_competenze'
import ObiettiviProfessionali from './obiettivi_professionali'
import AltreInformazioni from './altre_informazioni'
import Allegati from './allegati'
//import DatiUsoInterno from './dati_uso_interno';

class CandidatoShow extends Component {
  componentDidMount() {
    this.props.fetchCandidato(this.props.match.params.id)
  }

  onDeleteClick() {
    this.props.deleteCandidato(this.props.match.params.id).then(() => {
      this.props.history.push('/app/candidati')
    })
  }

  archiveCandidato() {
    this.props.archiveCandidato(this.props.match.params.id).then(() => {
      this.props.history.push('/app/candidati')
    })
  }

  unarchiveCandidato() {
    this.props.unarchiveCandidato(this.props.match.params.id).then(() => {
      this.props.history.push('/app/candidati')
    })
  }

  saveVideoCvUrl = () => {
    this.props.saveVideoCvUrl(this.props.match.params.id, this.props.candidato.videoCvUrl)
  }

  handleAlert = () => {
    swal({
      className: 'swl-text-center',
      icon: 'warning',
      title: 'Eliminazione candidato',
      text: 'Sei sicuro di vole eliminare questo candidato? Questa azione è irreversibile.',
      buttons: ['Annulla', 'Si, elimina candidato'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDeleteClick()
      }
    })
  }
  //////////////////////////////////////////////
  // RENDER
  render() {
    const { candidato, canDelete, canArchive, canChangeServizi, ruolo, cvCompleto } = this.props

    if (!candidato) {
      return <div>Caricamento in corso...</div>
    }
    if (!candidato.anagrafica) {
      return <div>Caricamento in corso...</div>
    }
    return (
      <div className="candidato-show">
        {ruolo === 'operatore' && (
          <div className="alert-candidato">
            {!!candidato.sitoWebFlag && !cvCompleto && (
              <div className="alert alert-warning">
                <b>Attenzione!</b>
                <p>
                  Il candidato è segnato come visibile su sito pubblico, ma il suo CV non è completo e quindi non sarà
                  effettivamente visibile sul sito.
                </p>
              </div>
            )}

            {!!candidato.sitoWebFlag &&
              !!candidato.anagrafica.extracomunitarioFlag &&
              !candidato.anagrafica.extracomunitarioItalianoB1Flag && (
                <div className="alert alert-warning">
                  <b>Attenzione!</b>
                  <p>
                    Il candidato è segnato come visibile su sito pubblico, ma essendo extracomunitario senza una
                    conoscenza della lingua italiana almeno di livello B1, non sarà effettivamente visibile sul sito.
                  </p>
                </div>
              )}
          </div>
        )}

        <a className="anchor" id="anagrafica">
          {' '}
        </a>
        <Anagrafica ruolo={ruolo} canChangeServizi={canChangeServizi} />

        <CompletezzaCv />

        {ruolo === 'operatore' && (
          <ServiziRichiesti candidatoId={this.props.match.params.id} canChangeServizi={canChangeServizi} />
        )}

        <AltreInformazioni />

        {ruolo === 'operatore' && <CvSintetico />}

        {ruolo === 'operatore' && <NoteInterne />}

        <VideoCv
          url={candidato.videoCvUrl}
          onChangeUrl={this.props.setVideoCvUrl}
          onSaveVideoCv={this.saveVideoCvUrl}
          canChangeServizi={canChangeServizi}
        />

        <a className="anchor" id="stato-occupazionale">
          {' '}
        </a>
        <StatoOccupazionale />

        <a className="anchor" id="esperienze-lavorative">
          {' '}
        </a>
        <EsperienzeLavorative />

        <a className="anchor" id="titoli-di-studio">
          {' '}
        </a>
        <TitoliStudio />

        <CorsiFormazione />

        <ConoscenzeLinguistiche />

        <ConoscenzeInformatiche />

        <InteressiCorsi />

        <a className="anchor" id="altre-competenze">
          {' '}
        </a>
        <AltreCompetenze />

        <a className="anchor" id="obiettivi-professionali">
          {' '}
        </a>
        <ObiettiviProfessionali />

        <Allegati />

        {/* {this.props.ruolo === 'operatore' ?
					<DatiUsoInterno />
				: null} */}

        <div className="text-center">
          {canDelete ? (
            <button onClick={() => this.handleAlert()} className="btn btn-danger">
              <Ink />
              <FaExclamationTriangle />
              Elimina candidato
            </button>
          ) : null}
          &nbsp;&nbsp;
          {canArchive && !candidato.archiviatoFlag ? (
            <button onClick={() => this.archiveCandidato()} className="btn btn-warning">
              <Ink />
              <MdArchive />
              Archivia
            </button>
          ) : null}
          {canArchive && candidato.archiviatoFlag ? (
            <button onClick={() => this.unarchiveCandidato()} className="btn btn-success">
              <Ink />
              <MdArchive />
              Disarchivia
            </button>
          ) : null}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    candidato: state.candidatoAttivo,
    ruolo: state.auth.user.ruolo,
    cvCompleto: isCvCompleto(state),
    canDelete:
      !!state.auth.user.permessi['eliminaCandidato'] &&
      state.candidatoAttivo.sportello &&
      state.candidatoAttivo.sportello.sportelloId === state.auth.user.sportelloCorrente.sportelloId,
    canArchive:
      !!state.auth.user.permessi['archiviaCandidato'] &&
      state.candidatoAttivo.sportello &&
      state.candidatoAttivo.sportello.sportelloId === state.auth.user.sportelloCorrente.sportelloId,
    canChangeServizi:
      !!state.auth.user.permessi['anagrafica'] &&
      state.candidatoAttivo.sportello &&
      state.candidatoAttivo.sportello.sportelloId === state.auth.user.sportelloCorrente.sportelloId,
  }
}

export default connect(mapStateToProps, {
  fetchCandidato,
  deleteCandidato,
  archiveCandidato,
  unarchiveCandidato,
  setVideoCvUrl,
  saveVideoCvUrl,
  isCvCompleto,
})(CandidatoShow)
