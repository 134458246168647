import axios from 'axios'
import cogoToast from 'cogo-toast'
import moment from 'moment'
import { FaSortAmountUpAlt } from 'react-icons/fa'
import {
  ADD_APPUNTAMENTO,
  ADD_CANDIDATI_GRUPPO,
  ADD_CANDIDATO_GRUPPO,
  ADD_CONOSCENZA_INFORMATICA,
  ADD_CONOSCENZA_LINGUISTICA,
  ADD_CORSO_FORMAZIONE,
  ADD_CREDITI_AGENZIA,
  ADD_ESPERIENZA_LAVORATIVA,
  ADD_GRUPPO_CANDIDATO,
  ADD_OBIETTIVO_PROFESSIONALE,
  ADD_PARAGRAFO_NEWSLETTER,
  ADD_SPORTELLO_NEWSLETTER,
  ADD_SPORTELLO_OPERATORE,
  ADD_TITOLO_STUDIO,
  ANNULLA_SPOSTAMENTO,
  ARCHIVE_CANDIDATO,
  AUTH_ERROR,
  AUTH_USER,
  CHANGE_FILTER_CANDIDATI,
  CHANGE_PAGE_AGENZIE,
  CHANGE_PAGE_AZIENDE,
  CHANGE_PAGE_CANDIDATI,
  CHANGE_PAGE_JOB_VACANCIES,
  CHANGE_PAGE_NEWSLETTERS,
  CHANGE_PAGE_NOTIFICHE,
  CHANGE_PASSWORD,
  CLEAR_NEW_POSIZIONE_PROFESSIONALE,
  CLEAR_NOTIFICATION,
  CONFERMA_SPOSTAMENTO,
  CREATE_AGENZIA,
  CREATE_AREA_GEOGRAFICA,
  CREATE_AZIENDA,
  CREATE_CANDIDATO,
  CREATE_CANDIDATURA,
  CREATE_GRUPPO,
  CREATE_INTERESSE_PER_CANDIDATO,
  CREATE_JOB_VACANCY,
  CREATE_NEWSLETTER,
  CREATE_NOTA_DIARIO_CANDIDATO,
  CREATE_OPERATORE,
  CREATE_OPERATORE_AZAG,
  CREATE_SLOT,
  CREATE_SPORTELLO,
  CREATE_SUBAMMINISTRATORE,
  DELETE_AGENZIA,
  DELETE_AREA_GEOGRAFICA,
  DELETE_AZIENDA,
  DELETE_CANDIDATO,
  DELETE_CANDIDATURA,
  DELETE_GRUPPO,
  DELETE_INTERESSE_PER_CANDIDATO,
  DELETE_JOB_VACANCY,
  DELETE_NEWSLETTER,
  DELETE_NOTA_DIARIO_CANDIDATO,
  DELETE_OPERATORE,
  DELETE_OPERATORE_AZAG,
  DELETE_SLOT,
  DELETE_SPORTELLO,
  DELETE_SUBAMMINISTRATORE,
  DUPLICATE_ESPERIENZA_LAVORATIVA,
  DUPLICATE_NEWSLETTER,
  FETCH_ACQUISIZIONE_CANDIDATI_SPORTELLO,
  FETCH_AGENZIA,
  FETCH_AGENZIE,
  FETCH_AREA_GEOGRAFICA,
  FETCH_AREE_GEOGRAFICHE,
  FETCH_AZIENDA,
  FETCH_AZIENDE,
  FETCH_CANDIDATI,
  FETCH_CANDIDATO,
  FETCH_CREDITI_SMS,
  FETCH_CREDITI_SMS_CONSUMATI,
  FETCH_GRUPPI,
  FETCH_GRUPPI_CANDIDATO,
  FETCH_GRUPPO,
  FETCH_JOB_VACANCIES,
  FETCH_JOB_VACANCIES_AGENZIA,
  FETCH_JOB_VACANCIES_AZIENDA,
  FETCH_JOB_VACANCY,
  FETCH_LOOKUPS,
  FETCH_MOVIMENTI_CREDITI_AGENZIE,
  FETCH_NEWSLETTER,
  FETCH_NEWSLETTERS,
  FETCH_NOTIFICHE,
  FETCH_NOTIFICHE_NON_LETTE,
  FETCH_NUMERO_DESTINATARI,
  FETCH_OPERATORE,
  FETCH_OPERATORI,
  FETCH_OPERATORI_AZAG,
  FETCH_PROVINCE,
  FETCH_SLOT,
  FETCH_SLOTS,
  FETCH_SPORTELLI,
  FETCH_SPORTELLI_PER_AREA,
  FETCH_SPORTELLO,
  FETCH_SUBAMMINISTRATORE,
  FETCH_SUBAMMINISTRATORI,
  FETCH_TYPEAHEAD_CANDIDATI,
  FETCH_TYPEAHEAD_COMUNI,
  FETCH_TYPEAHEAD_COMUNI_STATI_ESTERI,
  FETCH_TYPEAHEAD_POSIZIONI_PROFESSIONALI,
  FETCH_USER,
  GET_STATISTICHE_CANDIDATO,
  SET_FILTRO_PANNELLO_STATISTICHE,
  IMPERSONA,
  INVIA_EMAIL_CV_GRUPPO,
  INVIA_EMAIL_SEGNALAZIONE,
  INVIO_FORM_SPORTELLO,
  LOGIN_START,
  NOTIFICATION,
  PASSWORD_CHANGED,
  REGISTRAZIONE_UTENTE,
  REMOVE_ALLEGATO,
  REMOVE_APPUNTAMENTO,
  REMOVE_CANDIDATO_GRUPPO,
  REMOVE_CONOSCENZA_INFORMATICA,
  REMOVE_CONOSCENZA_LINGUISTICA,
  REMOVE_CORSO_FORMAZIONE,
  REMOVE_ESPERIENZA_LAVORATIVA,
  REMOVE_OBIETTIVO_PROFESSIONALE,
  REMOVE_PARAGRAFO_NEWSLETTER,
  REMOVE_SPORTELLO_NEWSLETTER,
  REMOVE_SPORTELLO_OPERATORE,
  REMOVE_TITOLO_STUDIO,
  RESET_AREA_GEOGRAFICA_ATTIVA,
  RESET_CANDIDATO,
  RESET_NEWSLETTER,
  RESET_OPERATORE_ATTIVO,
  RESET_PASSWORD,
  RESET_SPORTELLO_ATTIVO,
  RESET_STATO_CONTATTO_APP,
  RESET_STATO_INVIO_FORM_SPORTELLO,
  RESET_STATO_INVIO_MESSAGGIO,
  RESET_STATO_REGISTRAZIONE,
  RESET_SUBAMMINISTRATORE_ATTIVO,
  REVOCA_CONSENSO_NEWSLETTER,
  SAVE_AGENZIA,
  SAVE_ALTRE_COMPETENZE,
  SAVE_ALTRE_INFORMAZIONI,
  SAVE_ANAGRAFICA,
  SAVE_AREA_GEOGRAFICA,
  SAVE_AZIENDA,
  SAVE_CONOSCENZE_INFORMATICHE,
  SAVE_DESCRIZIONE_CV_SINTETICO,
  SAVE_DISPONIBILITA_GEOGRAFICA,
  SAVE_GRUPPO,
  SAVE_INTERESSI_CORSI,
  SAVE_JOB_VACANCY,
  SAVE_NEWSLETTER,
  SAVE_NOTE_AGENZIA,
  SAVE_NOTE_AGENZIA_DA_OPERATORE,
  SAVE_NOTE_INTERNE,
  SAVE_NUOVO_SPORTELLO,
  SAVE_OBIETTIVI_PROFESSIONALI,
  SAVE_OBIETTIVO_PROFESSIONALE,
  SAVE_OPERATORE,
  SAVE_OPERATORE_AZAG,
  SAVE_PRIVACY,
  SAVE_SERVIZIO,
  SAVE_SITO_WEB_FLAG,
  SAVE_SLOT,
  SAVE_SPORTELLO,
  SAVE_STAMPE,
  SAVE_STATO_OCCUPAZIONALE,
  SAVE_SUBAMMINISTRATORE,
  SAVE_VIDEOCV_URL,
  SBLOCCA_CANDIDATO,
  SEND_CONTATTO_APP,
  SEND_EMAIL,
  SEND_NEWSLETTER,
  SEND_SMS,
  SET_CENTRO_DISPONIBILITA_GEOGRAFICA,
  SET_EDIT_OPERATORE_AZAG,
  SET_FILTER_CREDITI_SMS_CONSUMATI,
  SET_FILTER_NOTE_DIARIO_ESCLUDI_AUTOMATICHE,
  SET_FILTER_PANNELLO_EXPORT_CSV,
  SET_KEYWORDS_AGENZIE,
  SET_KEYWORDS_AZIENDE,
  SET_KEYWORDS_JOB_VACANCIES,
  SET_MOSTRA_JV_APERTE,
  SET_NEW_POSIZIONE_PROFESSIONALE,
  SET_NOTE_AGENZIA,
  SET_NOTE_SERVIZIO,
  SET_NOTIFICA_LETTA,
  SET_PROVINCIA_JOB_VACANCIES,
  SET_RAGGIO_DISPONIBILITA_GEOGRAFICA,
  SET_SOLO_PROPRIETARIO_FLAG_JV,
  SET_SPORTELLO_CORRENTE_FLAG_AGENZIE,
  SET_SPORTELLO_CORRENTE_FLAG_AZIENDE,
  SET_STATO_SERVIZIO,
  SET_VIDEOCV_URL,
  SET_VIEW_MODE_CANDIDATI,
  TOGGLE_MOBILE_MENU_OPEN,
  TOGGLE_TIPO_RICERCA_CANDIDATI,
  UNARCHIVE_CANDIDATO,
  UNAUTH_USER,
  UPDATE_CONOSCENZA_INFORMATICA,
  UPDATE_CONOSCENZA_LINGUISTICA,
  UPDATE_CORSO_FORMAZIONE,
  UPDATE_ESPERIENZA_LAVORATIVA,
  UPDATE_NEWSLETTER,
  UPDATE_PARAGRAFO_NEWSLETTER,
  UPLOAD_ALLEGATO_NEWSLETTER,
  REMOVE_ALLEGATO_NEWSLETTER,
  UPDATE_TITOLO_STUDIO,
  UPLOAD_ALLEGATI,
  VERIFICA_CODICE_FISCALE_CISL,
  VERIFY_TOKEN,
  GET_STATS,
  CAMBIA_PAGINA_CORSI_FORMAZIONE,
  SET_KEYWORDS_CORSI_FORMAZIONE,
  FETCH_CORSI_FORMAZIONE,
  SAVE_CORSO_FORMAZIONE,
  CREATE_CORSO_FORMAZIONE,
  DELETE_CORSO_FORMAZIONE,
  FETCH_CORSO_ATTIVO,
  SUBSCRIBE_CORSO,
  FETCH_CORSI_FORMAZIONE_CANDIDATO,
  RESET_CORSO_ATTIVO,
  SWITCH_ESPERIENZA_LAVORATIVA_IN_CV,
} from './types'

// export const API_ROOT_URL = 'http://192.168.1.101:8082/v1'
// export const API_ROOT_URL = 'http://79.58.24.215:8082/v1';
//export const API_ROOT_URL = 'http://api-staging-sportellolavoro.f2.net/v1';
// export const API_ROOT_URL = 'http://api-staging.sportellolavorocisl.it/v1';
// export const API_ROOT_URL = 'https://api.staging.sportellolavorocisl.it/v1';
export const API_ROOT_URL = 'https://api.sportellolavorocisl.it/v1';

//////////////////////////////////////////////
// NOTIFY
export function notify(title, message, level = 'info', position = 'tr', autoDismiss = 6) {
  return {
    type: NOTIFICATION,
    payload: { title, message, level, position, autoDismiss },
  }
}

export function notifyError(title, message) {
  return notify(title, message, 'error', 'tr', 10)
}

export function notifyWarning(title, message) {
  return notify(title, message, 'warning', 'tr', 5)
}

export function notifyOk(title, message) {
  return notify(title, message, 'success', 'tr', 5)
}

export function clearNotification() {
  return {
    type: CLEAR_NOTIFICATION,
  }
}

//////////////////////////////////////////////
// TOGGLE MOBILE MENU OPEN
export function toggleMobileMenuOpen() {
  return {
    type: TOGGLE_MOBILE_MENU_OPEN,
  }
}

//////////////////////////////////////////////
// LOGIN --> THUNK

export function loginUser({ username, password, browserHistory }) {
  return function (dispatch) {
    dispatch({
      type: LOGIN_START,
    })
    axios
      .post(`${API_ROOT_URL}/session`, { login: username, password: password })
      .then(response => {
        dispatch({
          type: AUTH_USER,
          payload: response.data,
        })
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('user', JSON.stringify(response.data))
        browserHistory.push('/app')
      })

      .catch(e => {
        dispatch(authError('Username o password errati'))
      })
  }
}

export function authError(error) {
  return {
    type: AUTH_ERROR,
    payload: error,
  }
}

//////////////////////////////////////////////
// LOGOUT --> THUNK

export function logoutUser(browserHistory) {
  localStorage.removeItem('token')
  localStorage.removeItem('user')
  browserHistory.push('/')
  return { type: UNAUTH_USER }
}

//////////////////////////////////////////////
// FETCH USER --> THUNK

export function fetchUser(browserHistory) {
  return function (dispatch) {
    axios
      .get(`${API_ROOT_URL}/session`)

      .then(response => {
        dispatch({
          type: FETCH_USER,
          payload: response.data,
        })
        // browserHistory.push('/app')
      })

      .catch(() => {
        dispatch(logoutUser(browserHistory))
        browserHistory.push('/')
      })
  }
}

//////////////////////////////////////////////
// CAMBIA PASSWORD --> THUNK
export function cambiaPassword(vecchiaPassword, nuovaPassword, browserHistory) {
  return function (dispatch) {
    axios
      .put(`${API_ROOT_URL}/session`, { vecchiaPassword, nuovaPassword })
      .then(response => {
        // Dispatch PASSWORD_CHANGED
        dispatch({
          type: PASSWORD_CHANGED,
          payload: response.data,
        })
        // Nuovo token con nuovo ruolo
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('user', JSON.stringify(response.data))
        // Notifica OK
        dispatch(notifyOk('Password modificata', 'La password è stata modificata correttamente'))
        // Redirect a Home
        browserHistory.push('/app')
      })
      .catch(error => {
        dispatch(
          notifyError(
            'Errore durante la modifica',
            "E' avvenuto un errore durante la modifica della password"
          )
        )
      })
  }
}

//////////////////////////////////////////////
// RESET PASSWORD --> THUNK
export function resetPassword(username, localState) {
  return function (dispatch) {
    axios
      .post(`${API_ROOT_URL}/session/forgot_password`, { login: username })
      .then(response => {
        dispatch({
          type: RESET_PASSWORD,
          payload: response.data,
        })
        localState('success')
      })
      .catch(() => {
        cogoToast.error('Attenzione: questo username non è presente nel database', {
          heading: 'Username non trovato',
          position: 'top-right',
          hideAfter: 10,
        })
        localState('error')
      })
  }
}

export function changePasswordFromReset(token, password, browserHistory) {
  return function (dispatch) {
    axios.post(`${API_ROOT_URL}/session/change_password`, { token, password }).then(response => {
      dispatch({
        type: CHANGE_PASSWORD,
        payload: response.data,
      })
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('user', JSON.stringify(response.data))
      browserHistory.push('/app')
    })
  }
}

export function verificaToken(token) {
  return function (dispatch) {
    axios
      .get(`${API_ROOT_URL}/session/change_password/${token}`)
      .then(response =>
        dispatch({
          type: VERIFY_TOKEN,
          payload: response.data,
        })
      )
      .catch(error =>
        cogoToast.error('Il link non è valido, riprovare reset password', { hideAfter: 10 })
      )
  }
}

//////////////////////////////////////////////
// CAMBIO SPORTELLO OPERATORE --> THUNK
export function cambioSportelloOperatore(sportelloId) {
  return function (dispatch) {
    axios.put(`${API_ROOT_URL}/session`, { sportelloId }).then(response => {
      dispatch({
        type: FETCH_USER,
        payload: response.data,
      })
      // Nuovo token con nuovo sportello
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('user', JSON.stringify(response.data))

      window.location.replace(`/app`)
    })
  }
}

//////////////////////////////////////////////
// IMPERSONA --> THUNK

export function impersona(candidatoId) {
  return function (dispatch) {
    axios.post(`${API_ROOT_URL}/candidati/${candidatoId}/impersona`).then(response => {
      dispatch({
        type: IMPERSONA,
        payload: response.data,
      })
      // Salvo il token dell'utente e "ricarico"
      localStorage.clear()
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('user', JSON.stringify(response.data))
      window.location = '/app'
    })
  }
}

//////////////////////////////////////////////
// LOOKUPS --> PROMISE

export function fetchLookups() {
  const request = axios.get(`${API_ROOT_URL}/lookups`)

  return {
    type: FETCH_LOOKUPS,
    payload: request,
  }
}

//////////////////////////////////////////////
// TYPEAHEADs --> PROMISE

export function fetchTypeaheadComuni(input) {
  const request = axios.get(`${API_ROOT_URL}/comuni?q=${input}&page_size=5`)

  return {
    type: FETCH_TYPEAHEAD_COMUNI,
    payload: request,
  }
}

export function fetchTypeaheadComuniConStatiEsteri(input) {
  const request = axios.get(`${API_ROOT_URL}/comuni?q=${input}&estero=true&page_size=5`)

  return {
    type: FETCH_TYPEAHEAD_COMUNI_STATI_ESTERI,
    payload: request,
  }
}

export function fetchTypeaheadPosizioniProfessionali(input) {
  const request = axios.get(`${API_ROOT_URL}/posizioni_professionali?q=${input}&page_size=5`)
  return {
    type: FETCH_TYPEAHEAD_POSIZIONI_PROFESSIONALI,
    payload: request,
  }
}

export function fetchTypeaheadCandidati(input) {
  const request = axios.get(
    `${API_ROOT_URL}/candidati?contiene=${input}&mostra_dati_minimi=true&page_size=5`
  )

  return {
    type: FETCH_TYPEAHEAD_CANDIDATI,
    payload: request,
  }
}

//////////////////////////////////////////////
// VERIFICA CF CISL --> PROMISE

export function verificaCodiceFiscaleCisl(codiceFiscale) {
  const request = axios.get(`${API_ROOT_URL}/dati_cisl/${codiceFiscale}`)

  return {
    type: VERIFICA_CODICE_FISCALE_CISL,
    payload: request,
  }
}

//////////////////////////////////////////////
// CANDIDATI LIST --> PROMISE

export function fetchCandidati({
  organizzazione,
  tipoCandidati,
  keywords,
  comuneDisponibilita,
  servizioRichiesto,
  statoServizio,
  comuneDomicilio,
  rangeEta,
  sesso,
  lingue,
  obiettiviProfessionali,
  esperienzeLavorative,
  formazione,
  interesseCorsi,
  invalidita,
  automunito,
  livelloPatente,
  disponibilitaTempo,
  page = 1,
  pageSize = 20,
}) {
  let queryStringOrganizzazione = ''
  let queryStringTipoCandidati = ''
  const queryStringPagination = `page=${page}&page_size=${pageSize}`
  let queryStringComuneDisponibilita = ''
  let queryStringKeywords = ''
  let queryStringServizioRichiesto = ''
  let queryStringStatoServizio = ''
  let queryStringComuneDomicilio = ''
  let queryStringEtaDa = ''
  let queryStringEtaA = ''
  let queryStringSesso = ''
  let queryStringLingue = ''
  let queryStringObiettiviProfessionali = ''
  let queryStringEsperienzeLavorative = ''
  let queryStringFormazione = ''
  let queryStringInteresseCorsi = ''
  let queryStringInvalidita = ''
  let queryStringAutomunito = ''
  let queryStringLivelloPatente = ''
  let queryStringDisponibilitaTempo = ''

  switch (tipoCandidati) {
    case 'sportello': {
      queryStringTipoCandidati = '&livello=sportello&archiviato=false'
      break
    }
    case 'regione': {
      queryStringTipoCandidati = '&livello=regione&archiviato=false'
      break
    }
    case 'tutta la rete': {
      queryStringTipoCandidati = '&livello=tutta+la+rete&archiviato=false'
      break
    }
    case 'archiviati': {
      queryStringTipoCandidati = '&livello=sportello&archiviato=true'
      break
    }
    default:
      break
  }

  if (organizzazione) {
    queryStringOrganizzazione = '&organizzazione=' + organizzazione
  }
  if (comuneDisponibilita && comuneDisponibilita.codice) {
    queryStringComuneDisponibilita = '&codice_catastale_comune=' + comuneDisponibilita.codice
  }
  if (keywords && keywords.length > 1) {
    queryStringKeywords = '&keywords=' + keywords
  }
  if (servizioRichiesto && servizioRichiesto.length > 1) {
    queryStringServizioRichiesto = '&servizio_richiesto=' + servizioRichiesto
  }
  if (statoServizio && statoServizio.length > 1) {
    queryStringStatoServizio = '&stato_servizio=' + statoServizio
  }
  if (comuneDomicilio && comuneDomicilio.codice) {
    queryStringComuneDomicilio = '&codice_catastale_comune_domicilio=' + comuneDomicilio.codice
  }
  if (rangeEta && rangeEta.length >= 5 && rangeEta.indexOf('-') > -1) {
    const etas = rangeEta.split('-')
    queryStringEtaDa = `&eta_da=${etas[0]}`
    queryStringEtaA = `&eta_a=${etas[1]}`
  }
  if (sesso && sesso.length === 1) {
    queryStringSesso = '&sesso=' + sesso.toLowerCase()
  }
  if (lingue && lingue.length > 1) {
    queryStringLingue = '&conoscenze_linguistiche=' + lingue
  }

  if (obiettiviProfessionali && obiettiviProfessionali.length > 1) {
    queryStringObiettiviProfessionali = '&obiettivi_professionali=' + obiettiviProfessionali
  }

  if (esperienzeLavorative && esperienzeLavorative.length > 1) {
    queryStringEsperienzeLavorative = '&esperienze_lavorative=' + esperienzeLavorative
  }

  if (formazione && formazione.length > 1) {
    queryStringFormazione = '&formazione=' + formazione
  }

  if (interesseCorsi && interesseCorsi.length > 1) {
    queryStringInteresseCorsi = '&interesse_corsi=' + interesseCorsi
  }
  if (invalidita && invalidita.length > 1) {
    queryStringInvalidita = '&invalidita=' + invalidita
  }
  if (automunito && automunito.length > 1) {
    queryStringAutomunito = '&automunito=' + automunito
  }
  if (livelloPatente && livelloPatente.length > 1) {
    queryStringLivelloPatente = '&livello_patente=' + livelloPatente
  }
  if (disponibilitaTempo && disponibilitaTempo.length > 1) {
    queryStringDisponibilitaTempo = '&disponibilita_tempo=' + disponibilitaTempo
  }

  const queryString =
    queryStringPagination +
    queryStringTipoCandidati +
    queryStringOrganizzazione +
    queryStringKeywords +
    queryStringComuneDisponibilita +
    queryStringServizioRichiesto +
    queryStringStatoServizio +
    queryStringComuneDomicilio +
    queryStringEtaDa +
    queryStringEtaA +
    queryStringSesso +
    queryStringLingue +
    queryStringObiettiviProfessionali +
    queryStringEsperienzeLavorative +
    queryStringFormazione +
    queryStringInteresseCorsi +
    queryStringInvalidita +
    queryStringAutomunito +
    queryStringLivelloPatente +
    queryStringDisponibilitaTempo

  const request = axios.get(`${API_ROOT_URL}/candidati?${queryString}`)

  return {
    type: FETCH_CANDIDATI,
    payload: request,
  }
}

export function changePageCandidati(page) {
  return {
    type: CHANGE_PAGE_CANDIDATI,
    payload: page,
  }
}

export function setViewModeCandidati(viewMode) {
  return {
    type: SET_VIEW_MODE_CANDIDATI,
    payload: viewMode,
  }
}

export function changeFilterCandidati(field, value) {
  return {
    type: CHANGE_FILTER_CANDIDATI,
    payload: { field, value },
  }
}

export function toggleTipoRicercaCandidati(tipoRicerca) {
  return {
    type: TOGGLE_TIPO_RICERCA_CANDIDATI,
    payload: tipoRicerca,
  }
}

//////////////////////////////////////////////
// CANDIDATO: FETCH, CREATE, DELETE --> PROMISE

export function fetchCandidato(id) {
  const request = axios.get(`${API_ROOT_URL}/candidati/${id}`)
  return {
    type: FETCH_CANDIDATO,
    payload: request,
  }
}

export function resetCandidato() {
  return {
    type: RESET_CANDIDATO,
    payload: null,
  }
}

export function createCandidato(props) {
  const request = axios.post(`${API_ROOT_URL}/candidati`, props)

  return {
    type: CREATE_CANDIDATO,
    payload: request,
  }
}

export function deleteCandidato(id) {
  const request = axios.delete(`${API_ROOT_URL}/candidati/${id}`)

  return {
    type: DELETE_CANDIDATO,
    payload: request,
  }
}

export function archiveCandidato(id) {
  const request = axios.put(`${API_ROOT_URL}/candidati/${id}`, { archiviatoFlag: true })

  return {
    type: ARCHIVE_CANDIDATO,
    payload: request,
  }
}

export function unarchiveCandidato(id) {
  const request = axios.put(`${API_ROOT_URL}/candidati/${id}`, { archiviatoFlag: false })

  return {
    type: UNARCHIVE_CANDIDATO,
    payload: request,
  }
}

export function setFiltroPannelloStatistiche(field, value) {
  return {
    type: SET_FILTRO_PANNELLO_STATISTICHE,
    payload: { field, value },
  }
}

export function getStatisticheCandidatiBySportello({ sportelloId, dataDa, dataA }) {
  return function (dispatch) {
    axios
      .get(`${API_ROOT_URL}/sportelli/${sportelloId}/stats?dataDa=${dataDa}&dataA=${dataA}`)
      .then(response =>
        dispatch({
          type: GET_STATISTICHE_CANDIDATO,
          payload: response.data,
        })
      )
  }
}
export function getStatisticheCandidati({ dataDa, dataA, areaGeograficaId }) {
  let query = `${API_ROOT_URL}/stats?dataDa=${dataDa}&dataA=${dataA}`
  if (areaGeograficaId) {
    query = query + `&areaGeograficaId=${areaGeograficaId}`
  }
  return function (dispatch) {
    axios.get(query).then(response =>
      dispatch({
        type: GET_STATS,
        payload: response.data,
      })
    )
  }
}

//////////////////////////////////////////////
// INVIO CREDENZIALI VIA EMAIL / SMS ==> THUNK

export function sendCredenzialiEmail(candidatoId) {
  return function (dispatch) {
    axios.get(`${API_ROOT_URL}/candidati/${candidatoId}/invio_credenziali_email`).then(response => {
      dispatch(notifyOk('Invio credenziali', "L'e-mail con le credenziali è stata inviata."))
    })
  }
}

export function sendCredenzialiSms(candidatoId) {
  return function (dispatch) {
    axios.get(`${API_ROOT_URL}/candidati/${candidatoId}/invio_credenziali_sms`).then(response => {
      dispatch(notifyOk('Invio credenziali', "L'SMS con le credenziali è stato inviato."))
    })
  }
}

//////////////////////////////////////////////
// CANDIDATO: EDIT PARTS --> PROMISE

export function saveAnagrafica(candidatoId, values) {
  const anagrafica = { anagrafica: values }
  const request = axios.put(`${API_ROOT_URL}/candidati/${candidatoId}`, anagrafica)

  return {
    type: SAVE_ANAGRAFICA,
    payload: request,
  }
}

export function saveSitoWebFlag(candidatoId, sitoWebFlag) {
  const request = axios.put(`${API_ROOT_URL}/candidati/${candidatoId}`, { sitoWebFlag })

  return {
    type: SAVE_SITO_WEB_FLAG,
    payload: request,
  }
}

export function saveStampe(candidatoId, flagObject) {
  const stampe = { stampe: flagObject }
  const request = axios.put(`${API_ROOT_URL}/candidati/${candidatoId}`, stampe)

  return {
    type: SAVE_STAMPE,
    payload: request,
  }
}

export function setNoteAgenzia(noteAgenzia) {
  return {
    type: SET_NOTE_AGENZIA,
    payload: noteAgenzia,
  }
}

export function saveNoteAgenzia(candidatoId, noteAgenzia) {
  const request = axios.put(`${API_ROOT_URL}/candidati/${candidatoId}`, { noteAgenzia })

  return {
    type: SAVE_NOTE_AGENZIA,
    payload: request,
  }
}

export function saveNoteAgenziaDaOperatore(movimentoCreditiAgenziaId, note) {
  const request = axios.put(
    `${API_ROOT_URL}/movimenti_crediti_agenzie/${movimentoCreditiAgenziaId}`,
    { note }
  )

  return {
    type: SAVE_NOTE_AGENZIA_DA_OPERATORE,
    payload: request,
  }
}

export function setStatoServizio(servizio, stato) {
  return {
    type: SET_STATO_SERVIZIO,
    payload: { servizio, stato },
  }
}

export function setNoteServizio(servizio, note) {
  return {
    type: SET_NOTE_SERVIZIO,
    payload: { servizio, note },
  }
}

export function saveServizio(id, tipoServizio, stato, note) {
  const update = {
    servizio: {
      [tipoServizio]: { stato, note },
    },
  }
  const request = axios.put(`${API_ROOT_URL}/candidati/${id}`, update)

  return {
    type: SAVE_SERVIZIO,
    payload: request,
  }
}

export function setVideoCvUrl(url) {
  return {
    type: SET_VIDEOCV_URL,
    payload: url,
  }
}

export function saveVideoCvUrl(id, videoCvUrl) {
  const request = axios.put(`${API_ROOT_URL}/candidati/${id}`, { videoCvUrl })

  return {
    type: SAVE_VIDEOCV_URL,
    payload: request,
  }
}

export function saveNuovoSportello(id, sportelloId) {
  const nuovoSportello = { sportelloId }
  const request = axios.put(`${API_ROOT_URL}/candidati/${id}`, nuovoSportello)

  return {
    type: SAVE_NUOVO_SPORTELLO,
    payload: request,
  }
}

export function confermaSpostamento(candidatoId) {
  const request = axios.post(`${API_ROOT_URL}/candidati/${candidatoId}/conferma_spostamento`)

  return {
    type: CONFERMA_SPOSTAMENTO,
    payload: request,
  }
}

export function annullaSpostamento(candidatoId) {
  const request = axios.post(`${API_ROOT_URL}/candidati/${candidatoId}/annulla_spostamento`)

  return {
    type: ANNULLA_SPOSTAMENTO,
    payload: request,
  }
}

export function saveStatoOccupazionale(id, values) {
  const statoOccupazionale = { statoOccupazionale: values }
  const request = axios.put(`${API_ROOT_URL}/candidati/${id}`, statoOccupazionale)

  return {
    type: SAVE_STATO_OCCUPAZIONALE,
    payload: request,
  }
}

export function saveConoscenzeInformatiche(id, values) {
  const conoscenzeInformatiche = { conoscenzeInformatiche: values }
  const request = axios.put(`${API_ROOT_URL}/candidati/${id}`, conoscenzeInformatiche)

  return {
    type: SAVE_CONOSCENZE_INFORMATICHE,
    payload: request,
  }
}

export function saveInteressiCorsi(id, values) {
  const interessiCorsi = { interessiCorsi: values }
  const request = axios.put(`${API_ROOT_URL}/candidati/${id}`, interessiCorsi)

  return {
    type: SAVE_INTERESSI_CORSI,
    payload: request,
  }
}

export function saveAltreCompetenze(id, values) {
  const altreCompetenze = { altreCompetenze: values }
  const request = axios.put(`${API_ROOT_URL}/candidati/${id}`, altreCompetenze)

  return {
    type: SAVE_ALTRE_COMPETENZE,
    payload: request,
  }
}

export function saveObiettiviProfessionali(id, values) {
  const obiettiviProfessionali = { obiettiviProfessionali: values }
  const request = axios.put(`${API_ROOT_URL}/candidati/${id}`, obiettiviProfessionali)

  return {
    type: SAVE_OBIETTIVI_PROFESSIONALI,
    payload: request,
  }
}

export function setCentroDisponibilitaGeografica(lat, lng) {
  const centro = { lat, lng }
  return {
    type: SET_CENTRO_DISPONIBILITA_GEOGRAFICA,
    payload: centro,
  }
}

export function setRaggioDisponibilitaGeografica(raggio) {
  return {
    type: SET_RAGGIO_DISPONIBILITA_GEOGRAFICA,
    payload: raggio,
  }
}

export function saveDisponibilitaGeografica(candidatoId, lat, lng, raggio) {
  const disponibilitaGeografica = {
    obiettiviProfessionali: { disponibilitaGeografica: { lat, lng, raggio } },
  }
  const request = axios.put(`${API_ROOT_URL}/candidati/${candidatoId}`, disponibilitaGeografica)

  return {
    type: SAVE_DISPONIBILITA_GEOGRAFICA,
    payload: request,
  }
}

export function saveAltreInformazioni(id, values) {
  const altreInformazioni = { altreInformazioni: values }
  const request = axios.put(`${API_ROOT_URL}/candidati/${id}`, altreInformazioni)

  return {
    type: SAVE_ALTRE_INFORMAZIONI,
    payload: request,
  }
}

// export function saveUsoInterno(id, values) {
// 	const usoInterno = { usoInterno: values };
// 	const request = axios.put(`${API_ROOT_URL}/candidati/${id}`, usoInterno);

// 	return {
// 		type: SAVE_USO_INTERNO,
// 		payload: request
// 	}
// }

export function saveDescrizioneCvSintetico(id, descrizioneCvSintetico) {
  const request = axios.put(`${API_ROOT_URL}/candidati/${id}`, descrizioneCvSintetico)

  return {
    type: SAVE_DESCRIZIONE_CV_SINTETICO,
    payload: request,
  }
}

export function inviaEmailSegnalazione(id) {
  const request = axios.post(`${API_ROOT_URL}/candidati/${id}/invia_email_segnalazione`)

  return {
    type: INVIA_EMAIL_SEGNALAZIONE,
    payload: request,
  }
}

export function saveNoteInterne(id, noteInterne) {
  const request = axios.put(`${API_ROOT_URL}/candidati/${id}`, noteInterne)

  return {
    type: SAVE_NOTE_INTERNE,
    payload: request,
  }
}

export function savePrivacyFlag(id, consensoPrivacyFlag) {
  const request = axios.put(`${API_ROOT_URL}/candidati/${id}`, { consensoPrivacyFlag })

  return {
    type: SAVE_PRIVACY,
    payload: request,
  }
}

export function savePrivacyMarketingFlag(id, consensoPrivacyMarketingFlag) {
  const request = axios.put(`${API_ROOT_URL}/candidati/${id}`, { consensoPrivacyMarketingFlag })

  return {
    type: SAVE_PRIVACY,
    payload: request,
  }
}

////////////////////////////////////////////////
// CANDIDATO: ADD, REMOVE, UPDATE EXT. ENTITIES --> PROMISE

export function setNewPosizioneProfessionale(posizione) {
  return {
    type: SET_NEW_POSIZIONE_PROFESSIONALE,
    payload: posizione,
  }
}

export function clearNewPosizioneProfessionale() {
  return {
    type: CLEAR_NEW_POSIZIONE_PROFESSIONALE,
    payload: null,
  }
}

export function addObiettivoProfessionale(posizioneProfessionale, candidatoId) {
  const posizione = { posizioneProfessionale, selezionatoFlag: true }
  const request = axios.post(
    `${API_ROOT_URL}/candidati/${candidatoId}/obiettivi_professionali`,
    posizione
  )
  return {
    type: ADD_OBIETTIVO_PROFESSIONALE,
    payload: request,
  }
}

export function saveObiettivoProfessionale(obiettivoProfessionaleId, selezionatoFlag, candidatoId) {
  const posizione = { selezionatoFlag }
  const request = axios.put(
    `${API_ROOT_URL}/candidati/${candidatoId}/obiettivi_professionali/${obiettivoProfessionaleId}`,
    posizione
  )
  return {
    type: SAVE_OBIETTIVO_PROFESSIONALE,
    payload: request,
  }
}

export function removeObiettivoProfessionale(obiettivoProfessionaleId, candidatoId) {
  const request = axios.delete(
    `${API_ROOT_URL}/candidati/${candidatoId}/obiettivi_professionali/${obiettivoProfessionaleId}`
  )
  return {
    type: REMOVE_OBIETTIVO_PROFESSIONALE,
    payload: obiettivoProfessionaleId,
  }
}

///////////////////////////////////////////
// ESPERIENZE LAVORATIVE --> PROMISE

export function addEsperienzaLavorativa(esperienzaLavorativa, candidatoId) {
  const request = axios.post(
    `${API_ROOT_URL}/candidati/${candidatoId}/esperienze_lavorative`,
    esperienzaLavorativa
  )
  return {
    type: ADD_ESPERIENZA_LAVORATIVA,
    payload: request,
  }
}

export function updateEsperienzaLavorativa(
  esperienzaLavorativaId,
  esperienzaLavorativa,
  candidatoId
) {
  const request = axios.put(
    `${API_ROOT_URL}/candidati/${candidatoId}/esperienze_lavorative/${esperienzaLavorativaId}`,
    esperienzaLavorativa
  )
  return {
    type: UPDATE_ESPERIENZA_LAVORATIVA,
    payload: request,
  }
}
export function switchAttivaDisattiva(esperienzaLavorativaId, value) {
  return {
    type: SWITCH_ESPERIENZA_LAVORATIVA_IN_CV,
    payload: value,
  }
}

export function removeEsperienzaLavorativa(esperienzaLavorativaId, candidatoId) {
  const request = axios.delete(
    `${API_ROOT_URL}/candidati/${candidatoId}/esperienze_lavorative/${esperienzaLavorativaId}`
  )
  return {
    type: REMOVE_ESPERIENZA_LAVORATIVA,
    payload: esperienzaLavorativaId,
  }
}

export function duplicateEsperienzaLavorativa(esperienzaLavorativaId, candidatoId) {
  const request = axios.post(
    `${API_ROOT_URL}/candidati/${candidatoId}/esperienze_lavorative/${esperienzaLavorativaId}/duplicate`
  )
  return {
    type: DUPLICATE_ESPERIENZA_LAVORATIVA,
    payload: request,
  }
}

///////////////////////////////////////////
// TITOLI DI STUDIO --> PROMISE

export function addTitoloStudio(titoloStudio, candidatoId) {
  const request = axios.post(`${API_ROOT_URL}/candidati/${candidatoId}/titoli_studio`, titoloStudio)
  return {
    type: ADD_TITOLO_STUDIO,
    payload: request,
  }
}

export function updateTitoloStudio(titoloStudioId, titoloStudio, candidatoId) {
  const request = axios.put(
    `${API_ROOT_URL}/candidati/${candidatoId}/titoli_studio/${titoloStudioId}`,
    titoloStudio
  )
  return {
    type: UPDATE_TITOLO_STUDIO,
    payload: request,
  }
}

export function removeTitoloStudio(titoloStudioId, candidatoId) {
  const request = axios.delete(
    `${API_ROOT_URL}/candidati/${candidatoId}/titoli_studio/${titoloStudioId}`
  )
  return {
    type: REMOVE_TITOLO_STUDIO,
    payload: titoloStudioId,
  }
}

///////////////////////////////////////////
// CORSI DI FORMAZIONE --> PROMISE

export function addCorsoFormazione(corsoFormazione, candidatoId) {
  const request = axios.post(
    `${API_ROOT_URL}/candidati/${candidatoId}/corsi_formazione`,
    corsoFormazione
  )
  return {
    type: ADD_CORSO_FORMAZIONE,
    payload: request,
  }
}

export function updateCorsoFormazione(corsoFormazioneId, corsoFormazione, candidatoId) {
  const request = axios.put(
    `${API_ROOT_URL}/candidati/${candidatoId}/corsi_formazione/${corsoFormazioneId}`,
    corsoFormazione
  )
  return {
    type: UPDATE_CORSO_FORMAZIONE,
    payload: request,
  }
}

export function removeCorsoFormazione(corsoFormazioneId, candidatoId) {
  const request = axios.delete(
    `${API_ROOT_URL}/candidati/${candidatoId}/corsi_formazione/${corsoFormazioneId}`
  )
  return {
    type: REMOVE_CORSO_FORMAZIONE,
    payload: corsoFormazioneId,
  }
}

///////////////////////////////////////////
// CONOSCENZE LINGUISTICHE --> PROMISE

export function addConoscenzaLinguistica(conoscenzaLinguistica, candidatoId) {
  const request = axios.post(
    `${API_ROOT_URL}/candidati/${candidatoId}/conoscenze_linguistiche`,
    conoscenzaLinguistica
  )
  return {
    type: ADD_CONOSCENZA_LINGUISTICA,
    payload: request,
  }
}

export function updateConoscenzaLinguistica(
  conoscenzaLinguisticaId,
  conoscenzaLinguistica,
  candidatoId
) {
  const request = axios.put(
    `${API_ROOT_URL}/candidati/${candidatoId}/conoscenze_linguistiche/${conoscenzaLinguisticaId}`,
    conoscenzaLinguistica
  )
  return {
    type: UPDATE_CONOSCENZA_LINGUISTICA,
    payload: request,
  }
}

export function removeConoscenzaLinguistica(conoscenzaLinguisticaId, candidatoId) {
  const request = axios.delete(
    `${API_ROOT_URL}/candidati/${candidatoId}/conoscenze_linguistiche/${conoscenzaLinguisticaId}`
  )
  return {
    type: REMOVE_CONOSCENZA_LINGUISTICA,
    payload: conoscenzaLinguisticaId,
  }
}

///////////////////////////////////////////
// CONOSCENZE INFORMATICHE --> PROMISE

export function addConoscenzaInformatica(conoscenzaInformatica, candidatoId) {
  const request = axios.post(
    `${API_ROOT_URL}/candidati/${candidatoId}/conoscenze_informatiche`,
    conoscenzaInformatica
  )
  return {
    type: ADD_CONOSCENZA_INFORMATICA,
    payload: request,
  }
}

export function updateConoscenzaInformatica(
  conoscenzaInformaticaId,
  conoscenzaInformatica,
  candidatoId
) {
  const request = axios.put(
    `${API_ROOT_URL}/candidati/${candidatoId}/conoscenze_informatiche/${conoscenzaInformaticaId}`,
    conoscenzaInformatica
  )
  return {
    type: UPDATE_CONOSCENZA_INFORMATICA,
    payload: request,
  }
}

export function removeConoscenzaInformatica(conoscenzaInformaticaId, candidatoId) {
  const request = axios.delete(
    `${API_ROOT_URL}/candidati/${candidatoId}/conoscenze_informatiche/${conoscenzaInformaticaId}`
  )
  return {
    type: REMOVE_CONOSCENZA_INFORMATICA,
    payload: conoscenzaInformaticaId,
  }
}

/////////////////////////////////////////
// UPLOAD ALLEGATI

export function uploadAllegati(data, candidatoId) {
  const request = axios.post(`${API_ROOT_URL}/candidati/${candidatoId}/allegati`, data)
  return {
    type: UPLOAD_ALLEGATI,
    payload: request,
  }
}

export function removeAllegato(allegatoId, candidatoId) {
  const request = axios.delete(`${API_ROOT_URL}/candidati/${candidatoId}/allegati/${allegatoId}`)
  return {
    type: REMOVE_ALLEGATO,
    payload: allegatoId,
  }
}

/////////////////////////////////////////
// INTERESSE AZIENDA > CANDIDATO

export function createInteressePerCandidato(candidatoId) {
  const request = axios.post(`${API_ROOT_URL}/candidati/${candidatoId}/interessi_aziende`)

  return {
    type: CREATE_INTERESSE_PER_CANDIDATO,
    payload: request,
  }
}

export function deleteInteressePerCandidato(candidatoId) {
  const request = axios.delete(`${API_ROOT_URL}/candidati/${candidatoId}/interessi_aziende`)

  return {
    type: DELETE_INTERESSE_PER_CANDIDATO,
    payload: candidatoId,
  }
}

/////////////////////////////////////////
// AGENZIA > SBLOCCO CANDIDATO

export function sbloccaCandidato(candidatoId) {
  const request = axios.post(`${API_ROOT_URL}/candidati/${candidatoId}/sblocca`)

  return {
    type: SBLOCCA_CANDIDATO,
    payload: request,
  }
}

/////////////////////////////////////////
// SLOT APPUNTAMENTI

export function fetchSlots(sportelloId) {
  const request = axios.get(`${API_ROOT_URL}/slot?sportelloId=${sportelloId}`)

  return {
    type: FETCH_SLOTS,
    payload: request,
  }
}

export function fetchSlot(slotId, start, end) {
  let payload = {}

  if (slotId) {
    const request = axios.get(`${API_ROOT_URL}/slot/${slotId}`)
    payload = request
  } else {
    payload = {
      data: {
        dataOraInizio: start,
        dataOraFine: end,
        numeroMassimoCandidati: 10,
        descrizione: '',
      },
    }
  }

  return {
    type: FETCH_SLOT,
    payload: payload,
  }
}

export function createSlot(values) {
  // Preparo i dati per la chiamata
  values.dataOraInizio = moment(values.dataInizio + 'T' + values.oraInizio)
  values.dataOraFine = moment(values.dataFine + 'T' + values.oraFine)
  delete values.dataInizio
  delete values.oraInizio
  delete values.dataFine
  delete values.oraFine

  const request = axios.post(`${API_ROOT_URL}/slot`, values)

  return {
    type: CREATE_SLOT,
    payload: request,
  }
}

export function saveSlot(values, slotId) {
  // Preparo i dati per la chiamata
  values.dataOraInizio = moment(values.dataInizio + 'T' + values.oraInizio)
  values.dataOraFine = moment(values.dataFine + 'T' + values.oraFine)
  delete values.dataInizio
  delete values.oraInizio
  delete values.dataFine
  delete values.oraFine

  const request = axios.put(`${API_ROOT_URL}/slot/${slotId}`, values)

  return {
    type: SAVE_SLOT,
    payload: request,
  }
}

export function deleteSlot(slotId) {
  const request = axios.delete(`${API_ROOT_URL}/slot/${slotId}`)

  return {
    type: DELETE_SLOT,
    payload: request,
  }
}

/////////////////////////////////////////
// APPUNTAMENTI

export function addAppuntamento(slotId, candidatoId) {
  const request = axios.post(`${API_ROOT_URL}/slot/${slotId}/appuntamenti`, { candidatoId })
  return {
    type: ADD_APPUNTAMENTO,
    payload: request,
  }
}

export function removeAppuntamento(slotId, candidatoId) {
  const request = axios.delete(`${API_ROOT_URL}/slot/${slotId}/appuntamenti/${candidatoId}`)
  return {
    type: REMOVE_APPUNTAMENTO,
    payload: candidatoId,
  }
}

/////////////////////////////////////////
// GRUPPI

export function fetchGruppi(tuttiGliSportelli = false) {
  const request = axios.get(
    `${API_ROOT_URL}/gruppi_cv_sintetico?tutti_gli_sportelli=${tuttiGliSportelli}`
  )

  return {
    type: FETCH_GRUPPI,
    payload: request,
  }
}

export function fetchGruppiCandidato(candidatoId) {
  const request = axios.get(`${API_ROOT_URL}/candidati/${candidatoId}/gruppi_cv_sintetico`)

  return {
    type: FETCH_GRUPPI_CANDIDATO,
    payload: request,
  }
}

export function fetchGruppo(gruppoId) {
  const request = axios.get(`${API_ROOT_URL}/gruppi_cv_sintetico/${gruppoId}`)

  return {
    type: FETCH_GRUPPO,
    payload: request,
  }
}

export function createGruppo(nome) {
  const request = axios.post(`${API_ROOT_URL}/gruppi_cv_sintetico`, { nome })

  return {
    type: CREATE_GRUPPO,
    payload: request,
  }
}

export function saveGruppo(values, gruppoId) {
  const request = axios.put(`${API_ROOT_URL}/gruppi_cv_sintetico/${gruppoId}`, values)

  return {
    type: SAVE_GRUPPO,
    payload: request,
  }
}

export function deleteGruppo(gruppoId) {
  const request = axios.delete(`${API_ROOT_URL}/gruppi_cv_sintetico/${gruppoId}`)

  return {
    type: DELETE_GRUPPO,
    payload: gruppoId,
  }
}

export function inviaEmailCvGruppo(gruppoId) {
  const request = axios.get(`${API_ROOT_URL}/gruppi_cv_sintetico/${gruppoId}/invio_email`)

  return {
    type: INVIA_EMAIL_CV_GRUPPO,
    payload: gruppoId,
  }
}

export function addCandidatiGruppo(gruppoId, candidatiIds) {
  const candidati = candidatiIds.map(candidatoId => ({ candidatoId }))
  const request = axios.post(`${API_ROOT_URL}/gruppi_cv_sintetico/${gruppoId}/candidati`, {
    candidati,
  })

  return {
    type: ADD_CANDIDATI_GRUPPO,
    payload: request,
  }
}

/////////////////////////////////////////
// CANDIDATI GRUPPI

export function addCandidatoGruppo(gruppoId, candidatoId) {
  const request = axios.post(`${API_ROOT_URL}/gruppi_cv_sintetico/${gruppoId}/candidati`, {
    candidatoId,
  })
  return {
    type: ADD_CANDIDATO_GRUPPO,
    payload: request,
  }
}

// Identica a action sopra, solo che viene chiamata dall'Anagrafica candidato
// per aggiungere un gruppo: il reducer è diverso perché non impatta su GruppoAttivo,
// ma su CandidatoAttivo.Angrafica.
export function addGruppoCandidato(candidatoId, gruppoId) {
  const request = axios.post(`${API_ROOT_URL}/candidati/${candidatoId}/gruppi_cv_sintetico`, {
    gruppoCvSinteticoId: gruppoId,
  })
  return {
    type: ADD_GRUPPO_CANDIDATO,
    payload: request,
  }
}

export function removeCandidatoGruppo(gruppoId, candidatoId) {
  const request = axios.delete(
    `${API_ROOT_URL}/gruppi_cv_sintetico/${gruppoId}/candidati/${candidatoId}`
  )
  return {
    type: REMOVE_CANDIDATO_GRUPPO,
    payload: candidatoId,
  }
}

/////////////////////////////////////////
// AREE GEOGRAFICHE

export function fetchAreeGeografiche(areaGeograficaId, areaGeograficaNome) {
  if (areaGeograficaId && areaGeograficaNome) {
    return {
      type: FETCH_AREE_GEOGRAFICHE,
      payload: {
        data: [{ areaGeograficaId: areaGeograficaId, nome: areaGeograficaNome }],
      },
    }
  }

  const request = axios.get(`${API_ROOT_URL}/aree_geografiche`)
  return {
    type: FETCH_AREE_GEOGRAFICHE,
    payload: request,
  }
}

export function fetchAreaGeografica(areaGeograficaId) {
  const request = axios.get(`${API_ROOT_URL}/aree_geografiche/${areaGeograficaId}`)

  return {
    type: FETCH_AREA_GEOGRAFICA,
    payload: request,
  }
}

export function createAreaGeografica(values) {
  const request = axios.post(`${API_ROOT_URL}/aree_geografiche`, values)

  return {
    type: CREATE_AREA_GEOGRAFICA,
    payload: request,
  }
}

export function saveAreaGeografica(values, areaGeograficaId) {
  const request = axios.put(`${API_ROOT_URL}/aree_geografiche/${areaGeograficaId}`, values)

  return {
    type: SAVE_AREA_GEOGRAFICA,
    payload: request,
  }
}

export function deleteAreaGeografica(areaGeograficaId) {
  const request = axios.delete(`${API_ROOT_URL}/aree_geografiche/${areaGeograficaId}`)

  return {
    type: DELETE_AREA_GEOGRAFICA,
    payload: areaGeograficaId,
  }
}

export function resetAreaGeograficaAttiva() {
  return {
    type: RESET_AREA_GEOGRAFICA_ATTIVA,
    payload: null,
  }
}

/////////////////////////////////////////
// SPORTELLI

export function fetchSportelli({ keywords = '', organizzazione = '', areaGeograficaId = '' } = {}) {
  const queryKeywords = `?keywords=${keywords}`
  const queryOrganizzazione = organizzazione ? `&organizzazione=${organizzazione}` : ''
  const queryAreaGeografica = areaGeograficaId ? `&area_geografica_id=${areaGeograficaId}` : ''
  const queryString = queryKeywords + queryOrganizzazione + queryAreaGeografica

  const request = axios.get(`${API_ROOT_URL}/sportelli${queryString}`)

  return {
    type: FETCH_SPORTELLI,
    payload: request,
  }
}

export function fetchSportelliPerArea(organizzazione) {
  const request = axios.get(
    `${API_ROOT_URL}/sportelli?per_area=true&organizzazione=${organizzazione}`
  )

  return {
    type: FETCH_SPORTELLI_PER_AREA,
    payload: request,
  }
}

export function fetchSportello(sportelloId) {
  const request = axios.get(`${API_ROOT_URL}/sportelli/${sportelloId}`)

  return {
    type: FETCH_SPORTELLO,
    payload: request,
  }
}

export function createSportello(values) {
  const valuesToSave = { ...values }
  if (valuesToSave.padreSportelloId === '') {
    valuesToSave.padreSportelloId = null
  }
  const request = axios.post(`${API_ROOT_URL}/sportelli`, valuesToSave)

  return {
    type: CREATE_SPORTELLO,
    payload: request,
  }
}

export function saveSportello(values, sportelloId) {
  const valuesToSave = { ...values }
  if (valuesToSave.padreSportelloId === '') {
    valuesToSave.padreSportelloId = null
  }
  const request = axios.put(`${API_ROOT_URL}/sportelli/${sportelloId}`, valuesToSave)

  return {
    type: SAVE_SPORTELLO,
    payload: request,
  }
}

export function deleteSportello(sportelloId) {
  const request = axios.delete(`${API_ROOT_URL}/sportelli/${sportelloId}`)

  return {
    type: DELETE_SPORTELLO,
    payload: sportelloId,
  }
}

export function resetSportelloAttivo() {
  return {
    type: RESET_SPORTELLO_ATTIVO,
    payload: null,
  }
}

/////////////////////////////////////////
// OPERATORI

export function fetchOperatori(filter = { keywords: '' }) {
  const queryKeywords = `?keywords=${filter.keywords}`
  const queryOrganizzazione = filter.organizzazione
    ? `&organizzazione=${filter.organizzazione}`
    : ''
  const queryAreaGeografica = filter.areaGeograficaId
    ? `&area_geografica_id=${filter.areaGeograficaId}`
    : ''
  const queryString = queryKeywords + queryOrganizzazione + queryAreaGeografica

  const request = axios.get(`${API_ROOT_URL}/operatori${queryString}`)

  return {
    type: FETCH_OPERATORI,
    payload: request,
  }
}

export function fetchOperatore(operatoreId) {
  const request = axios.get(`${API_ROOT_URL}/operatori/${operatoreId}`)

  return {
    type: FETCH_OPERATORE,
    payload: request,
  }
}

export function createOperatore(values) {
  const request = axios.post(`${API_ROOT_URL}/operatori`, values)

  return {
    type: CREATE_OPERATORE,
    payload: request,
  }
}

export function saveOperatore(values, operatoreId) {
  const request = axios.put(`${API_ROOT_URL}/operatori/${operatoreId}`, values)

  return {
    type: SAVE_OPERATORE,
    payload: request,
  }
}

export function deleteOperatore(operatoreId) {
  const request = axios.delete(`${API_ROOT_URL}/operatori/${operatoreId}`)

  return {
    type: DELETE_OPERATORE,
    payload: operatoreId,
  }
}

export function resetOperatoreAttivo() {
  return {
    type: RESET_OPERATORE_ATTIVO,
    payload: null,
  }
}

// SPORTELLI operatoreId
export function addSportelloOperatore(operatoreId, sportelloId, responsabileFlag) {
  const request = axios.post(`${API_ROOT_URL}/operatori/${operatoreId}/sportelli`, {
    sportelloId,
    responsabileFlag,
  })

  return {
    type: ADD_SPORTELLO_OPERATORE,
    payload: request,
  }
}

export function removeSportelloOperatore(operatoreId, sportelloId) {
  const request = axios.delete(`${API_ROOT_URL}/operatori/${operatoreId}/sportelli/${sportelloId}`)

  return {
    type: REMOVE_SPORTELLO_OPERATORE,
    payload: sportelloId,
  }
}

/////////////////////////////////////////
// SUB-AMMINISTRATORI

export function fetchSubAmministratori() {
  const request = axios.get(`${API_ROOT_URL}/amministratori`)

  return {
    type: FETCH_SUBAMMINISTRATORI,
    payload: request,
  }
}

export function fetchSubAmministratore(amministratoreId) {
  const request = axios.get(`${API_ROOT_URL}/amministratori/${amministratoreId}`)

  return {
    type: FETCH_SUBAMMINISTRATORE,
    payload: request,
  }
}

export function createSubAmministratore(values) {
  const request = axios.post(`${API_ROOT_URL}/amministratori`, values)

  return {
    type: CREATE_SUBAMMINISTRATORE,
    payload: request,
  }
}

export function saveSubAmministratore(values, amministratoreId) {
  const request = axios.put(`${API_ROOT_URL}/amministratori/${amministratoreId}`, values)

  return {
    type: SAVE_SUBAMMINISTRATORE,
    payload: request,
  }
}

export function deleteSubAmministratore(amministratoreId) {
  const request = axios.delete(`${API_ROOT_URL}/amministratori/${amministratoreId}`)

  return {
    type: DELETE_SUBAMMINISTRATORE,
    payload: amministratoreId,
  }
}

export function resetSubAmministratoreAttivo() {
  return {
    type: RESET_SUBAMMINISTRATORE_ATTIVO,
    payload: null,
  }
}

/////////////////////////////////////////
// SITO PUBBLICO

export function fetchProvince() {
  const request = axios.get(`${API_ROOT_URL}/province`)

  return {
    type: FETCH_PROVINCE,
    payload: request,
  }
}

export function fetchProvinceConJv() {
  const request = axios.get(`${API_ROOT_URL}/province?con_jv=true`)

  return {
    type: FETCH_PROVINCE,
    payload: request,
  }
}

export function invioFormSportello(sportelloId, values) {
  const request = axios.post(
    `${API_ROOT_URL}/sportelli/${sportelloId}/richieste_appuntamenti`,
    values
  )

  return {
    type: INVIO_FORM_SPORTELLO,
    payload: request,
  }
}

export function resetStatoInvioFormSportello() {
  return {
    type: RESET_STATO_INVIO_FORM_SPORTELLO,
  }
}

export function registrazioneUtente(values) {
  let registrazione = { ...values }
  let endPoint = ''

  switch (values.tipoUtente && values.tipoUtente.toLowerCase()) {
    case 'azienda':
      endPoint = 'aziende'
      delete registrazione.tipoUtente
      delete registrazione.nome
      delete registrazione.cognome
      delete registrazione.comuneEsteso
      break
    case 'agenzia':
      endPoint = 'agenzie'
      delete registrazione.tipoUtente
      delete registrazione.nome
      delete registrazione.cognome
      delete registrazione.comuneEsteso
      break
    case 'candidato':
      endPoint = 'candidati'
      delete registrazione.tipoUtente
      delete registrazione.ragioneSociale
      delete registrazione.referenteNome
      delete registrazione.referenteCognome
      delete registrazione.indirizzo
      delete registrazione.cap
      delete registrazione.comune
      delete registrazione.codiceFiscale
      delete registrazione.partitaIva
      break
    default:
      endPoint = 'aziende'
      break
  }

  const request = axios.post(`${API_ROOT_URL}/${endPoint}`, registrazione)

  return {
    type: REGISTRAZIONE_UTENTE,
    payload: request,
  }
}

export function resetStatoRegistrazione() {
  return {
    type: RESET_STATO_REGISTRAZIONE,
  }
}

/////////////////////////////////////////
// AZIENDE

export function fetchAziende(keywords = '', sportelloCorrenteFlag = true, page = 1, pageSize = 5) {
  const request = axios.get(
    `${API_ROOT_URL}/aziende?page=${page}&page_size=${pageSize}&keywords=${keywords}&sportello_corrente=${sportelloCorrenteFlag}`
  )

  return {
    type: FETCH_AZIENDE,
    payload: request,
  }
}

export function fetchAzienda(aziendaId) {
  const request = axios.get(`${API_ROOT_URL}/aziende/${aziendaId}`)

  return {
    type: FETCH_AZIENDA,
    payload: request,
  }
}

export function createAzienda(values) {
  const azienda = { ...values }
  delete azienda.comuneEsteso

  const request = axios.post(`${API_ROOT_URL}/aziende`, azienda)

  return {
    type: CREATE_AZIENDA,
    payload: request,
  }
}

export function saveAzienda(values) {
  const azienda = { ...values }
  delete azienda.aziendaId
  delete azienda.comuneEsteso
  delete azienda.dataCreazione

  const request = axios.put(`${API_ROOT_URL}/aziende/${values.aziendaId}`, azienda)

  return {
    type: SAVE_AZIENDA,
    payload: request,
  }
}

export function deleteAzienda(aziendaId) {
  const request = axios.delete(`${API_ROOT_URL}/aziende/${aziendaId}`)

  return {
    type: DELETE_AZIENDA,
    payload: aziendaId,
  }
}

export function setKeywordsAziende(keywords) {
  return {
    type: SET_KEYWORDS_AZIENDE,
    payload: keywords,
  }
}

export function setSportelloCorrenteFlagAziende(sportelloCorrenteFlag) {
  return {
    type: SET_SPORTELLO_CORRENTE_FLAG_AZIENDE,
    payload: sportelloCorrenteFlag,
  }
}

export function changePageAziende(page) {
  return {
    type: CHANGE_PAGE_AZIENDE,
    payload: page,
  }
}

export function fetchJobVacanciesAzienda(aziendaId, page = 1, pageSize = 20) {
  const request = axios.get(
    `${API_ROOT_URL}/aziende/${aziendaId}/job_vacancies?page=${page}&page_size=${pageSize}`
  )

  return {
    type: FETCH_JOB_VACANCIES_AZIENDA,
    payload: request,
  }
}

/////////////////////////////////////////
// AGENZIE

export function fetchAgenzie(keywords = '', sportelloCorrenteFlag = true, page = 1, pageSize = 5) {
  const request = axios.get(
    `${API_ROOT_URL}/agenzie?page=${page}&page_size=${pageSize}&keywords=${keywords}&sportello_corrente=${sportelloCorrenteFlag}`
  )

  return {
    type: FETCH_AGENZIE,
    payload: request,
  }
}

export function fetchAgenzia(agenziaId) {
  const request = axios.get(`${API_ROOT_URL}/agenzie/${agenziaId}`)

  return {
    type: FETCH_AGENZIA,
    payload: request,
  }
}

export function createAgenzia(values) {
  const agenzia = { ...values }
  delete agenzia.comuneEsteso

  const request = axios.post(`${API_ROOT_URL}/agenzie`, agenzia)

  return {
    type: CREATE_AGENZIA,
    payload: request,
  }
}

export function saveAgenzia(values) {
  const agenzia = { ...values }
  delete agenzia.agenziaId
  delete agenzia.comuneEsteso
  delete agenzia.dataCreazione

  const request = axios.put(`${API_ROOT_URL}/agenzie/${values.agenziaId}`, agenzia)

  return {
    type: SAVE_AGENZIA,
    payload: request,
  }
}

export function deleteAgenzia(agenziaId) {
  const request = axios.delete(`${API_ROOT_URL}/agenzie/${agenziaId}`)

  return {
    type: DELETE_AGENZIA,
    payload: agenziaId,
  }
}

export function setKeywordsAgenzie(keywords) {
  return {
    type: SET_KEYWORDS_AGENZIE,
    payload: keywords,
  }
}

export function setSportelloCorrenteFlagAgenzie(sportelloCorrenteFlag) {
  return {
    type: SET_SPORTELLO_CORRENTE_FLAG_AGENZIE,
    payload: sportelloCorrenteFlag,
  }
}

export function changePageAgenzie(page) {
  return {
    type: CHANGE_PAGE_AGENZIE,
    payload: page,
  }
}

export function fetchJobVacanciesAgenzia(agenziaId, page = 1, pageSize = 20) {
  const request = axios.get(
    `${API_ROOT_URL}/agenzie/${agenziaId}/job_vacancies?page=${page}&page_size=${pageSize}`
  )

  return {
    type: FETCH_JOB_VACANCIES_AGENZIA,
    payload: request,
  }
}

export function addCreditiAgenzia(agenziaId, crediti, note) {
  const request = axios.post(`${API_ROOT_URL}/movimenti_crediti_agenzie`, {
    agenziaId,
    crediti,
    note,
  })

  return {
    type: ADD_CREDITI_AGENZIA,
    payload: request,
  }
}

/////////////////////////////////////////
// JOB VACANCIES

export function fetchJobVacancies({
  organizzazione = '',
  provincia = '',
  keywords = '',
  soloProprietarioFlag = false,
  mostraJvAperte = false,
  page = 1,
  pageSize = 20,
  homeFlag = false,
}) {
  const params = `organizzazione=${organizzazione}&page=${page}&page_size=${pageSize}${
    provincia && '&provincia=' + provincia
  }${keywords && '&keywords=' + keywords}${
    soloProprietarioFlag ? '&solo_proprietario_flag=' + soloProprietarioFlag : ''
  }${mostraJvAperte ? '&mostra_jv_aperte=' + mostraJvAperte : ''}${
    homeFlag ? '&home_flag=' + homeFlag : ''
  }`

  const request = axios.get(`${API_ROOT_URL}/job_vacancies?${params}`)

  return {
    type: FETCH_JOB_VACANCIES,
    payload: request,
  }
}

export function fetchJobVacancy(jobVacancyId) {
  const request = axios.get(`${API_ROOT_URL}/job_vacancies/${jobVacancyId}`)

  return {
    type: FETCH_JOB_VACANCY,
    payload: request,
  }
}

export function createJobVacancy(values, permessoJvHome) {
  const jv = { ...values }

  delete jv.luogoLavoroComuneEsteso

  if (!permessoJvHome) {
    delete jv.homeFlag
  }

  const request = axios.post(`${API_ROOT_URL}/job_vacancies`, jv)

  return {
    type: CREATE_JOB_VACANCY,
    payload: request,
  }
}

export function saveJobVacancy(values, permessoJvHome) {
  const jv = { ...values }
  delete jv.jobVacancyId
  delete jv.agenziaId
  delete jv.aziendaId
  delete jv.sportelloId
  delete jv.dataCreazione
  delete jv.luogoLavoroComuneEsteso
  delete jv.dataInizioPubblicazione
  delete jv.luogoLavoroComune
  delete jv.luogoLavoroProvincia
  delete jv.agenziaRagioneSociale
  delete jv.agenziaMarchio
  delete jv.agenziaLogo
  delete jv.aziendaRagioneSociale
  delete jv.aziendaLogo
  delete jv.sportelloNome
  delete jv.sportelloOrganizzazione
  delete jv.sportelloAgenziaSportelloId
  delete jv.sportelloAgenziaNome
  delete jv.sportelloAgenziaOrganizzazione
  delete jv.sportelloAziendaSportelloId
  delete jv.sportelloAziendaNome
  delete jv.sportelloAziendaOrganizzazione
  delete jv.numeroInteressati
  delete jv.candidatiInteressati

  if (!permessoJvHome) {
    delete jv.homeFlag
  }

  jv.statoVacancy = jv.statoVacancy || '';
  jv.descrizioneSintetica = jv.descrizioneSintetica || '';
  jv.figuraProfessionale = jv.figuraProfessionale || '';
  jv.mansione = jv.mansione || '';
  jv.descrizioneCompleta = jv.descrizioneCompleta || '';
  jv.dataInizio = jv.dataInizio || '';
  jv.datoreLavoro = jv.datoreLavoro || '';
  jv.orarioLavoro = jv.orarioLavoro || '';
  jv.luogoLavoro = jv.luogoLavoro || '';
  jv.tipologiaContratto = jv.tipologiaContratto || '';
  jv.rangeRetributivo = jv.rangeRetributivo || '';
  jv.livelloStudi = jv.livelloStudi || '';
  jv.titoloStudio = jv.titoloStudio || '';
  jv.esperienzaRichiesta = jv.esperienzaRichiesta || '';
  jv.skill = jv.skill || '';
  jv.altriRequisiti = jv.altriRequisiti || '';
  jv.particolarita = jv.particolarita || '';
  jv.dataScadenza = jv.dataScadenza || '';

  const request = axios.put(`${API_ROOT_URL}/job_vacancies/${values.jobVacancyId}`, jv)

  return {
    type: SAVE_JOB_VACANCY,
    payload: request,
  }
}

export function deleteJobVacancy(jobVacancyId) {
  const request = axios.delete(`${API_ROOT_URL}/job_vacancies/${jobVacancyId}`)

  return {
    type: DELETE_JOB_VACANCY,
    payload: jobVacancyId,
  }
}

export function setKeywordsJobVacancies(keywords) {
  return {
    type: SET_KEYWORDS_JOB_VACANCIES,
    payload: keywords,
  }
}

export function setProvinciaJobVacancies(provincia) {
  return {
    type: SET_PROVINCIA_JOB_VACANCIES,
    payload: provincia,
  }
}

export function setSoloProprietarioFlag(soloProprietarioFlag) {
  return {
    type: SET_SOLO_PROPRIETARIO_FLAG_JV,
    payload: soloProprietarioFlag,
  }
}

export function setMostraJvAperte(mostraJvAperte) {
  return {
    type: SET_MOSTRA_JV_APERTE,
    payload: mostraJvAperte,
  }
}

export function changePageJobVacancies(page) {
  return {
    type: CHANGE_PAGE_JOB_VACANCIES,
    payload: page,
  }
}

export function createCandidatura(jobVacancyId) {
  const request = axios.post(`${API_ROOT_URL}/job_vacancies/${jobVacancyId}/candidati`)

  return {
    type: CREATE_CANDIDATURA,
    payload: request,
  }
}

export function deleteCandidatura(jobVacancyId, candidatoId) {
  const request = axios.delete(
    `${API_ROOT_URL}/job_vacancies/${jobVacancyId}/candidati/${candidatoId}`
  )

  return {
    type: DELETE_CANDIDATURA,
    payload: jobVacancyId,
  }
}

/////////////////////////////////////////
// OPERATORI AZIENDE AGENZIE

export function fetchOperatoriAzag(tipo, aziendaAgenziaId) {
  const request = axios.get(`${API_ROOT_URL}/${tipo}/${aziendaAgenziaId}/operatori`)

  return {
    type: FETCH_OPERATORI_AZAG,
    payload: request,
  }
}

export function setEditOperatoreAzag(utenteId, isEdit) {
  return {
    type: SET_EDIT_OPERATORE_AZAG,
    payload: { utenteId, isEdit },
  }
}

export function saveOperatoreAzag(tipo, aziendaAgenziaId, values) {
  const operatore = { ...values }

  delete operatore.utenteId
  delete operatore.dataFineBlocco

  const request = axios.put(
    `${API_ROOT_URL}/${tipo}/${aziendaAgenziaId}/operatori/${values.utenteId}`,
    operatore
  )

  return {
    type: SAVE_OPERATORE_AZAG,
    payload: request,
  }
}

export function createOperatoreAzag(tipo, aziendaAgenziaId, values) {
  const operatore = { ...values }

  const request = axios.post(`${API_ROOT_URL}/${tipo}/${aziendaAgenziaId}/operatori`, operatore)

  return {
    type: CREATE_OPERATORE_AZAG,
    payload: request,
  }
}

export function deleteOperatoreAzag(tipo, aziendaAgenziaId, utenteId) {
  const request = axios.delete(`${API_ROOT_URL}/${tipo}/${aziendaAgenziaId}/operatori/${utenteId}`)

  return {
    type: DELETE_OPERATORE_AZAG,
    payload: utenteId,
  }
}

export function sendEmail(values) {
  if (values.destinatario === 'candidato') {
    delete values.gruppoCvSinteticoId
  } else {
    delete values.candidatoId
  }

  delete values.tipoMessaggio
  delete values.destinatario

  const request = axios.post(`${API_ROOT_URL}/messaggi/email`, values)

  return {
    type: SEND_EMAIL,
    payload: request,
  }
}

export function sendSms(values) {
  if (values.destinatario === 'candidato') {
    delete values.gruppoCvSinteticoId
  } else {
    delete values.candidatoId
  }

  delete values.tipoMessaggio
  delete values.destinatario
  delete values.oggetto

  const request = axios.post(`${API_ROOT_URL}/messaggi/sms`, values)

  return {
    type: SEND_SMS,
    payload: request,
  }
}

export function resetStatoInvioMessaggio() {
  return {
    type: RESET_STATO_INVIO_MESSAGGIO,
  }
}

export function sendContattoApp(oggetto, messaggio) {
  const request = axios.post(`${API_ROOT_URL}/contatto_sportello`, { oggetto, messaggio })

  return {
    type: SEND_CONTATTO_APP,
    payload: request,
  }
}

export function resetStatoContattoApp() {
  return {
    type: RESET_STATO_CONTATTO_APP,
  }
}

export function fetchNotifiche(page, pageSize = 10) {
  const request = axios.get(`${API_ROOT_URL}/notifiche?page=${page}&page_size=${pageSize}`)

  return {
    type: FETCH_NOTIFICHE,
    payload: request,
  }
}

export function changePageNotifiche(page) {
  return {
    type: CHANGE_PAGE_NOTIFICHE,
    payload: page,
  }
}

export function fetchNotificheNonLette() {
  const request = axios.get(`${API_ROOT_URL}/notifiche?non_lette=true`)
  const x = axios.get(`${API_ROOT_URL}/notifiche_non_lette`)

  return {
    type: FETCH_NOTIFICHE_NON_LETTE,
    payload: x,
  }
}

export function setNotificaLetta(notificaId) {
  const request = axios.put(`${API_ROOT_URL}/notifiche/${notificaId}`, { flagLetto: true })

  return {
    type: SET_NOTIFICA_LETTA,
    payload: request,
  }
}

export function createNotaDiarioCandidato(candidatoId, notaDiario) {
  const request = axios.post(`${API_ROOT_URL}/candidati/${candidatoId}/note_diario`, notaDiario)

  return {
    type: CREATE_NOTA_DIARIO_CANDIDATO,
    payload: request,
  }
}

export function deleteNotaDiarioCandidato(candidatoId, notaDiarioId) {
  const request = axios.delete(
    `${API_ROOT_URL}/candidati/${candidatoId}/note_diario/${notaDiarioId}`
  )

  return {
    type: DELETE_NOTA_DIARIO_CANDIDATO,
    payload: notaDiarioId,
  }
}

// MOVIMENTI

export function fetchMovimentiCreditiAgenzie(mese, anno) {
  const request = axios.get(`${API_ROOT_URL}/movimenti_crediti_agenzie?mese=${mese}&anno=${anno}`)

  return {
    type: FETCH_MOVIMENTI_CREDITI_AGENZIE,
    payload: request,
  }
}

export function fetchAcquisizioneCandidatiSportello(mese, anno) {
  const request = axios.get(
    `${API_ROOT_URL}/acquisizione_candidati_sportello?mese=${mese}&anno=${anno}`
  )

  return {
    type: FETCH_ACQUISIZIONE_CANDIDATI_SPORTELLO,
    payload: request,
  }
}

// NEWSLETTERS LIST

export function fetchNewsletters(page, pageSize = 7) {
  const request = axios.get(`${API_ROOT_URL}/newsletters?page=${page}&page_size=${pageSize}`)

  return {
    type: FETCH_NEWSLETTERS,
    payload: request,
  }
}

export function changePageNewsletters(page) {
  return {
    type: CHANGE_PAGE_NEWSLETTERS,
    payload: page,
  }
}

export function deleteNewsletter(newsletterId) {
  const request = axios.delete(`${API_ROOT_URL}/newsletters/${newsletterId}`)

  return {
    type: DELETE_NEWSLETTER,
    payload: newsletterId,
  }
}

// NEWSLETTER DETAILS

export function resetNewsletter() {
  return {
    type: RESET_NEWSLETTER,
  }
}

export function fetchNewsletter(newsletterId) {
  const request = axios.get(`${API_ROOT_URL}/newsletters/${newsletterId}`)

  return {
    type: FETCH_NEWSLETTER,
    payload: request,
  }
}

export function duplicateNewsletter(newsletterId) {
  const request = axios.post(`${API_ROOT_URL}/newsletters/${newsletterId}/duplica`)

  return {
    type: DUPLICATE_NEWSLETTER,
    payload: request,
  }
}

function cleanNewsletter(newsletter, isSend = false) {
  const output = { ...newsletter }
  delete output.tipoDestinatari
  delete output.numeroDestinatari
  delete output.html
  delete output.dataInvio
  delete output.operatoreNome
  delete output.gruppoCvSinteticoNome
  delete output.dataCreazione
  // delete output.allegato1
  // delete output.allegato2
  delete output.allegato1rendered
  delete output.allegato2rendered

  if (!isSend) {
    delete output.newsletterId
  }

  if (!newsletter.gruppoCvSinteticoId) {
    output.sportelli = newsletter.sportelli.map(s => s.sportelloId)
  }

  output.paragrafi = newsletter.paragrafi.map(p => ({
    titolo: p.titolo,
    testo: p.testo,
  }))

  const data = new FormData()

  if (newsletter.allegato1 && newsletter.allegato1.length && newsletter.allegato1[0].name) {
    data.append('file1', newsletter.allegato1[0])
    delete output.allegato1
  }
  if (newsletter.allegato2 && newsletter.allegato2.length && newsletter.allegato2[0].name) {
    data.append('file2', newsletter.allegato2[0])
    delete output.allegato2
  }

  data.append('newsletter', JSON.stringify(output))

  return data
}

export function createNewsletter(newsletter) {
  const request = axios.post(`${API_ROOT_URL}/newsletters`, cleanNewsletter(newsletter))

  return {
    type: CREATE_NEWSLETTER,
    payload: request,
  }
}

export function saveNewsletter(newsletter) {
  const request = axios.put(
    `${API_ROOT_URL}/newsletters/${newsletter.newsletterId}`,
    cleanNewsletter(newsletter)
  )

  return {
    type: SAVE_NEWSLETTER,
    payload: request,
  }
}

export function sendNewsletter(newsletter, isProva = false) {
  const request = axios.post(
    `${API_ROOT_URL}/newsletters/send?test=${isProva}`,
    cleanNewsletter(newsletter, true)
  )

  return {
    type: SEND_NEWSLETTER,
    payload: request,
  }
}

export function updateNewsletter(field, value) {
  return {
    type: UPDATE_NEWSLETTER,
    payload: { field, value },
  }
}

export function fetchNumeroDestinatari(destinatari) {
  const request = axios.post(`${API_ROOT_URL}/newsletters/numero_destinatari`, destinatari)

  return {
    type: FETCH_NUMERO_DESTINATARI,
    payload: request,
  }
}

// PARAGRAFI

export function addParagrafoNewsletter() {
  return {
    type: ADD_PARAGRAFO_NEWSLETTER,
  }
}

export function uploadAllegatoNewsletter(allegatoId, value, valueToBeRendered) {
  return {
    type: UPLOAD_ALLEGATO_NEWSLETTER,
    payload: { allegatoId, value, valueToBeRendered },
  }
}
export function removeAllegatoNewsletter(allegatoId) {
  return {
    type: REMOVE_ALLEGATO_NEWSLETTER,
    payload: allegatoId,
  }
}

export function updateParagrafoNewsletter(paragrafoId, field, value) {
  return {
    type: UPDATE_PARAGRAFO_NEWSLETTER,
    payload: { paragrafoId, field, value },
  }
}

export function removeParagrafoNewsletter(paragrafoId) {
  return {
    type: REMOVE_PARAGRAFO_NEWSLETTER,
    payload: paragrafoId,
  }
}

// SPORTELLI

export function addSportelloNewsletter(sportello) {
  return {
    type: ADD_SPORTELLO_NEWSLETTER,
    payload: sportello,
  }
}

export function removeSportelloNewsletter(sportelloId) {
  return {
    type: REMOVE_SPORTELLO_NEWSLETTER,
    payload: sportelloId,
  }
}

// REVOCA CONSENSO NEWSLETTER

export function revocaConsenso(token) {
  const request = axios.post(`${API_ROOT_URL}/newsletters/unsubscribe`, { token })

  return {
    type: REVOCA_CONSENSO_NEWSLETTER,
    payload: request,
  }
}

export function setFilterNoteDiarioEscludiAutomatiche(flag) {
  return {
    type: SET_FILTER_NOTE_DIARIO_ESCLUDI_AUTOMATICHE,
    payload: flag,
  }
}

//////////////////////////////////////////////
// CREDITI SMS CONSUMATI -> SET_FILTER

export function setFilterCreditiSmsConsumati(field, value) {
  return {
    type: SET_FILTER_CREDITI_SMS_CONSUMATI,
    payload: { field, value },
  }
}

//////////////////////////////////////////////
// CREDITI SMS CONSUMATI -> LOAD_CREDITI
export function fetchCreditiSmsConsumati({ daData, aData, areaGeograficaId, sportelloId }) {
  const areaGeografica = areaGeograficaId ? `&area_geografica_id=${areaGeograficaId}` : ''
  const sportello = sportelloId ? `&sportello_id=${sportelloId}` : ''

  const request = axios.get(
    `${API_ROOT_URL}/crediti_sms_consumati?da_data=${daData}&a_data=${aData}${areaGeografica}${sportello}`
  )

  return {
    type: FETCH_CREDITI_SMS_CONSUMATI,
    payload: request,
  }
}

// CREDITI SMS

export function fetchCreditiSms() {
  const request = axios.get(`${API_ROOT_URL}/crediti_sms_disponibili`)
  return {
    type: FETCH_CREDITI_SMS,
    payload: request,
  }
}

// PANNELLO EXPORT CSV FILTRI HOME PAGE
export function setFilterPannelloExportCsv(field, value) {
  return {
    type: SET_FILTER_PANNELLO_EXPORT_CSV,
    payload: { field, value },
  }
}

// export function fetchExportCsvCandidati({ areaGeograficaId, sportelloId }) {
//   const areaGeografica = areaGeograficaId ? `&area_geografica_id=${areaGeograficaId}` : ''
//   const sportello = sportelloId ? `&sportello_id=${sportelloId}` : ''

//   const request = axios.get(`${API_ROOT_URL}/candidati?export=csv${}${areaGeografica}${sportello}`)

//   return {
//     type: FETCH_CREDITI_SMS_CONSUMATI,
//     payload: request
//   }
// }

//CORSI DI FORMAZIONE
export function cambiaPaginaCorsiFormazione(page) {
  return {
    type: CAMBIA_PAGINA_CORSI_FORMAZIONE,
    payload: page,
  }
}

export function setKeywordsCorsiFormazione(keywords) {
  return {
    type: SET_KEYWORDS_CORSI_FORMAZIONE,
    payload: keywords,
  }
}
export function fetchCorsiDiFormazione() {
  return axios.get(`${API_ROOT_URL}/corsi_formazione_proposti`).then(response => {
    return {
      type: FETCH_CORSI_FORMAZIONE,
      payload: response,
    }
  })
}
export function fetchCorsiDiFormazioneCandidato(candidatoId) {
  return axios
    .get(`${API_ROOT_URL}/candidati/${candidatoId}/corsi_formazione_proposti`)
    .then(response => {
      return {
        type: FETCH_CORSI_FORMAZIONE_CANDIDATO,
        payload: response,
      }
    })
}
export function saveCorsoFormazione(values) {
  const { corsoFormazionePropostoId, sportelloId, numeroCandidati, candidati, ...corsoFormazione } =
    { ...values }

  const request = axios.put(
    `${API_ROOT_URL}/corsi_formazione_proposti/${corsoFormazionePropostoId}`,
    corsoFormazione
  )

  return {
    type: SAVE_CORSO_FORMAZIONE,
    payload: request,
  }
}

export function createCorsoFormazione(values, permessoGesioneCorsi) {
  const corsoFormazione = { ...values }

  delete corsoFormazione.luogoLavoroComuneEsteso

  const request = axios.post(`${API_ROOT_URL}/corsi_formazione_proposti`, corsoFormazione)

  return {
    type: CREATE_CORSO_FORMAZIONE,
    payload: request,
  }
}
export function deleteCorsoFormazione(corsoFormazioneId) {
  axios.delete(`${API_ROOT_URL}/corsi_formazione_proposti/${corsoFormazioneId}`)
  return {
    type: DELETE_CORSO_FORMAZIONE,
    payload: corsoFormazioneId,
  }
}
export function resetCorsoAttivo() {
  return {
    type: RESET_CORSO_ATTIVO,
    payload: null,
  }
}
export function fetchCorsoAttivo(corsoId) {
  return axios.get(`${API_ROOT_URL}/corsi_formazione_proposti/${corsoId}`).then(res => {
    return {
      type: FETCH_CORSO_ATTIVO,
      payload: res.data,
    }
  })
}
export function fetchCorsiCandidato(candidatoId, corsoId) {
  return axios
    .get(`${API_ROOT_URL}/candidati/${candidatoId}/corsi_formazione_proposti/${corsoId}`)
    .then(res => {
      return {
        type: FETCH_CORSI_FORMAZIONE_CANDIDATO,
        payload: res.data,
      }
    })
}
export function subscribeCorso(candidatoId, corsoFormazionePropostoId) {
  return axios
    .post(`${API_ROOT_URL}/candidati/${candidatoId}/corsi_formazione_proposti`, {
      corsoFormazionePropostoId,
    })
    .then(res => {
      return { type: SUBSCRIBE_CORSO, payload: res }
    })
}
export function unscribeCorso(candidatoId, corsoFormazionePropostoId) {
  return axios.delete(
    `${API_ROOT_URL}/candidati/${candidatoId}/corsi_formazione_proposti/${corsoFormazionePropostoId}`
  )
}
