import moment from 'moment'
import {
  CHANGE_FILTER_CANDIDATI,
  CHANGE_PAGE_CANDIDATI,
  CREATE_INTERESSE_PER_CANDIDATO,
  DELETE_INTERESSE_PER_CANDIDATO,
  FETCH_CANDIDATI,
  GET_STATISTICHE_CANDIDATO,
  GET_STATS,
  SBLOCCA_CANDIDATO,
  SET_FILTRO_PANNELLO_STATISTICHE,
  SET_VIEW_MODE_CANDIDATI,
  TOGGLE_TIPO_RICERCA_CANDIDATI,
} from '../actions/types'

const INITIAL_FILTERS = {
  tipoCandidati: 'sportello',
  keywords: '',
  keywordsUI: '',
  comuneDisponibilita: {},
  servizioRichiesto: '',
  statoServizio: '',
  comuneDomicilio: {},
  rangeEta: '',
  sesso: '',
  lingue: '',
  obiettiviProfessionali: '',
  esperienzeLavorative: '',
  formazione: '',
  interesseCorsi: '',
  livelloPatente: '',
  disponibiltaTempo: '',
  automunito: null,
  invalidita: null,
}

const INITIAL_STATE = {
  data: [],
  pagination: {
    page: 1,
    totalPages: 1,
  },
  viewMode: 'thumbs',
  tipoRicerca: 'keywords',
  pageRequested: 1,
  filters: INITIAL_FILTERS,
  statistiche: {},
  filtroPannelloStatistiche: {
    areaGeograficaId: '',
    sportelloId: '',
    dataDa: moment().add(-1, 'months').format('YYYY-MM-DD'),
    dataA: moment().format('YYYY-MM-DD'),
  },
}

export default function reducerCandidati(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_CANDIDATI:
      return {
        ...state,
        data: action.payload.data.items,
        pagination: action.payload.data.pagination,
      }
    case SET_VIEW_MODE_CANDIDATI:
      return {
        ...state,
        viewMode: action.payload,
        pageRequested: 1,
      }
    case CHANGE_FILTER_CANDIDATI:
      const changed = {
        [action.payload.field]: action.payload.value,
      }
      return {
        ...state,
        filters: {
          ...state.filters,
          ...changed,
        },
        pageRequested: 1,
      }
    case TOGGLE_TIPO_RICERCA_CANDIDATI:
      // Resetto i filtri per keyword (ricerca base)
      // e tutti i campi della ricerca avanzata,
      // ma mantengo il filtro sul tipo di candidati
      // (sportello, regione, rete, archiviati)
      return {
        ...state,
        tipoRicerca: action.payload,
        filters: {
          ...INITIAL_FILTERS,
          tipoCandidati: state.filters.tipoCandidati,
        },
      }
    case CHANGE_PAGE_CANDIDATI:
      return {
        ...state,
        pageRequested: action.payload,
      }
    case CREATE_INTERESSE_PER_CANDIDATO:
      return {
        ...state,
        data: state.data.map((c) => {
          if (action.payload.data && c.candidatoId === action.payload.data.candidatoId) {
            return {
              ...c,
              interessato: true,
            }
          }
          return c
        }),
      }
    case DELETE_INTERESSE_PER_CANDIDATO:
      return {
        ...state,
        data: state.data.map((c) => {
          if (c.candidatoId === action.payload) {
            return {
              ...c,
              interessato: false,
            }
          }
          return c
        }),
      }
    case SBLOCCA_CANDIDATO:
      return {
        ...state,
        data: state.data.map((c) => {
          if (action.payload.data && c.candidatoId === action.payload.data.candidatoId) {
            return {
              ...c,
              ...action.payload.data,
            }
          }
          return c
        }),
      }
    case GET_STATISTICHE_CANDIDATO:
      return {
        ...state,
        statistiche: {
          ...action.payload,
        },
      }
    case GET_STATS:
      return {
        ...state,
        statistiche: {
          ...action.payload,
        },
      }
    case SET_FILTRO_PANNELLO_STATISTICHE: {
      return {
        ...state,
        filtroPannelloStatistiche: {
          ...state.filtroPannelloStatistiche,
          [action.payload.field]: action.payload.value,
        },
      }
    }
    default:
      return state
  }
}
