import { FETCH_LOOKUPS } from '../actions/types'

const INITIAL_STATE = {}

export default function reducerLookups(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_LOOKUPS:
      return action.payload.data
    default:
      return state
  }
}
