import moment from 'moment'
import { FETCH_GRUPPI, CREATE_GRUPPO, DELETE_GRUPPO } from '../actions/types'

const INITIAL_STATE = []

const formatGruppi = (gruppi) =>
  gruppi.map((gruppo) => {
    return {
      ...gruppo,
      dataCreazione: gruppo.dataCreazione ? moment(gruppo.dataCreazione).format('DD/MM/YYYY') : null,
    }
  })

export default function gruppiReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_GRUPPI:
      return formatGruppi(action.payload.data)
    case CREATE_GRUPPO:
      return [
        ...state,
        {
          ...action.payload.data,
          dataCreazione: moment(action.payload.data.dataCreazione).format('DD/MM/YYYY'),
          numeroCandidati: 0,
        },
      ]
    case DELETE_GRUPPO:
      return state.filter((gruppo) => gruppo.gruppoCvSinteticoId !== action.payload)
    default:
      return state
  }
}
