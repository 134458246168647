import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toggleMobileMenuOpen } from '../../../actions'

import { Link } from 'react-router-dom'

import {
  MdDashboard,
  MdAccountBox,
  MdPeople,
  MdGrade,
  MdToday,
  MdDomain,
  MdPlace,
  MdStore,
  MdSecurity,
  MdAssignment,
  MdBusinessCenter,
  MdMail,
  MdSms,
  MdPerson,
  MdSwapCalls,
  MdList,
  MdInfoOutline,
} from 'react-icons/md'
import { IoMdStats } from 'react-icons/io'

import './header_menu.less'
import { Dropdown, MenuItem } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

class HeaderMenu extends Component {
  renderMenu(ruolo, permessi) {
    switch (ruolo) {
      case 'modifica_password': {
        return (
          <ul className="menu">
            <li>
              <Link to="/app" activeclassname="active">
                <MdDashboard />
                Home
              </Link>
            </li>
            <li>
              <Link to="/cambio-password" activeclassname="active">
                <MdToday />
                Cambia password
              </Link>
            </li>
          </ul>
        )
      }

      case 'candidato': {
        return (
          <ul className="menu">
            <li>
              <Link to="/app" activeclassname="active">
                <MdDashboard />
                Home
              </Link>
            </li>
            <li>
              <Link to={`/app/candidati/${this.props.user.candidatoId}`} activeclassname="active">
                <MdPeople />
                Curriculum
              </Link>
            </li>
            {/* <li>
							<Link to="/appuntamenti" activeclassname="active"><MdToday />Appuntamenti</Link>
						</li> */}
            <li>
              <Link to="/app/job-vacancies" activeclassname="active">
                <MdAssignment />
                Job vacancies
              </Link>
            </li>
            <li>
              <Link to="/app/contatto-app" activeclassname="active">
                <MdMail />
                Contattaci
              </Link>
            </li>
            <li>
              <Link to="/app/corsi-di-formazione">
                <MdInfoOutline />
                Corsi
              </Link>
            </li>
          </ul>
        )
      }

      case 'agenzia': {
        return (
          <ul className="menu">
            <li>
              <Link to="/app" activeclassname="active">
                <MdDashboard />
                Home
              </Link>
            </li>
            <li>
              <Link to={`/app/agenzie/${this.props.user.agenziaId}`} activeclassname="active">
                <MdAccountBox />
                Profilo
              </Link>
            </li>
            <li>
              <Link to="/app/candidati" activeclassname="active">
                <MdPeople />
                Candidati
              </Link>
            </li>
            <li>
              <Link to="/app/job-vacancies" activeclassname="active">
                <MdAssignment />
                Job vacancies
              </Link>
            </li>
            <li>
              <Link to="/app/contatto-app" activeclassname="active">
                <MdMail />
                Contattaci
              </Link>
            </li>
          </ul>
        )
      }

      case 'azienda': {
        return (
          <ul className="menu">
            <li>
              <Link to="/app" activeclassname="active">
                <MdDashboard />
                Home
              </Link>
            </li>
            <li>
              <Link to={`/aziende/${this.props.user.aziendaId}`} activeclassname="active">
                <MdPerson />
                Profilo
              </Link>
            </li>
            <li>
              <Link to="/app/candidati" activeclassname="active">
                <MdPeople />
                Candidati
              </Link>
            </li>
            <li>
              <Link to="/job-vacancies" activeclassname="active">
                <MdAssignment />
                Job vacancies
              </Link>
            </li>
            <li>
              <Link to="/contatto-app" activeclassname="active">
                <MdMail />
                Contattaci
              </Link>
            </li>
          </ul>
        )
      }

      case 'operatore': {
        return (
          <ul className="menu">
            <li>
              <Link to="/app" activeclassname="active">
                <MdDashboard />
                Home
              </Link>
            </li>
            <li>
              {/* <Dropdown id="candidati-dropdown">
                <Dropdown.Toggle noCaret>
                  <MdPeople />
                  Candidati
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu">
                  <LinkContainer to="/app/candidati">
                    <MenuItem>
                      <MdList />
                      Lista Candidati
                    </MenuItem>
                  </LinkContainer>
                  <LinkContainer to="/app/candidati/statistiche">
                    <MenuItem>
                      <IoMdStats />
                      Statistiche Candidati
                    </MenuItem>
                  </LinkContainer>
                </Dropdown.Menu>
              </Dropdown> */}
              <Link to="/app/candidati">
                <MdPeople />
                Candidati
              </Link>
            </li>
            <li>
              <Link to="/app/gruppi" activeclassname="active">
                <MdGrade />
                Gruppi
              </Link>
            </li>
            <li>
              <Link to="/app/appuntamenti" activeclassname="active">
                <MdToday />
                Appuntamenti
              </Link>
            </li>
            <li>
              <Link to="/app/aziende" activeclassname="active">
                <MdDomain />
                Aziende
              </Link>
            </li>
            <li>
              <Link to="/app/agenzie" activeclassname="active">
                <MdBusinessCenter />
                Agenzie
              </Link>
            </li>
            <li>
              <Link to="/app/job-vacancies" activeclassname="active">
                <MdAssignment />
                Job vacancies
              </Link>
            </li>
            <li>
              <Link to="/app/corsi-di-formazione">
                <MdInfoOutline />
                Corsi
              </Link>
            </li>
            {/* <li>
              <Link to="/app/movimenti" activeclassname="active">
                <MdSwapCalls />
                Movimenti
              </Link>
            </li> */}
            {(permessi.newsletterFlag || true) && (
              <li>
                <Link to="/app/newsletter" activeclassname="active">
                  <MdMail />
                  Newsletter
                </Link>
              </li>
            )}
          </ul>
        )
      }

      case 'subamministratore': {
        return (
          <ul className="menu">
            <li>
              <Link to="/app" activeclassname="active">
                <MdDashboard />
                Home
              </Link>
            </li>
            <li>
              <Link to="/app/sportelli" activeclassname="active">
                <MdStore />
                Sportelli
              </Link>
            </li>
            <li>
              <Link to="/app/operatori" activeclassname="active">
                <MdPeople />
                Operatori
              </Link>
            </li>
            <li>
              <Link to="/app/statistiche" activeclassname="active">
                <IoMdStats />
                Statistiche
              </Link>
            </li>
          </ul>
        )
      }

      case 'amministratore': {
        return (
          <ul className="menu">
            <li>
              <Link to="/app" activeclassname="active">
                <MdDashboard />
                Home
              </Link>
            </li>
            <li>
              <Link to="/app/regioni" activeclassname="active">
                <MdPlace />
                Regioni
              </Link>
            </li>
            <li>
              <Link to="/app/sportelli" activeclassname="active">
                <MdStore />
                Sportelli
              </Link>
            </li>
            <li>
              <Link to="/app/operatori" activeclassname="active">
                <MdPeople />
                Operatori
              </Link>
            </li>
            <li>
              <Link to="/app/sub-amministratori" activeclassname="active">
                <MdSecurity />
                Sub-amministratori
              </Link>
            </li>
            <li>
              <Link to="/app/aziende" activeclassname="active">
                <MdDomain />
                Aziende
              </Link>
            </li>
            <li>
              <Link to="/app/agenzie" activeclassname="active">
                <MdBusinessCenter />
                Agenzie
              </Link>
            </li>
            <li>
              <Link to="/app/crediti-sms-consumati" activeclassname="active">
                <MdSms />
                Crediti SMS
              </Link>
            </li>
            <li>
              <Link to="/app/statistiche" activeclassname="active">
                <IoMdStats />
                Statistiche
              </Link>
            </li>
          </ul>
        )
      }
      default:
        break
    }
  }
  
  renderPublicMenu() {
    return (
      <ul className="public menu">
        <li>
          <Link to="/" activeclassname="active">
            Home
          </Link>
        </li>
        <li>
          <Link to="/cerca-lavoro" activeclassname="active">
            Cerca lavoro
          </Link>
        </li>
        <li>
          <Link to="/cerca-candidati" activeclassname="active">
            Cerca candidati
          </Link>
        </li>
        <li>
          <Link to="/chi-siamo" activeclassname="active">
            Chi siamo
          </Link>
        </li>
        <li>
          <Link to="/servizi" activeclassname="active">
            Servizi
          </Link>
        </li>
        <li className="menu-right">
          <Link className="red" to="/registrazione" activeclassname="active">
            Registrati
          </Link>
        </li>
        <li className="menu-right">
          <Link to="/contatti" activeclassname="active">
            Contattaci
          </Link>
        </li>
      </ul>
    )
  }

  render() {
    return (
      <nav className={`header-menu ${this.props.mobileMenuOpen ? 'open' : ''}`}>
        <div className="container">
          {this.props.isAuthenticated ? this.renderMenu(this.props.user.ruolo, this.props.user.permessi) : null}
          {/* // this.renderPublicMenu()} */}
        </div>
      </nav>
    )
  }
}

function mapStateToProps(state) {
  return {
    mobileMenuOpen: state.header.mobileMenuOpen,
    user: state.auth.user,
    isAuthenticated: state.auth.authenticated,
  }
}

export default connect(mapStateToProps, { toggleMobileMenuOpen })(HeaderMenu)
