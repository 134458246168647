import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Ink from 'react-ink'

import { MdModeEdit } from 'react-icons/md'

class NoteInterne extends Component {
  render() {
    const { candidatoId, noteInterne, canEdit } = this.props

    // if (!noteInterne) {
    //   return <div>Caricamento in corso...</div>;
    // }
    return (
      <div className="card-box note-interne">
        <div className="note-interne">
          <h4>NOTE INTERNE</h4>
          <p>{noteInterne}</p>
        </div>

        <div className="text-right">
          {canEdit ? (
            <Link
              to={`/app/candidati/${candidatoId}/note-interne`}
              className="btn btn-sm btn-primary"
            >
              <Ink />
              <MdModeEdit />
              Modifica note interne
            </Link>
          ) : null}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    candidatoId: state.candidatoAttivo.candidatoId,
    noteInterne: state.candidatoAttivo.noteInterne,
    canEdit:
    !!state.auth.user.permessi['anagrafica'] &&
    state.candidatoAttivo.sportello &&
    state.candidatoAttivo.sportello.sportelloId === state.auth.user.sportelloCorrente.sportelloId,
  }
}

export default connect(mapStateToProps)(NoteInterne)
