import React, { Component } from 'react'
import cogoToast from 'cogo-toast'
import { connect } from 'react-redux'
import { Form, Field } from 'react-final-form'

import { fetchCandidato, saveDescrizioneCvSintetico, inviaEmailSegnalazione } from '../../actions'
import BottoneAnnulla from './bottone_annulla'

class EditCvSintetico extends Component {
  componentDidMount() {
    // Ho già il candidato attivo se navigo normalmente
    // dal dettaglio candidato: questo controllo serve
    // nel caso in cui ricarico la pagina da qui (F5)
    if (!this.props.candidatoId) {
      this.props.fetchCandidato(this.props.match.params.id)
    }
  }

  onSubmit(props) {

    this.props.saveDescrizioneCvSintetico(this.props.match.params.id, props).then((action) => {
      this.props.history.goBack()
    })
  }

  handleInviaSegnalazione = (event) => {
    this.props.inviaEmailSegnalazione(this.props.match.params.id).then((res) => {
      if (res.payload.status === 200) {
        cogoToast.success("L'e-mail con il CV sintetico è stata inviata", {
          heading: 'Email inviata',
          position: 'top-right',
          hideAfter: 5,
        })
      }
    })
  }

  render() {
    const initialValues = this.props.initialValues ? this.props.initialValues : {}
    return (
      <Form
        onSubmit={this.onSubmit.bind(this)}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <div className="heading">
              <h1 className="page-title pull-left">CV sintetico</h1>
              <BottoneAnnulla />
            </div>
            <div className="panel panel-border panel-primary">
              <div className="panel-heading">
                <h3
                  className="panel-title"
                  style={{
                    color: form.getFieldState('descrizioneCvSintetico')?.error ? '#c00' : '',
                  }}
                >
                  Descrizione CV Sintetico -{' '}
                  {(form.getFieldState('descrizioneCvSintetico')?.value + '').length}{' '}
                  {form.getFieldState('descrizioneCvSintetico')?.error}
                </h3>
              </div>
              <div className="panel-body">
                <div className="form-group">
                  <Field
                    id="descrizioneCvSintetico"
                    name="descrizioneCvSintetico"
                    className="form-control"
                    style={{ height: '204px' }}
                    placeholder="Inserire Esperienze professionali pregresse, Skills, Disponibilità ed eventuali Sgravi o incentivi"
                    component="textarea"
                  />
                  <a
                    href="/images/note_compilazione_cv_sintetico.pdf"
                    target="_blank"
                    className="help-block"
                  >
                    Note per la compilazione del CV sintetico
                  </a>
                </div>
              </div>
            </div>

            <div className="text-center">
              {/* <button type="button" disabled={this.props.dirty} className="btn btn-purple btn-lg" onClick={this.handleInviaSegnalazione}>Invia via e-mail &raquo;</button>&nbsp;&nbsp; */}
              <button type="submit" className="btn btn-primary btn-lg">
                Salva CV sintetico &raquo;
              </button>
            </div>
          </form>
        )}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: { descrizioneCvSintetico: state.candidatoAttivo.descrizioneCvSintetico },
    candidatoId: state.candidatoAttivo.candidatoId, // per sapere se ho già il candidato attivo su F5
  }
}

function validate(values) {
  const errors = {}

  if (values.descrizioneCvSintetico && values.descrizioneCvSintetico.length < 400) {
    errors.descrizioneCvSintetico = 'Troppo breve'
  }

  if (values.descrizioneCvSintetico && values.descrizioneCvSintetico.length > 1000) {
    errors.descrizioneCvSintetico = 'Troppo lunga'
  }

  return errors
}

export default connect(mapStateToProps, {
  fetchCandidato,
  saveDescrizioneCvSintetico,
  inviaEmailSegnalazione,
})(EditCvSintetico)
