import React, { Component } from 'react'
import { connect } from 'react-redux'
import swal from 'sweetalert'
import { Link } from 'react-router-dom'
import { MdAdd, MdLock, MdShuffle } from 'react-icons/md'

import { fetchAreeGeografiche, deleteAreaGeografica, resetAreaGeograficaAttiva } from '../../../actions'

class AreeGeograficheList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      areaGeograficaId: null,
    }

    this.props.fetchAreeGeografiche()
  }

  componentDidMount() {
    this.props.resetAreaGeograficaAttiva()
  }

  handleRemove = (areaGeograficaId) => (event) => {
    this.handleDeleteRegion()
    this.setState({
      areaGeograficaId,
    })
  }

  handleConfirmRemove = () => {
    this.props.deleteAreaGeografica(this.state.areaGeograficaId)
    this.setState({
      areaGeograficaId: null,
    })
  }

  handleCancel = (event) => {
    this.setState({
      areaGeograficaId: null,
    })
  }

  renderAreeGeografiche() {
    const { areeGeografiche } = this.props
    return areeGeografiche.map((areaGeografica, index) => {
      return (
        <tr key={index}>
          <td>
            <Link to={'/app/regioni/' + areaGeografica.areaGeograficaId}>{areaGeografica.nome}</Link>
          </td>
          <td className="chiusa">{!!areaGeografica.chiusaFlag && <MdLock />}</td>
          <td className="spostamento">{!!areaGeografica.noConfermaSpostamentoFlag && <MdShuffle />}</td>
          <td>
            <button className="btn btn-sm btn-danger" onClick={this.handleRemove(areaGeografica.areaGeograficaId)}>
              &times; Elimina
            </button>
          </td>
        </tr>
      )
    })
  }

  handleDeleteRegion = () => {
    swal({
      icon: 'warning',
      title: 'Eliminazione regione',
      text: 'Sei sicuro di vole eliminare questa regione? Questa azione è irreversibile.',
      buttons: ['Annulla', 'Si, elimina regione'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.handleConfirmRemove()
      }
    })
  }

  render() {
    return (
      <div className="aree-geografiche-list">
        <h1 className="page-title">Regioni</h1>
        <div className="card-box">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th className="text-center">Chiusa</th>
                  <th className="text-center">No conferma spostamento</th>
                  <th>Elimina</th>
                </tr>
              </thead>
              <tbody>
                {this.props.areeGeografiche ? (
                  this.renderAreeGeografiche()
                ) : (
                  <tr>
                    <td>Nessuna regione presente</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <Link to="/app/regioni/new" className="btn btn-success">
            <div className="create-center">
              <MdAdd />
              Crea nuova regione
            </div>
          </Link>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    areeGeografiche: state.areeGeografiche,
  }
}

export default connect(mapStateToProps, {
  fetchAreeGeografiche,
  deleteAreaGeografica,
  resetAreaGeograficaAttiva,
})(AreeGeograficheList)
