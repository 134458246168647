import React, { Component } from 'react'
import moment from 'moment'
import { Form, Field } from 'react-final-form'
import _find from 'lodash/find'
import { connect } from 'react-redux'

import BottoneAnnulla from './bottone_annulla'
import { fetchCandidato, addTitoloStudio, updateTitoloStudio } from '../../actions'
import Lookups from '../lookups/lookups'

class AddEditTitoloStudio extends Component {
  ///////////////////////////////////////////////////////////////
  // SE EDIT E RELOAD PAGINA -> FETCH CANDIDATO
  componentDidMount() {
    // Ho già il candidato attivo se navigo normalmente
    // dal dettaglio candidato: questo controllo serve
    // nel caso in cui ricarico la pagina da qui (F5)
    if (this.props.match.id && !this.props.candidatoId) {
      this.props.fetchCandidato(this.props.match.id)
    }
  }

  ///////////////////////////////////////////////////////////////
  // SUBMIT
  onSubmit(values) {
    const { titoloStudioId, ...rest } = values

    if (this.props.match.params.titoloStudioId) {
      // Siamo in UPDATE
      this.props
        .updateTitoloStudio(this.props.match.params.titoloStudioId, rest, this.props.match.params.id)
        .then((action) => {
          // Redirect a dettagli candidato
          this.props.history.goBack()
        })
    } else {
      // Siamo in NEW
      this.props.addTitoloStudio(values, this.props.match.params.id).then((action) => {
        // Redirect a dettagli candidato
        this.props.history.goBack()
      })
    }
  }

  ///////////////////////////////////////////////
  // NEW / EDIT
  renderTitolo() {
    let titolo = ''
    if (this.props.match.params.titoloStudioId) {
      titolo = 'Modifica titolo di studio'
    } else {
      titolo = 'Nuovo titolo di studio'
    }
    return <h1 className="page-title pull-left">{titolo}</h1>
  }

  ///////////////////////////////////////////////////////////////
  // RENDER
  render() {
    const initialValues = this.props.initialValues ? this.props.initialValues : {}

    return (
      <Form
        onSubmit={this.onSubmit.bind(this)}
        validate={validate}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="heading">
              {this.renderTitolo()}
              <BottoneAnnulla />
            </div>

            <div className="row">
              <div className="col-md-8">
                <div className="panel panel-border panel-blue">
                  <div className="panel-heading">
                    <h3 className="panel-title">Titolo di studio</h3>
                  </div>
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <Field
                          name="tipo"
                          id="tipo"
                          render={({ input, meta }) => (
                            <div className={`form-group ${meta.touched && meta.invalid ? 'has-error' : ''}`}>
                              <label htmlFor="tipo">Tipologia</label>
                              <Lookups tipo="tipoTitoloStudio" {...input} />
                              <div className="help-block">{meta.touched && meta.error}</div>
                            </div>
                          )}
                        />
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="titolo">Titolo</label>
                          <Field
                            type="text"
                            className="form-control"
                            id="titolo"
                            name="titolo"
                            placeholder="es. Ingegneria Informatica"
                            component="input"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="istituto">Istituto</label>
                          <Field type="text" className="form-control" id="istituto" name="istituto" component="input" />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="voto">Voto</label>
                          <Field type="text" className="form-control" id="voto" name="voto" component="input" />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="dataInizioMese">Inizio</label>
                          <div className="row">
                            <div className="col-sm-6">
                              <Field
                                name="dataInizioMese"
                                id="dataInizioMese"
                                render={({ input }) => (
                                  <select className="form-control" {...input}>
                                    <option value="">Seleziona mese</option>
                                    <option value="1">Gennaio</option>
                                    <option value="2">Febbraio</option>
                                    <option value="3">Marzo</option>
                                    <option value="4">Aprile</option>
                                    <option value="5">Maggio</option>
                                    <option value="6">Giugno</option>
                                    <option value="7">Luglio</option>
                                    <option value="8">Agosto</option>
                                    <option value="9">Settembre</option>
                                    <option value="10">Ottobre</option>
                                    <option value="11">Novembre</option>
                                    <option value="12">Dicembre</option>
                                  </select>
                                )}
                              />
                            </div>
                            <div className="col-sm-6">
                              <Field
                                id="dataInizioAnno"
                                name="dataInizioAnno"
                                render={({ input, meta }) => (
                                  <>
                                    <input
                                      type="number"
                                      min="1930"
                                      max={moment().format('YYYY')}
                                      className="form-control"
                                      placeholder="Inserisci anno"
                                      {...input}
                                    />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="dataFineMese">Fine</label>
                          {/*<DatePicker
                      id="dataFine"
                      selected={dataFine.value ? moment(dataFine.value) : null}
                      onChange={(momentDate) => dataFine.onChange(momentDate.format('YYYY-MM-DD'))}
                      locale="it-IT"
                      className="form-control"/>*/}
                          <div className="row">
                            <div className="col-sm-6">
                              <Field
                                name="dataFineMese"
                                id="dataFineMese"
                                render={({ input }) => (
                                  <select className="form-control" {...input}>
                                    <option value="">Seleziona mese</option>
                                    <option value="1">Gennaio</option>
                                    <option value="2">Febbraio</option>
                                    <option value="3">Marzo</option>
                                    <option value="4">Aprile</option>
                                    <option value="5">Maggio</option>
                                    <option value="6">Giugno</option>
                                    <option value="7">Luglio</option>
                                    <option value="8">Agosto</option>
                                    <option value="9">Settembre</option>
                                    <option value="10">Ottobre</option>
                                    <option value="11">Novembre</option>
                                    <option value="12">Dicembre</option>
                                  </select>
                                )}
                              />
                            </div>
                            <div className="col-sm-6">
                              <Field
                                name="dataFineAnno"
                                id="dataFineAnno"
                                render={({ input, meta }) => (
                                  <>
                                    <input
                                      type="number"
                                      min="1930"
                                      max={moment().format('YYYY')}
                                      className="form-control"
                                      id="dataFineAnno"
                                      placeholder="Inserisci anno"
                                      {...input}
                                    />
                                    <div className="help-block">{meta.touched && meta.error}</div>
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="panel panel-border panel-blue">
                  <div className="panel-heading">
                    <h3 className="panel-title">Descrizione Curriculum</h3>
                  </div>
                  <div className="panel-body">
                    <div className="form-group">
                      <label htmlFor="descrizioneCv">Percorso formativo, corsi particolari...</label>
                      <Field
                        name="descrizioneCv"
                        id="descrizioneCv"
                        className="form-control"
                        rows="6"
                        component="textarea"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-blue btn-lg">
                Salva titolo di studio &raquo;
              </button>
            </div>
          </form>
        )}
      />
    )
  }
}

function validate(values) {
  const errors = {}

  if (!values.tipo) {
    errors.tipo = 'Seleziona il tipo'
  }

  return errors
}

const mapStateToProps = (state, props) => {
  // Sono in edit, devo inizializzare il form
  if (props.match.params.titoloStudioId) {
    return {
      initialValues: _find(state.candidatoAttivo.titoliStudio, {
        titoloStudioId: parseInt(props.match.params.titoloStudioId),
      }),
      candidatoId: state.candidatoAttivo.candidatoId, // per sapere se ho già il candidato attivo su F5
    }
  }
  return {}
}

// connect: 1st argument mapStateToProps, 2nd mapDispatchToProps
// reduxForm: 1st form config, 2nd mapStateToProps, 3rd mapDipatchToProps
export default connect(mapStateToProps, { fetchCandidato, addTitoloStudio, updateTitoloStudio })(AddEditTitoloStudio)
