import React from 'react'
import { Route, Switch } from 'react-router'
import RequireAuth from '../auth/require_auth'
import JobVacanciesLists from '../job_vacancies/job_vacancies'
import EditJobVacancy from './edit_job_vacancy'
import JobVacancy from './job_vacancy'

const GestioneJobVacancies = (props) => {
  return (
    <div>
      <Switch>
        <Route exact path="/app/job-vacancies" component={JobVacanciesLists} />
        <Route
          path="/app/job-vacancies/new"
          component={RequireAuth(['operatore', 'agenzia', 'azienda'])(EditJobVacancy)}
        />
        <Route
          path="/app/job-vacancies/:jobVacancyId/edit"
          component={RequireAuth(['operatore', 'agenzia', 'azienda'])(EditJobVacancy)}
        />
        <Route path="/app/job-vacancies/:jobVacancyId" component={JobVacancy} />
      </Switch>
    </div>
  )
}

export default GestioneJobVacancies
