import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import ScrollArea from 'react-scrollbar'
import swal from 'sweetalert'

import {
  createNotaDiarioCandidato,
  deleteNotaDiarioCandidato,
  setFilterNoteDiarioEscludiAutomatiche,
} from '../../actions'

import { getNoteDiarioFiltered } from '../../reducers/reducer_candidato_attivo'

import Ink from 'react-ink'
import { MdAdd } from 'react-icons/md'

import './anagrafica_diario.less'

const getInitialState = () => ({
  showAlertElimina: false,
  notaDiario: {
    testo: '',
    categoria: '',
    data: moment().format('YYYY-MM-DD'),
  },
  notaDiarioDaEliminareId: '',
})

class AnagraficaDiario extends Component {
  constructor(props) {
    super(props)

    this.state = getInitialState()
  }
  componentDidMount() {
    this.props.setFilterNoteDiarioEscludiAutomatiche(true)
  }

  showAlertElimina = (notaDiarioId) => (event) => {
    event.preventDefault()
    this.setState({
      notaDiarioDaEliminareId: notaDiarioId,
    })
    this.handleDeleteNota()
  }

  onRemove() {
    this.props.deleteNotaDiarioCandidato(this.props.candidatoId, this.state.notaDiarioDaEliminareId)
  }

  changeNotaDiario = (field) => (event) => {
    event.preventDefault()
    this.setState({
      notaDiario: {
        ...this.state.notaDiario,
        [field]: event.target.value,
      },
    })
  }

  createNotaDiario = (event) => {
    event.preventDefault()

    // Aggiungiamo ora e minuti correnti alla data selezionata
    let notaDiario = { ...this.state.notaDiario }
    delete notaDiario.notaDiarioId
    const adesso = new Date()
    notaDiario.data = moment(notaDiario.data + ' ' + adesso.getHours() + ':' + adesso.getMinutes()).toJSON()

    this.props.createNotaDiarioCandidato(this.props.candidatoId, notaDiario)
    this.setState(getInitialState())
  }

  renderNoteDiario(noteDiario) {
    return noteDiario.map((nota) => {
      return (
        <div key={nota.notaDiarioId} className="nota">
          {!!nota.flagCustom &&
            (!!this.props.user.permessi.cancellazioneNoteDiario ||
              nota.operatoreId === this.props.user.operatoreId) && (
              <a href="#" className="delete pull-right" onClick={this.showAlertElimina(nota.notaDiarioId)}>
                &times;
              </a>
            )}
          <div className="title">
            {moment(nota.data).format('DD MMMM YYYY [alle] HH:mm')}{' '}
            <span className="label label-primary">{nota.categoria || 'Automatica'}</span>
          </div>
          <div>{nota.testo}</div>
          <hr />
        </div>
      )
    })
  }

  handleFiltraNoteAutomatiche = (event) => {
    this.props.setFilterNoteDiarioEscludiAutomatiche(event.target.checked)
  }

  handleDeleteNota = () => {
    swal({
      icon: 'warning',
      title: 'Eliminazione nota',
      text: 'Sei sicuro di voler eliminare questa nota? Questa azione è irreversibile.',
      buttons: ['Annulla', 'Si, elimina nota'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onRemove()
      }
    })
  }

  render() {
    const { noteDiario, filterNoteDiarioEscludiAutomatiche } = this.props
    return (
      <div className="diario">
        <div className="form-group">
          <div className="checkbox checkbox-primary">
            <input
              type="checkbox"
              id="nascondiNoteAutomatiche"
              value={filterNoteDiarioEscludiAutomatiche ? filterNoteDiarioEscludiAutomatiche : true}
              checked={!!filterNoteDiarioEscludiAutomatiche}
              onChange={this.handleFiltraNoteAutomatiche}
            />
            <label htmlFor="nascondiNoteAutomatiche">Nascondi note automatiche</label>
          </div>
        </div>

        <div className="note-diario">
          <ScrollArea speed={0.8} className="area" contentClassName="content" horizontal={false}>
            <div>{this.renderNoteDiario(noteDiario)}</div>
          </ScrollArea>
        </div>

        <div className="nuova-nota">
          <form onSubmit={(event) => this.createNotaDiario(event)}>
            <div className="row">
              <div className="col-sm-4">
                <div className="form-group">
                  <label htmlFor="data">Data</label>
                  <input
                    className="form-control"
                    name="data"
                    id="data"
                    type="date"
                    value={this.state.notaDiario.data}
                    onChange={this.changeNotaDiario('data')}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="categoria">Categoria</label>
                  <select
                    className="form-control"
                    name="categoria"
                    id="categoria"
                    value={this.state.notaDiario.categoria}
                    onChange={this.changeNotaDiario('categoria')}
                  >
                    <option disabled value="">
                      -- Seleziona Categoria --
                    </option>
                    <option value="area_abitativa">Area abitativa</option>
                    <option value="area_assistenziale e_previdenziale">Area assistenziale e previdenziale</option>
                    <option value="area_bonus">Area bonus</option>
                    <option value="area_disabilita_e_non_autosufficienza">Area disabilità e non autosufficienza</option>
                    <option value="area_fiscale">Area fiscale</option>
                    <option value="area_socio_sanitaria">Area socio sanitaria</option>
                    <option value="contatto_tel_email">Contatto telefono / email</option>
                    <option value="formazione">Formazione</option>
                    <option value="inviato_colloquio">Inviato colloquio</option>
                    <option value="job_club">Job club</option>
                    <option value="nota_personale">Nota personale</option>
                    <option value="politiche_attive">Politiche attive</option>
                    <option value="varie">Varie</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-8">
                <div className="form-group">
                  <label htmlFor="testo">Testo</label>
                  <textarea
                    className="form-control"
                    name="testo"
                    id="testo"
                    rows="4"
                    value={this.state.notaDiario.testo}
                    onChange={this.changeNotaDiario('testo')}
                  ></textarea>
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-success btn-block"
              disabled={!this.state.notaDiario.testo || !this.state.notaDiario.categoria}
            >
              <Ink />
              <MdAdd /> Aggiungi nota
            </button>
          </form>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    candidatoId: state.candidatoAttivo.candidatoId,
    noteDiario: getNoteDiarioFiltered(state),
    filterNoteDiarioEscludiAutomatiche: state.candidatoAttivo.filterNoteDiarioEscludiAutomatiche,
    user: state.auth.user,
  }
}

export default connect(mapStateToProps, {
  createNotaDiarioCandidato,
  deleteNotaDiarioCandidato,
  setFilterNoteDiarioEscludiAutomatiche,
})(AnagraficaDiario)
