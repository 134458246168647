import React, { Component } from 'react'
import { Form, Field } from 'react-final-form'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchCandidato, saveAltreInformazioni } from '../../actions'
import Lookups from '../lookups/lookups'

class EditAltreInformazioni extends Component {
  componentDidMount() {
    // Ricarico sempre il candidato attivo perché potrei arrivare qui
    // dalla creazione di un nuovo candidato
    this.props.fetchCandidato(this.props.match.params.id)
  }

  onSubmit(values) {
    this.props.saveAltreInformazioni(this.props.match.params.id, values).then((action) => {
      this.props.history.push('/app/candidati/' + this.props.match.params.id)
    })
  }

  render() {
    const initialValues = this.props.initialValues ? this.props.initialValues : {}

    return (
      <Form
        onSubmit={this.onSubmit.bind(this)}
        initialValues={initialValues}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <div className="heading">
              <h1 className="page-title pull-left">Altre informazioni</h1>
              <Link to={`/app/candidati/${this.props.match.params.id}`} className="btn btn-default pull-right">
                &laquo; Annulla
              </Link>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="panel panel-border panel-blue">
                  <div className="panel-heading">
                    <h3 className="panel-title">Patente</h3>
                  </div>
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="patente">Patente</label>
                          <Field name="patente" id="patente" type="text" className="form-control" component="input" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="automunitoFlag">Automunito</label>
                          <div className="checkbox checkbox-blue">
                            <Field
                              name="automunitoFlag"
                              id="automunitoFlag"
                              type="checkbox"
                              className="form-control"
                              component="input"
                            />
                            <label htmlFor="automunitoFlag">Sì, automunito</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="statoCivile">Stato civile</label>
                          <Field
                            name="statoCivile"
                            id="statoCivile"
                            render={({ input }) => <Lookups tipo="tipoStatoCivile" {...input} />}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="numeroFigli">N° figli</label>
                          <Field
                            name="numeroFigli"
                            id="numeroFigli"
                            type="number"
                            className="form-control"
                            component="input"
                            min="0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="panel panel-border panel-blue">
                  <div className="panel-heading">
                    <h3 className="panel-title">Invalidità</h3>
                  </div>
                  <div className="panel-body">
                    <div className="form-group">
                      <div className="checkbox checkbox-blue">
                        <Field
                          name="disabileFlag"
                          id="disabileFlag"
                          type="checkbox"
                          className="form-control"
                          component="input"
                        />

                        <label htmlFor="disabileFlag">Disabile</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-blue">
                        <Field
                          name="invalidoCivileFlag"
                          id="invalidoCivileFlag"
                          type="checkbox"
                          className="form-control"
                          component="input"
                        />

                        <label htmlFor="invalidoCivileFlag">Invalido civile</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-blue">
                        <Field
                          name="altriSoggettiSvantaggiatiFlag"
                          id="altriSoggettiSvantaggiatiFlag"
                          type="checkbox"
                          className="form-control"
                          component="input"
                        />
                        <label htmlFor="altriSoggettiSvantaggiatiFlag">Altro soggetto svantaggiato</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="checkbox checkbox-blue">
                        <Field
                          name="collocamentoObbligatorioFlag"
                          id="collocamentoObbligatorioFlag"
                          type="checkbox"
                          className="form-control"
                          component="input"
                        />
                        <label htmlFor="collocamentoObbligatorioFlag">Soggetto a collocamento obbligatorio</label>
                      </div>
                    </div>
                    {form.getFieldState('disableFlag')?.value || form.getFieldState('invalidoCivileFlag')?.value ? (
                      <div className="form-group">
                        <label htmlFor="percentualeInvalidita">% invalidità</label>
                        <Field
                          component="input"
                          type="number"
                          className="form-control"
                          id="percentualeInvalidita"
                          name="percentualeInvalidita"
                          min="1"
                          max="100"
                        />
                      </div>
                    ) : null}

                    {form.getFieldState('disableFlag')?.value ||
                    form.getFieldState('invalidoCivileFlag')?.value ||
                    form.getFieldState('altriSoggettiSvantaggiatiFlag')?.value ? (
                      <div className="form-group">
                        <label htmlFor="descrizioneInvalidita">Descrizione invalidità</label>
                        <Field
                          component="textarea"
                          className="form-control"
                          id="descrizioneInvalidita"
                          name="descrizioneInvalidita"
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <div className="panel panel-border panel-blue">
                  <div className="panel-heading">
                    <h3 className="panel-title">CV - Altre informazioni</h3>
                  </div>
                  <div className="panel-body">
                    <div className="form-group">
                      <Field
                        component="textarea"
                        className="form-control"
                        id="noteCv"
                        name="noteCv"
                        style={{ height: '80px' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-blue btn-lg">
                Salva altre informazioni &raquo;
              </button>
            </div>
          </form>
        )}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: state.candidatoAttivo.altreInformazioni,
    candidatoId: state.candidatoAttivo.candidatoId, // per sapere se ho già il candidato attivo su F5
  }
}

export default connect(mapStateToProps, { fetchCandidato, saveAltreInformazioni })(EditAltreInformazioni)
