import React from 'react'
import { MdDeleteForever, MdEdit } from 'react-icons/md'
import { Link } from 'react-router-dom'

const CorsiCompleti = ({ corsi, ruolo, permessoGesioneCorsi, handleDeleteCorso }) => {
  const renderCorsi = () => {
    return corsi.map((corso) => {
      return (
        <tr key={corso.corsoFormazionePropostoId}>
          <td>
            <Link to={`/app/corsi-di-formazione/${corso.corsoFormazionePropostoId}`}>
              {corso.corsoFormazionePropostoId}
            </Link>
          </td>
          <td>
            <span>{corso.titolo}</span>
          </td>
          <td className="requisiti">{corso.requisiti}</td>
          <td className="riferimento">{corso.descrizione}</td>
          <td>{corso.durataOre}</td>
          <td className="modalitaErogazione">{corso.modalitaErogazione}</td>
          <td className="costo">{corso.costo}</td>
          <td className="text-center numero-interessati">{corso.numeroCandidati}</td>
          <td className="text-right">
            {ruolo === 'operatore' && !!permessoGesioneCorsi && (
              <div className="actions">
                <div className="btn-group" role="group">
                  <span title="modifica">
                    <Link
                      to={'/app/corsi-di-formazione/' + corso.corsoFormazionePropostoId + '/edit'}
                      className="btn btn-sm btn-primary"
                    >
                      <MdEdit />
                    </Link>
                  </span>
                  <button title="elimina" className="btn btn-sm btn-danger" onClick={handleDeleteCorso(corso)}>
                    <MdDeleteForever />
                  </button>
                </div>
              </div>
            )}
          </td>
        </tr>
      )
    })
  }

  return (
    <div className="job-vacancies-complete">
      <div className="card-box">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Titolo</th>
                <th>Requisiti</th>
                <th>Descrizione</th>
                <th>Durata ore</th>
                <th>Modalità erogazione</th>
                <th>Costo</th>
                <th className="text-center">N° Candidati</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{renderCorsi()}</tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default CorsiCompleti
