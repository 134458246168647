import React, { Component } from 'react'
import { connect } from 'react-redux'

import { fetchSportello, sendContattoApp, resetStatoContattoApp } from '../../actions'

class ContattoApp extends Component {
  constructor(props) {
    super(props)

    this.state = {
      oggetto: '',
      messaggio: '',
    }
  }

  componentDidMount() {
    this.props.fetchSportello(this.props.sportelloUtente.sportelloId)
    this.props.resetStatoContattoApp()

    // const { jv } = this.props.location.query || null

    // if (jv) {
    //   this.setState({
    //     oggetto: `Job vacancy n. ${jv}`,
    //     messaggio: `Sono interessato alla job vacancy n. ${jv} ...`,
    //   })
    // }
  }

  handleSendMessage = (event) => {
    event.preventDefault()
    this.props.sendContattoApp(this.state.oggetto, this.state.messaggio)
    this.setState({
      oggetto: '',
      messaggio: '',
    })
  }

  reset = (event) => {
    event.preventDefault()
    this.props.resetStatoContattoApp()
  }

  editOggetto = (event) => {
    this.setState({
      oggetto: event.target.value,
    })
  }

  editMessaggio = (event) => {
    this.setState({
      messaggio: event.target.value,
    })
  }

  render() {
    return (
      <div className="contatto-app">
        <div className="heading">
          <h1 className="page-title">Contatto lo sportello {this.props.sportello.nome}</h1>
        </div>

        <div className="card-box">
          {!!this.props.sportello.email ? (
            <div>
              {this.props.messaggioInviato && (
                <div className="alert alert-success">
                  <h4 style={{ display: 'inline-block', margin: 0 }}>
                    <strong>Messaggio inviato correttamente!</strong>
                  </h4>
                  <button type="button" className="close" onClick={this.reset}>
                    <span>&times;</span>
                  </button>
                </div>
              )}
              <form className="form-horizontal" onSubmit={this.handleSendMessage}>
                <div className="form-group">
                  <label htmlFor="a" className="col-sm-2 control-label">
                    A
                  </label>
                  <div className="col-sm-7">
                    <span className="form-control">{this.props.sportello.email}</span>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="oggetto" className="col-sm-2 control-label">
                    Oggetto
                  </label>
                  <div className="col-sm-7">
                    <input
                      type="text"
                      className="form-control"
                      id="oggetto"
                      value={this.state.oggetto}
                      onChange={this.editOggetto}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="messaggio" className="col-sm-2 control-label">
                    Messaggio
                  </label>
                  <div className="col-sm-7">
                    <textarea
                      className="form-control"
                      id="messaggio"
                      rows="10"
                      value={this.state.messaggio}
                      onChange={this.editMessaggio}
                      {...this.state.messaggio}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-sm-7 col-sm-offset-2">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={!(this.state.oggetto && this.state.messaggio)}
                    >
                      Invia messaggio
                    </button>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div className="alert alert-warning">
              Attenzione: lo sportello non ha nessun indirizzo e-mail di contatto configurato.
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    sportelloUtente: state.auth.user.sportelloCorrente,
    sportello: state.sportelloAttivo,
    messaggioInviato: state.contattoApp.inviato,
  }
}

export default connect(mapStateToProps, { fetchSportello, sendContattoApp, resetStatoContattoApp })(ContattoApp)
