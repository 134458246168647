import moment from 'moment'
import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import {
  API_ROOT_URL,
  fetchAreeGeografiche,
  fetchSportelli,
  getStatisticheCandidati,
  getStatisticheCandidatiBySportello,
  setFiltroPannelloStatistiche,
} from '../../actions'
import StatisticheGrafico from './statistiche_grafico'

const CandidatiStatistiche = ({
  permessoStatistiche,
  sportelloId,
  getStatisticheCandidatiBySportello,
  getStatisticheCandidati,
  fetchAreeGeografiche,
  fetchSportelli,
  statistiche,
  ruolo,
  aree,
  filtroPannelloStatistiche,
  sportelli,
  setFiltroPannelloStatistiche,
}) => {
  const [hasMounted, setHasMounted] = useState(false)

  const token = localStorage.getItem('token')

  const renderExportLink = () => {
    let href = `${API_ROOT_URL}`
    const baseString = '/stats/export?'
    href = href + baseString + `t=${token}`
    const querySportello = `&sportelloId=${filtroPannelloStatistiche.sportelloId}`
    const queryAreaGeografica = `&areaGeograficaId=${filtroPannelloStatistiche.areaGeograficaId}`
    const queryDataDa = `&dataDa=${filtroPannelloStatistiche.dataDa}`
    const queryDataA = `&dataA=${filtroPannelloStatistiche.dataA}`

    if (filtroPannelloStatistiche.dataDa) {
      href = href + queryDataDa
    }
    if (filtroPannelloStatistiche.dataA) {
      href = href + queryDataA
    }
    if (filtroPannelloStatistiche.sportelloId) {
      href = href + querySportello
    }
    if (!filtroPannelloStatistiche.sportelloId && sportelloId) {
      href = href + `&sportelloId=${sportelloId}`
    }
    if (filtroPannelloStatistiche.areaGeograficaId) {
      href = href + queryAreaGeografica
    }
    return href
  }

  //
  //EVENT HANDLERS
  //
  const handleChangeField = (field) => (event) => {
    setFiltroPannelloStatistiche(field, event.target.value)
  }

  const datasetGraficoUno = [
    { name: 'Minori di 15', value: statistiche.minore15, color: '#c7e6ff' },
    { name: 'Da 15 a 29', value: statistiche.da15a29, color: '#8bc7f9' },
    { name: 'Da 30 a 45', value: statistiche.da30a45, color: '#1f94f3' },
    { name: 'Da 46 a 55', value: statistiche.da46a55, color: '#086fc4' },
    { name: 'Maggiori di 55', value: statistiche.maggiore55, color: '#004680' },
  ]
  const coloriGraficoUno = ['#c7e6ff', '#8bc7f9', '#1f94f3', '#086fc4', '#004680']

  const datasetItalianiStranieri = [
    {
      name: 'Italiani',
      value: statistiche.italiani,
      color: '#2196f3',
    },
    {
      name: 'Stranieri',
      value: statistiche.stranieri,
      color: '#ffc104',
    },
  ]
  const coloriItalianiStranieri = ['#2196f3', '#ffc104']

  const datasetMaschiFemmine = [
    {
      name: 'Maschi',
      value: statistiche.maschi,
      color: '#0eb1fd',
    },
    {
      name: 'Femmine',
      value: statistiche.femmine,
      color: '#f98baf',
    },
  ]
  const coloriMaschiFemmine = ['#0eb1fd', '#f98baf']

  //
  //EFFECTS
  //

  useEffect(() => {
    if (ruolo === 'operatore' && !!permessoStatistiche) {
      getStatisticheCandidatiBySportello({
        sportelloId: sportelloId,
        dataDa: filtroPannelloStatistiche.dataDa,
        dataA: filtroPannelloStatistiche.dataA,
      })
      setHasMounted(true)
    }
    if (ruolo === 'amministratore' || ruolo === 'subamministratore') {
      if (filtroPannelloStatistiche.sportelloId) {
        getStatisticheCandidatiBySportello({
          sportelloId: filtroPannelloStatistiche.sportelloId,
          dataDa: filtroPannelloStatistiche.dataDa,
          dataA: filtroPannelloStatistiche.dataA,
        })
      } else {
        getStatisticheCandidati({
          dataDa: filtroPannelloStatistiche.dataDa,
          dataA: filtroPannelloStatistiche.dataA,
          areaGeograficaId: filtroPannelloStatistiche.areaGeograficaId,
        })
      }
      setHasMounted(true)
      fetchAreeGeografiche()
      fetchSportelli()
    }
  }, [
    fetchAreeGeografiche,
    fetchSportelli,
    filtroPannelloStatistiche.areaGeograficaId,
    filtroPannelloStatistiche.dataA,
    filtroPannelloStatistiche.dataDa,
    filtroPannelloStatistiche.sportelloId,
    getStatisticheCandidati,
    getStatisticheCandidatiBySportello,
    permessoStatistiche,
    ruolo,
    sportelloId,
  ])

  return !!permessoStatistiche || ruolo === 'amministratore' || ruolo === 'subamministratore' ? (
    <div>
      <h1 className="page-title">Statistiche Candidati</h1>

      {ruolo === 'operatore' && (
        <div className="pannello-export-csv card-box">
          <div className="row">
            <div className="col-md-3 col-lg-4">
              <label htmlFor="dataDa">Data da:</label>
              <ReactDatePicker
                id="dataDa"
                selected={
                  filtroPannelloStatistiche.dataDa && filtroPannelloStatistiche.dataDa !== 'Invalid date'
                    ? new Date(moment(filtroPannelloStatistiche.dataDa).format('YYYY-MM-DD'))
                    : null
                }
                dateFormat="dd/MM/yyyy"
                locale="it"
                className="form-control"
                value={new Date(moment(filtroPannelloStatistiche.dataDa))}
                onChange={(e) => setFiltroPannelloStatistiche('dataDa', moment(e).format('YYYY-MM-DD'))}
              />
            </div>
            <div className="col-md-3 col-lg-4">
              <label htmlFor="dataA">Data a:</label>
              <ReactDatePicker
                id="dataA"
                selected={
                  filtroPannelloStatistiche.dataA && filtroPannelloStatistiche.dataA !== 'Invalid date'
                    ? new Date(moment(filtroPannelloStatistiche.dataA).format('YYYY-MM-DD'))
                    : null
                }
                dateFormat="dd/MM/yyyy"
                locale="it"
                className="form-control"
                value={new Date(filtroPannelloStatistiche.dataA)}
                onChange={(e) => setFiltroPannelloStatistiche('dataA', moment(e).format('YYYY-MM-DD'))}
              />
            </div>
            <div className="col-md-3 col-lg-4">
              <a href={renderExportLink()} target="_blank" rel="noreferrer" className="btn btn-primary">
                Esporta statistiche in file excel
              </a>
            </div>
          </div>
        </div>
      )}

      {(ruolo === 'amministratore' || ruolo === 'subamministratore') && (
        <div className="pannello-export-csv card-box">
          <div className="row">
            <div className="col-md-3 col-lg-4">
              <label htmlFor="dataDa">Data da:</label>
              <ReactDatePicker
                id="dataDa"
                selected={
                  filtroPannelloStatistiche.dataDa && filtroPannelloStatistiche.dataDa !== 'Invalid date'
                    ? new Date(moment(filtroPannelloStatistiche.dataDa).format('YYYY-MM-DD'))
                    : null
                }
                dateFormat="dd/MM/yyyy"
                locale="it"
                className="form-control"
                value={new Date(moment(filtroPannelloStatistiche.dataDa))}
                onChange={(e) => setFiltroPannelloStatistiche('dataDa', moment(e).format('YYYY-MM-DD'))}
              />
            </div>
            <div className="col-md-3 col-lg-4">
              <label htmlFor="dataA">Data a:</label>
              <ReactDatePicker
                id="dataA"
                selected={
                  filtroPannelloStatistiche.dataA && filtroPannelloStatistiche.dataA !== 'Invalid date'
                    ? new Date(moment(filtroPannelloStatistiche.dataA).format('YYYY-MM-DD'))
                    : null
                }
                dateFormat="dd/MM/yyyy"
                locale="it"
                className="form-control"
                value={new Date(filtroPannelloStatistiche.dataA)}
                onChange={(e) => setFiltroPannelloStatistiche('dataA', moment(e).format('YYYY-MM-DD'))}
              />
            </div>
            <div className="col-md-3 col-lg-4">
              <div className="form-group">
                <label htmlFor="organizzazione">Area geografica</label>
                <select
                  id="areaGeografica"
                  className="form-control"
                  value={filtroPannelloStatistiche.areaGeograficaId}
                  onChange={handleChangeField('areaGeograficaId')}
                >
                  <option value="">-- Tutte --</option>
                  {aree.map((areaGeografica) => {
                    return (
                      <option key={areaGeografica.areaGeograficaId} value={areaGeografica.areaGeograficaId}>
                        {areaGeografica.nome} - {areaGeografica.regione}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-3 col-lg-4">
              <div className="form-group">
                <label htmlFor="organizzazione">Sportello</label>
                <select
                  id="sportelli"
                  className="form-control"
                  value={filtroPannelloStatistiche.sportelloId}
                  onChange={handleChangeField('sportelloId')}
                >
                  <option value="">-- Tutti --</option>
                  {sportelli
                    .filter((sportello) => {
                      return (
                        !filtroPannelloStatistiche.areaGeograficaId ||
                        sportello.areaGeograficaId === parseInt(filtroPannelloStatistiche.areaGeograficaId, 10)
                      )
                    })
                    .map((sportello) => (
                      <option value={sportello.sportelloId} key={sportello.sportelloId}>
                        {sportello.organizzazione} - {sportello.nome}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-4">
              <a href={renderExportLink()} target="_blank" className="btn btn-primary" rel="noreferrer">
                Esporta statistiche
              </a>
            </div>
          </div>
        </div>
      )}
      {hasMounted && (
        <>
          <div className="row">
            <div className="col-md-3">
              <div className="card-box">
                <p className="margin-0 bold">Candidati</p>
                <div className="bold text-big">{statistiche.candidati}</div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="card-box">
                <StatisticheGrafico
                  titolo="Fasce d'età"
                  nCandidati={statistiche.candidati}
                  COLORS={coloriGraficoUno}
                  dataset={datasetGraficoUno}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="card-box">
                <StatisticheGrafico
                  titolo="Nazionalità"
                  nCandidati={statistiche.candidati}
                  COLORS={coloriItalianiStranieri}
                  dataset={datasetItalianiStranieri}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="card-box">
                <StatisticheGrafico
                  titolo="Sesso"
                  nCandidati={statistiche.candidati}
                  dataset={datasetMaschiFemmine}
                  COLORS={coloriMaschiFemmine}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  ) : (
    <div className="flex-center">
      <h1>Richiedi il permesso per visualizzare le statistiche candidati</h1>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    permessoStatistiche: state.auth.user.permessi.statistiche,
    sportelloId: state.auth.user.sportelloCorrente.sportelloId,
    statistiche: state.candidati.statistiche,
    ruolo: state.auth.user.ruolo,
    aree: state.areeGeografiche,
    filtroPannelloStatistiche: state.candidati.filtroPannelloStatistiche,
    sportelli: state.sportelli,
  }
}

export default connect(mapStateToProps, {
  getStatisticheCandidatiBySportello,
  getStatisticheCandidati,
  fetchAreeGeografiche,
  fetchSportelli,
  setFiltroPannelloStatistiche,
})(CandidatiStatistiche)
