import React, { Component } from 'react'
import { connect } from 'react-redux'
import AsyncSelect from 'react-select/async'
import { Field, Form } from 'react-final-form'
import {
  addObiettivoProfessionale,
  fetchTypeaheadPosizioniProfessionali,
  removeObiettivoProfessionale,
  saveObiettivoProfessionale,
} from '../../actions'
import './posizioni_professionali.less'

class PosizioniProfessionali extends Component {
  componentDidMount() {
    if (!this.props.isAgenzia) {
      this.props.fetchTypeaheadPosizioniProfessionali('a')
    }
  }

  ///////////////////////////////////////////////////////////////
  // GESTIONE AUTOCOMPLETE POSIZIONI
  getPosizioniOptions = posizioni => {
    return posizioni.map(posizione => {
      return {
        value: posizione,
        label: posizione,
      }
    })
  }

  searchChange = async search => {
    return this.props.fetchTypeaheadPosizioniProfessionali(search).then(res => {
      const options = this.getPosizioniOptions(res.payload.data)
      return [{ value: search, label: `Crea: "${search}"` }, ...options]
    })
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  createFromSearch(options, search) {
    // only create an option from search if the length of the search string is > 0 and
    // it does no match the label property of an existing option
    search = this.capitalizeFirstLetter(search)
    if (
      search.length == 0 ||
      options
        .map(function (option) {
          return option.label
        })
        .indexOf(search) > -1
    )
      return null
    else {
      return { label: search, value: search }
    }
  }

  renderOption(item) {
    return (
      <div className="simple-option">
        {!!item.newOption ? (
          <i style={{ color: '#61B814' }}>&raquo; Crea voce "{item.label}"</i>
        ) : (
          item.label
        )}
      </div>
    )
  }

  onSubmit(values) {
    const newPosizioneProfessionale = values.newPosizioneProfessionale.value
    if (
      !!newPosizioneProfessionale &&
      this.props.posizioniProfessionaliCandidato.indexOf(newPosizioneProfessionale) === -1
    ) {
      // Non vuoto e non duplicato
      this.props
        .addObiettivoProfessionale(newPosizioneProfessionale, this.props.candidatoId)
        // .then(action => {
        //   this.props.clearNewPosizioneProfessionale()
        // })
    }
  }

  onToggleSelezionato(obiettivoProfessionale) {
    return function (event) {
      event.preventDefault()
      if (this.props.canEdit) {
        this.props.saveObiettivoProfessionale(
          obiettivoProfessionale.obiettivoProfessionaleId,
          !obiettivoProfessionale.selezionatoFlag,
          this.props.candidatoId
        )
      }
    }
  }

  onRemove(obiettivoProfessionaleId) {
    return function (event) {
      event.preventDefault()
      this.props.removeObiettivoProfessionale(obiettivoProfessionaleId, this.props.candidatoId)
    }
  }

  renderElencoPosizioni() {
    return this.props.posizioniProfessionaliCandidato?.map(posizioneProfessionale => (
      <li key={posizioneProfessionale.obiettivoProfessionaleId}>
        <a
          href="/#"
          title="Clicca per mostrare/nascondere sul CV"
          onClick={this.onToggleSelezionato(posizioneProfessionale).bind(this)}
        >
          <span
            className={
              'label ' +
              (posizioneProfessionale.selezionatoFlag ? 'label-primary' : 'label-default')
            }
          >
            CV
          </span>
        </a>
        {posizioneProfessionale.posizioneProfessionale}
        {this.props.canEdit ? (
          <a
            href="/#"
            className="remove"
            onClick={this.onRemove(posizioneProfessionale.obiettivoProfessionaleId).bind(this)}
          >
            &times;
          </a>
        ) : null}
      </li>
    ))
  }

  render() {
    return (
      <div>
        {this.props.canEdit ? (
          <Form
            onSubmit={this.onSubmit.bind(this)}
            initialValues={{ newPosizioneProfessionale: this.props.newPosizioneProfessionale }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <div className="input-group">
                    <Field
                      name="newPosizioneProfessionale"
                      render={({ input }) => (
                        <AsyncSelect
                          placeholder={this.props.newPosizioneProfessionale || 'Seleziona...'}
                          loadOptions={this.searchChange}
                          loadingPlaceholder="Caricamento..."
                          searchingText="Caricamento..."
                          noResultsText="Nessun risultato"
                          {...input}
                        />
                      )}
                    />

                    <span className="input-group-btn">
                      <button className="btn btn-primary" type="submit">
                        Aggiungi
                      </button>
                    </span>
                  </div>
                </div>
              </form>
            )}
          />
        ) : null}

        <ul className="posizioni">{this.renderElencoPosizioni()}</ul>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    posizioniProfessionali: state.posizioniProfessionali,
    posizioniProfessionaliCandidato:
      state.candidatoAttivo.obiettiviProfessionali.posizioniProfessionali,
    newPosizioneProfessionale: state.newPosizioneProfessionale,
    candidatoId: state.candidatoAttivo.candidatoId,
    isAgenzia: state.auth.user.ruolo === 'agenzia',
    canEdit:
      !!state.auth.user.permessi['obiettiviProfessionali'] &&
      state.candidatoAttivo.sportello &&
      state.candidatoAttivo.sportello.sportelloId === state.auth.user.sportelloCorrente.sportelloId,
  }
}

export default connect(mapStateToProps, {
  addObiettivoProfessionale,
  saveObiettivoProfessionale,
  removeObiettivoProfessionale,
  fetchTypeaheadPosizioniProfessionali,
})(PosizioniProfessionali)
